import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

const RegisterTransactionCard = (props) => {
  const { userFees, handleDownloadInvoice } = props;
  
  return (
    <Fragment>
{/* {userFees?.filter(project => project.orderId !== null).map((project, index) => ( */}
  <div className='TransactionCard'>
    <div className='TransactionCard-row-1'>
      <p className='first-col'>Registration Fee</p>
      <p className='second-col'>
        ₹{userFees?.fees?.paidAmount < userFees?.fees?.originalAmount ? (
          <>
            {userFees?.fees?.paidAmount}{' '}
            <span
              style={{
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
                marginLeft: '10px',
                color: '#999',
              }}
            >
              ₹{userFees?.fees?.originalAmount}
            </span>
          </>
        ) : (
            userFees?.fees?.paidAmount
        )}
      </p>
      <p className='third-col'>
        {/* You can handle the date format here */}
        {userFees?.fees?.purchasedOn}
      </p>
    </div>
    <div className='TransactionCard-row-2'>
      <p className='all-chapters-text'>
     
      </p>

      <div className='flex'>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('register', userFees?.fees,'receipt')}
      >
        Download receipt
      </p>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('register', userFees?.fees,'invoice')}
      >
        Download invoice
      </p>
      </div>
    </div>
  </div>
{/* ))} */}

    </Fragment>
  );
};

export default RegisterTransactionCard;
