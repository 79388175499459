import * as api from '../actions/api/index'
import { SET_SELECTED_STUDENT,FETCH_ALL_EXAMS,SET_EXAM_ATTENDANCE ,GET_RANKINGS} from '../actionsType';

export const setSelectedStudent = (student) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_STUDENT,
    payload: student,
  });
};




export const fetchAllExams=()=> async(dispatch)=>{
  try {
    const {data}=await api.fetchAllExams()
    dispatch({
      type:FETCH_ALL_EXAMS,
      payload: data
  
    })
  
    return data
  } catch (err) {
    const message = err?.response?.data?.message
    ? err.response.data.message
    : 'Something went wrong';
  console.log(message);
  }

}




export const checkExamAttendance = (userId, examId) => async (dispatch) => {
  console.log('userID', userId,examId)
  try {
    const {data} = await api.getAttendance( userId, examId );
    console.log('data from checkexam actipn', data)
    dispatch({
      type: SET_EXAM_ATTENDANCE,
      payload: data?.data
    });
    return data
  } catch (error) {
    console.error("Failed to fetch exam attendance", error);
  }
};





export const userRankings=(examId)=>async(dispatch)=>{

  try {

    const {data}= await api.getRankings(examId)
    console.log('data', data)
    dispatch({
      type:GET_RANKINGS,
      payload:data?.data
    })
    return data
  }
    catch (err) {
      const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
    }
  
  }
  
  