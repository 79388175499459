export const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   timeZone: 'UTC'
    };
    
    return date.toLocaleString('en-Uk', options);
}


export const getMinuteDifference = (startTimeString, endTimeString) => {
    const startDate = new Date(startTimeString);
    const endDate = new Date(endTimeString);

    // Calculate the difference in milliseconds
    const differenceMs = endDate - startDate;

    // Convert milliseconds to minutes
    const differenceMinutes = Math.floor(differenceMs / (1000 * 60));

    return differenceMinutes;
}


export const StandardTime=(startTime)=>{


  const date = new Date(startTime)

const hours = date.getHours();
const minutes = date.getMinutes();
const period = hours >= 12 ? 'pm' : 'am';
const formattedTime = `${(hours % 12) || 12}:${minutes.toString().padStart(2, '0')} ${period}`;

return formattedTime;

}






export const calculateCountdown = (startTimeMillis, endTimeMillis) => {
  const currentTime = new Date().getTime();
  const diffMillis = startTimeMillis - currentTime;
  const diffSeconds = Math.floor((diffMillis % (1000 * 60)) / 1000);
  const diffMinutes = Math.floor((diffMillis % (1000 * 60 * 60)) / (1000 * 60));

  if (currentTime > endTimeMillis) {
    return {
      label: 'Meeting Ended',
      disabled: true,
      color: 'grey',
    };
  }

  if (currentTime > startTimeMillis) {
    return {
      
      label: '',
      disabled: false,
      color: '',
    };
  }

  return {
    label: `Classs in ${diffMinutes}min ${diffSeconds}sec`,
    disabled: true,
    color: '',
  };
};
