import { GET_LECTURE_PRICE,GET_USER_LECTURES_BY_YEAR,GET_USER_ALL_LECTURES } from '../actionsType';
const Initialstate={
    lecturePrices:[],
    userLecturesByYEAR:[],
    userAllLiveLectures:[]
}



export const lectureReducer=(state=Initialstate,action)=>{

    switch(action.type){
            case GET_LECTURE_PRICE:
                return {
                    ...state,
                    lecturePrices:action?.payload
                }
            case GET_USER_LECTURES_BY_YEAR:
                return{
                    ...state,
                    userLecturesByYEAR:action?.payload
                }

            case GET_USER_ALL_LECTURES:
                return{
                    ...state,
                    userAllLiveLectures:action?.payload
                }
          

             default:
                return state
    }

}


export default lectureReducer;