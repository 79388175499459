import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from '../Navbar';
import { Box, Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { GrView } from "react-icons/gr";
import ParentDropdown from '../ParentDropdown';
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { checkExamAttendance } from '../../../redux/actions/getStudentActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TestMega() {
  const dispatch = useDispatch();
  const { allExams, selectedStudent, examAttendance } = useSelector((store) => store.studentReducer);

  const filteredMegaExams = allExams?.filter(
    (item) => item.select_class === selectedStudent?.class?.toString() && item.testType === "mega_test"
  );

  useEffect(() => {
    if (selectedStudent) {
      filteredMegaExams.forEach((exam) => {
        dispatch(checkExamAttendance(selectedStudent.id, exam.id));
      });
    }
  }, [selectedStudent]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div >
      <Navbar />

    <Container sx={{mt:10}}>
    <Grid container spacing={4} alignItems="center" justifyContent={{ xs: 'center', md: 'space-between' }} my={4}>
<Grid item xs={12} md="auto">
  <Typography variant="h4" component="h2" color="textPrimary" textAlign="center">
    Mega Exam - All Subjects
  </Typography>
</Grid>
<Grid item xs={12} md="auto">
  <Box display="flex" justifyContent="center" width="100%">
    <ParentDropdown fullWidth />
  </Box>
</Grid>
</Grid>
   
    <TableContainer component={Paper} sx={{ overflowX: 'auto'}}>
      <Table >
        <TableHead >
          <TableRow>
          <StyledTableCell>Date <FaCaretDown/> </StyledTableCell>
            <StyledTableCell  align="center">Subject</StyledTableCell>
            <StyledTableCell align="center">Chapter</StyledTableCell>
            <StyledTableCell  align="center">Marks</StyledTableCell>
            <StyledTableCell align="center">View Marksheet </StyledTableCell>
            <StyledTableCell align="center">View Answer Key</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
        {filteredMegaExams?.map((exam) => {
                const attendanceData = examAttendance?.attended?.find((index) => index.examId === exam.id);
                console.log('test mcq', attendanceData)

                return (
                  <StyledTableRow key={exam?.id}>
                    <StyledTableCell>{exam?.select_date}</StyledTableCell>
                    <StyledTableCell align='center'>{exam?.select_subject}</StyledTableCell>
                    <StyledTableCell align="center">{exam?.select_chapter}</StyledTableCell>
                    <StyledTableCell align="center">{exam?.total_marks}</StyledTableCell>
                    <StyledTableCell align="center">
                      {attendanceData ? <GrView /> : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {attendanceData ? <GrView /> : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <span className={`badge ${attendanceData && attendanceData.attendedBy ? 'badge-success' : 'badge-danger'}`}>
                        {attendanceData && attendanceData.attendedBy ? 'Attended' : 'Not attended'}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    
    </div>
  );
}
