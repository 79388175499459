
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserPoints } from '../redux/actions/userActions';
import ecLogo from '../images/ec_logo_square.jpg';
import { verifyRegistrationPayment, purchaseRegistration } from '../redux/actions/api/index';
import { setUserDetails } from '../redux/actions/userActions';
import boyImage from '../images/boy1.jpg';
import iconImage from '../images/picon.png';
import girlImage from '../images/girl1.png'; // Assuming girlImage is correctly imported
import '../styles/RegisstrationFeesModal.css';
import { Tooltip } from 'react-bootstrap';
import { getAllStandards } from '../redux/actions/standardActions';
const RegistrationFeeModal = ({ handleClose }) => {
 
  const [hover, setHover] = useState(false); // Hover state added
  const navigate = useNavigate();
  const { userPoints, userData } = useSelector((store) => store.userReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  const standardData = allStandards?.find((item) => item.class === userData.standard);
  const [showRewardSelection, setShowRewardSelection] = useState(false);
  const [finalPrice, setFinalPrice] = useState(standardData.registrationFees);
  const [selectedReward, setSelectedReward] = useState(null);
  const [pointsUsed, setPointsUsed] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPoints());
    dispatch(getAllStandards())
  }, []);

  const handleBuyNow = async (selectedReward, amount) => {
    try {
      const response = await purchaseRegistration(userData.id, {
        amount,
        rewardType: selectedReward,
        pointsUsed: pointsUsed,
      });

      if (response.status !== 201) throw new Error('Purchase failed');

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: response.data.response.amount,
        currency: response.data.response.currency,
        name: 'Enrouting Careers',
        description: response.data.response.notes.desc,
        image: ecLogo,
        order_id: response.data.response.id,
        handler: async (paymentResponse) => {
          try {
            const paymentDetails = {
              orderCreationId: response.data.response.id,
              razorpayPaymentId: paymentResponse.razorpay_payment_id,
              razorpaySignature: paymentResponse.razorpay_signature,
              userId: userData.id,
              amount,
              rewardType: selectedReward,
              pointsUsed: pointsUsed,
            };

            const verifyResponse = await verifyRegistrationPayment(paymentDetails);
            if (verifyResponse.status === 200) {
              dispatch(setUserDetails());
              // navigate('/subscription', {
              //   state: {
              //     item: { userData, paymentDetails, type: 'registrationFees' },
              //   },
              // }
              navigate('/purchases', {
                state: {
                  item: { userData, paymentDetails, type: 'registrationFees' },
                },
              }
            
            );
            } else {
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            toast.error('Payment verification failed');
          }

          handleClose();
          document.body.style.overflow = 'auto';
        },
        prefill: {
          name: `${userData.firstname} ${userData.lastname}`,
          email: userData.email,
          contact: userData.phone,
        },
        theme: { color: '#3399cc' },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', () => {
        toast.error('Payment failed');
        handleClose();
        document.body.style.overflow = 'auto';
      });
    } catch (error) {
      console.error('Error during purchase:', error);
      toast.error(error.message || 'An error occurred during the purchase');
    } finally {
      document.body.style.overflow = 'auto';
    }
  };

  const applyReward = (rewardType) => {
    const rewardAmount = rewardType === 'welcomeBonus' && userPoints?.data?.welcomeBonus

    const discount = Math.min(standardData.registrationFees - 1, rewardAmount);
    setPointsUsed(discount);
    setSelectedReward(rewardType);
    setFinalPrice(standardData.registrationFees - discount);
    setShowRewardSelection(false);
  };

  const removeReward = () => {
    setSelectedReward(null);
    setPointsUsed(0);
    setFinalPrice(standardData?.registrationFees);
  };

  // const RewardSelection = () => (
  //   <div className="reward-selection-modal">
  //     <img
  //       src={boyImage}
  //       alt="anime"
  //       style={{ height: '200px', width: 'auto', marginRight: '15px' }}
  //       className="circular-image"
  //     />
  //     <div></div>
  //     <h2>Select a reward to apply</h2>
  //     <div className="reward-buttons">
  //       <button
  //       disabled={
  //         userPoints?.data?.welcomeBonus === null ||
  //         isNaN(userPoints?.data?.welcomeBonus) ||
  //         userPoints?.data?.welcomeBonus === undefined
  //     }
  //               onClick={() => applyReward('welcomeBonus')}
  //         className="use-welcome-bonus-btn"
  //       >
  //         Use Welcome Bonus 
  //       </button>

  //       <button className="styled-button" onClick={() => setShowRewardSelection(false)}>
  //         Cancel
  //       </button>
  //     </div>
  //   </div>
  // );


  const RewardSelection = () => (
    <div className="reward-selection-modal-custom">
      <img
        src={boyImage}
        alt="anime"
        style={{ height: '200px', width: 'auto', marginRight: '15px' }}
        className="circular-image-custom"
      />
      <div></div>
      <h2>Select a reward to apply</h2>
      <div className="reward-buttons-custom">
        
        <button
          disabled={
            userPoints?.data?.welcomeBonus === null ||
            isNaN(userPoints?.data?.welcomeBonus) ||
            userPoints?.data?.welcomeBonus === undefined
          }
          onClick={() => applyReward('welcomeBonus')}
          className="use-welcome-bonus-btn-custom"
        >
          Use Welcome Bonus
        </button>
  
        <button className="styled-button-custom" onClick={() => setShowRewardSelection(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
  return (
    // <div className="Registration-content">
    //   {showRewardSelection ? (
    //     <RewardSelection />
    //   ) : (
    //     <div className="Registration-content">
    //       <div className="pay-registration-fee">Pay Registration Fee</div>
    //       <p className="pay-registration-fee-description">
    //         To unlock chapters, please pay the registration fee.
    //         <br />
    //         Price: ₹{finalPrice}
    //       </p>

    //       <div className="modal-buttons">
    //         <button
    //           className="styled-button"
    //           onMouseEnter={() => setHover(true)} // Mouse enter sets hover to true
              
    //           onMouseLeave={() => setHover(false)} // Mouse leave sets hover to false
    //           onClick={() => setShowRewardSelection(true)}
    //         >
    //           {hover ? (
    //             <div style={{ display: 'flex', alignItems: 'center' }}>
    //               <img
    //                 src={girlImage}
    //                 alt="hover effect"
    //                 style={{ height: '100px', width: 'auto', marginRight: '-70px' }}
    //               />
    //               <div>
    //                 <p className="congrats-text">
    //                   Congratulations! You have WON {userPoints?.data?.welcomeBonus} welcome points!
    //                 </p>
    //                 <p className="points-description">
    //                   <img
    //                     src={iconImage}
    //                     alt="icon"
    //                     style={{ height: '15px', width: 'auto', marginRight: '5px' }}
    //                   />
    //                   Use your points to avail waiver on the purchase of pre-recorded sessions.
    //                   <br />
    //                   <img
    //                     src={iconImage}
    //                     alt="icon"
    //                     style={{ height: '15px', width: 'auto', marginRight: '5px' }}
    //                   />
    //                   These points are valid for 40 days from the date of first purchase.
    //                   <br />
    //                   <img
    //                     src={iconImage}
    //                     alt="icon"
    //                     style={{ height: '15px', width: 'auto', marginRight: '5px' }}
    //                   />
    //                   Points cannot be redeemed as cash or combined with other discounts.
    //                   <br />
    //                   <img
    //                     src={iconImage}
    //                     alt="icon"
    //                     style={{ height: '15px', width: 'auto', marginRight: '5px' }}
    //                   />
    //                   Points are non-transferable.
    //                 </p>
    //               </div>
    //             </div>
    //           ) : (
    //             'Use Points'
    //           )}
    //         </button>
    //         <button className="styled-button-right" onClick={() => handleBuyNow(selectedReward, finalPrice)}>
    //           Pay Now
    //         </button>
    //         <button className="styled-button-right" onClick={handleClose}>
    //           Close
    //         </button>
    //       </div>

    //       {selectedReward && (
    //         <div>
    //           <p>
    //             <strong>
    //               {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
    //             </strong>
    //             <button className="styled-button" onClick={removeReward}>
    //               Remove
    //             </button>
    //           </p>
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </div>





<div className="registration-content">
  {showRewardSelection ? (
    <RewardSelection />
  ) : (
    <div className="registration-content">
      <div className="pay-registration-fee-custom">Pay Registration Fee</div>
      <p className="pay-registration-fee-description-custom">
        To unlock chapters, please pay the registration fee.
        <br />
        Price: ₹{finalPrice}
      </p>

        {/* {hover ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={girlImage}
                alt="hover effect"
                style={{ height: '100px', width: 'auto', marginRight: '-70px' }}
              />
              <div>
                <p className="congrats-text-custom">
                  Congratulations! You have WON {userPoints?.data?.welcomeBonus} welcome points!
                </p>
                <p className="points-description-custom">
                  <img
                    src={iconImage}
                    alt="icon"
                    style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                  />
                  Use your points to avail waiver on the purchase of pre-recorded sessions.
                  <br />
                  <img
                    src={iconImage}
                    alt="icon"
                    style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                  />
                  These points are valid for 40 days from the date of first purchase.
                  <br />
                  <img
                    src={iconImage}
                    alt="icon"
                    style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                  />
                  Points cannot be redeemed as cash or combined with other discounts.
                  <br />
                  <img
                    src={iconImage}
                    alt="icon"
                    style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                  />
                  Points are non-transferable.
                </p>
              </div>
            </div>
          ) : (
            'Use Points'
          )} */}


      <div className="modal-buttons-custom">
        {
          !selectedReward &&   <button

          className="styled-button-custom"
          onMouseEnter={() => setHover(true)} 
          onMouseLeave={() => setHover(false)} 
          onClick={() => setShowRewardSelection(true)}
        >
        
        {hover ? (
  <div style={{  display: 'flex', alignItems: 'center' }}>
    <img
      src={girlImage}
      alt="hover effect"
      style={{ height: '100px', width: 'auto', marginRight: '-70px' }}
    />
    <div style={{ maxWidth: '150px' }}> {/* Limit the width of the text */}
      <p className="congrats-text-custom">
        Congratulations! You have WON {userPoints?.data?.welcomeBonus} welcome points!
      </p>
      <p className="points-description-custom">
        <img
          src={iconImage}
          alt="icon"
          style={{ height: '15px', width: 'auto', marginRight: '5px' }}
        />
        Use your points to avail a waiver on the purchase of pre-recorded sessions.
        <br />

        
        {/* Other content */}
      </p>

      
    </div>
  </div>
) : (
  'Use Points'
)}

        </button>
        }
      
        <button className="styled-button-right-custom" onClick={() => handleBuyNow(selectedReward, finalPrice)}>
          Pay Now
        </button>
        <button className="styled-button-right-custom" onClick={handleClose}>
          Close
        </button>
      </div>

      {selectedReward && (
        <div>
          <p>
            <strong>
              {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
            </strong>
            <button className="styled-button-custom" onClick={removeReward}>
              Remove
            </button>
          </p>
        </div>
      )}
    </div>
  )}
</div>
  );
};

export default RegistrationFeeModal;
