import { LOGIN, SIGNUP,
  USER_REGISTER_FEES,
  LOGOUT, SET_USER_DETAILS ,USER_POINTS} from '../actionsType';
import Cookies from 'js-cookie';

const initialState = {
  userData: null,
  isAuthenticated: null,
  userPoints:null,
  registerFees: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      Cookies.set('userJWT', action?.payload?.token, {
        expires: 7,path: '/'
      });
      return {
        ...state,
        userData: action?.payload?.userDetails,
        isAuthenticated: true,
      };

    case SIGNUP:
      Cookies.set('userJWT', action?.payload?.token, {
        expires: 7, path: '/'
      });
      return {
        ...state,
        userData: action?.payload?.userDetails,
        isAuthenticated: true,
      };

    case SET_USER_DETAILS:
      return {
        ...state,
        userData: action?.payload,
        isAuthenticated: true,
      };

    case USER_REGISTER_FEES:
      return{
        ...state,
        registerFees: action?.payload,
      }


    case LOGOUT:
      Cookies.remove('userJWT',{path: '/'});
      return initialState;
    case USER_POINTS:
        return{
          ...state,
          userPoints:action?.payload
  
        }

    default:
      return state;
  }
};

export default userReducer;