import React, { useState, useEffect } from 'react';
import closeIcon from '../images/ic_close.svg';
import escLightIcon from '../images/ic_esc_white.svg';
import escDarkIcon from '../images/ic_esc_gray.svg';
import errorImage from '../images/error-img2.jpg';
import '../styles/ReadWatchModal.css';

const ReadNotesModal = ({ handleClose, chapter }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isError, setIsError] = useState(false);

  // Validate the chapter notes URL and ensure it's a PDF
  const isValidPdfUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname.endsWith('.pdf'); // Only allow .pdf files
    } catch (error) {
      return false;
    }
  };

  // Check if the chapter notes exist and are valid
  const notesUrl = chapter?.notes && isValidPdfUrl(chapter?.notes) ? `${chapter.notes}#toolbar=0&navpanes=0&scrollbar=0` : null;

  useEffect(() => {
    if (!notesUrl) {

      console.log("=======>working")
      setIsError(true); // Set error if notes URL is invalid or not a PDF
    }
  }, [notesUrl]);


  console.log('course', chapter)

  return (
    <div className="ReadWatchModal">
      <div className={`ReadWatchModal-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <span onClick={handleClose} role="button" tabIndex={0} aria-label="Close">
          <img src={closeIcon} alt="Close icon" className="ReadWatchModal-close-icon" />
        </span>
        <div className="ReadWatchModal-title-container">
          <h1>{chapter?.name}</h1>
        </div>
        <p className="ReadWatchModal-notes-text">Notes</p>
        
        <div className={`ReadWatchModal-notes-container ${isDarkMode ? 'dark-mode' : ''}`}>
          {isError ? (
            <div className="error-image-container"> 
              <img
                src={errorImage}
                alt="Error loading notes"
                className="error-image"
              />
            </div>
          ) : (
            <iframe
              id="noContextMenu"
              className="notes-frame"
              src={notesUrl}
              type="application/pdf"
              scrolling="auto"
              frameBorder="0"
              height="100%"
              width="100%"
              title="Notes"
              aria-label="Notes content"
              onError={() => setIsError(true)} // Handle iframe loading error
            />
          )}
        </div>

        <div className="ReadWatchModal-options-container">
          <div className="ReadWatchModal-toggle-div">
            <p className={`toggle-switch-label ${isDarkMode ? 'dark-mode' : ''}`}>Dark mode</p>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isDarkMode}
                onChange={() => setIsDarkMode(!isDarkMode)}
              />
              <span className="switch" />
            </label>
          </div>
          <div className={`ReadWatchModal-close-div ${isDarkMode ? 'dark-mode' : ''}`}>
            <img
              src={isDarkMode ? escLightIcon : escDarkIcon}
              alt="Esc icon"
              className="esc-icon"
            />
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadNotesModal;
