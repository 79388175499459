// User Action Types
export const SIGNUP = 'SIGNUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const USER_POINTS='USER_POINTS';

// Course Action Types
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_USER_COURSES = 'GET_USER_COURSES';
export const GET_USER_CHAPTERS="GET_USER_CHAPTERS"

// Board Action Types
export const GET_ALL_BOARDS = 'GET_ALL_BOARDS';

// Standard Action Types
export const GET_ALL_STANDARDS = 'GET_ALL_STANDARDS';

// Testimonial Action Types
export const GET_ALL_TESTIMONIALS = 'GET_ALL_TESTIMONIALS';

// Event Action Types
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const MAKE_SUBMISSION = 'MAKE_SUBMISSION';
export const EDIT_SUBMISSION = 'EDIT_SUBMISSION';

// Workshop Action Types
export const GET_ALL_WORKSHOPS = 'GET_ALL_WORKSHOPS';
export const GET_USER_WORKSHOPS = 'GET_USER_WORKSHOPS';

// Blog Action Types
export const GET_ALL_BLOGS = 'GET_ALL_BLOGS';

// Help Email Action Type
// export const SEND_EMAIL = 'SEND_EMAIL';



export const GET_ALL_VIDEOS='GET_ALL_VIDEOS'

//Teacher action types
export const SCHEDULE_MEETING='SCHEDULE_MEETING';


export const TEACHER_LOGIN='TEACHER_LOGIN'
export const SET_TEACHER_DETAILS="SET_TEACHER_DETAILS"



// export const  TEACHER_LOGOUT='TEACHER_LOGOUT'


export const GET_ALL_MEETINGS="GET_ALL_MEETINGS"

export const TEACHER_LOGOUT="TEACHER_LOGOUT"
export const STUDENT_REQUESTS="STUDENT_REQUESTS"

export const LECTURE_REQUESTS="LECTURE_REQUESTS"

export const ALL_SUBJECTS="ALL_SUBJECTS"
export const ALL_CHAPTERS="ALL_CHAPTERS"
export const ALL_UNITS="ALL_UNITS"





//parent action type
export const PARENTS_SIGN_IN='PARENTS_SIGN_IN'
export const PARENTS_SIGN_UP='PARENTS_SIGN_UP'
export const PARENT_LOGOUT='PARENT_LOGOUT';
export const SET_PARENT_DETAILS='SET_PARENT_DETAILS';





export const SET_SELECTED_STUDENT='SET_SELECTED_STUDENT'
export const FETCH_ALL_EXAMS='FETCH_ALL_EXAMS'
export const SET_EXAM_ATTENDANCE="SET_EXAM_ATTENDANCE"
export const GET_RANKINGS='GET_RANKINGS'

export const GET_ALL_PROJECTS='GET_ALL_PROJECTS';
export const UPDATE_PROJECT_APPROVAL='UPDATE_PROJECT_APPROVAL';
export const USER_PROJECT="USER_PROJECT";
export const EDIT_PROJECT_SUBMISSION="EDIT_PROJECT_SUBMISSION";
export const MAKE_PROJECT_SUBMISSION="MAKE_PROJECT_SUBMISSION"



export const GET_ALL_USERS_BY_TEACHER="GET_ALL_USERS_BY_TEACHER";
export const ALL_PENDING_USERS='ALL_PENDING_USERS';
export const CREATE_BULK_USER='CREATE_BULK_USER';
export const CREATE_SINGLE_USER_BY_TEACHER='CREATE_SINGLE_USER_BY_TEACHER';
export const UPDATE_USER_STATUS='UPDATE_USER_STATUS'




//get lecture prices


export const GET_LECTURE_PRICE="GET_LECTURE_PRICE";
export const GET_USER_LECTURES_BY_YEAR="GET_USER_LECTURES_BY_YEAR";
export const GET_USER_ALL_LECTURES="GET_USER_ALL_LECTURES";



export const GET_USER_EXAMS='GET_USER_EXAMS';
export const USER_REGISTER_FEES='USER_REGISTER_FEES'