import * as api from './api';
import { GET_ALL_COURSES, GET_USER_COURSES,GET_USER_CHAPTERS } from '../actionsType';

export const getAllCourses = () => async (dispatch) => {
  try {
    const { data } = await api.getAllCourses();

    console.log('data from action', data)
    dispatch({
      type: GET_ALL_COURSES,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const getUserCourses = () => async (dispatch) => {
  try {

    const { data } = await api.getUserCourses();
   // console.log("data from course action",data);
    dispatch({
      type: GET_USER_COURSES,
      payload: data,
    });
    return data
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};


export const getUserChapters = () => async (dispatch) => {
  try {

    const { data } = await api.getUserChapters();
   console.log("data from chapter action",data);
    dispatch({
      type: GET_USER_CHAPTERS,
      payload: data,
    });
    return data
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};
