import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import MCQForm from "../../pages/Teachers/AddQuestions/MCQ_TYPE/MCQForm";
import AddExamModalTitle from "./AddExamModalTitle";
import McqQuestionForm from "../../pages/Teachers/AddQuestions/MCQ_TYPE/McqQuestionForm";
import SubjectiveForm from "../../pages/Teachers/AddQuestions/Subjective_Type/SubjectiveForm";
import MegaTestForm from "../../pages/Teachers/AddQuestions/Mega_Test_Type/MegaTestForm";
import UserSubjectiveForm from "../../pages/Students/ViewTest/Subjective_Type/UserSubjectiveForm";
import UserMegaTestForm from "../../pages/Students/ViewTest/Mega_Test_Type/UserMegaTestForm";
import SubExamAttendByUserForm from "../../pages/Teachers/AddQuestions/Subjective_Type/SubExamAttendByUser/SubExamAttendByUserForm";
import MegaExamAttendByUserForm from "../../pages/Teachers/AddQuestions/Mega_Test_Type/MegaExamAttendByUser/MegaExamAttendByUserForm";
import ResultCard from "../../pages/Students/ViewTest/Subjective_Type/ResultCard";
import ResultCardMega from "../../pages/Students/ViewTest/Mega_Test_Type/ResultCardMega";
import ResultCardMCQ from "../../pages/Students/ViewTest/MCQ_TYPE/ResultCardMCQ";

const AddExamModal = ({
  formData,
  open,
  formName,
  onClose,
  exam_Id,
  setIsEditOpen,
  onMarksUpdate,
}) => {
  const formComponents = {
    "Add MCQ Exam": MCQForm,
    "Add MCQ Question": McqQuestionForm,
    "Add Subjective Exam": SubjectiveForm,
    "Add Mega Test": MegaTestForm,
    "Upload Subjective Test": UserSubjectiveForm,
    "Upload Mega Test": UserMegaTestForm,
    "Add Sub Marks": SubExamAttendByUserForm,
    "Add Mega Marks": MegaExamAttendByUserForm,
    "Subject_Wise Result": ResultCard,
    "Mega Test Result": ResultCardMega,
    "MCQ Test Result": ResultCardMCQ,
  };
  const FormComponent = formComponents[formName];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" sx={{ width: "100%" }}>
      <AddExamModalTitle data={formName} />
      <DialogContent sx={{ padding: "16px", width: "100%" }}>
        <Box sx={{ margin: "20px" }} data-aos="fade-left">
          {FormComponent && (
            <FormComponent
              exam_Id={exam_Id}
              rowData={formData}
              setIsEditOpen={setIsEditOpen}
              onMarksUpdate={onMarksUpdate}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExamModal;
