import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from './Navbar';
import Footer from './Footer';
import WorkshopCard from './WorkshopCard';
import {
  getAllWorkshops,
  getUserWorkshops,
} from '../redux/actions/workshopActions';
import '../styles/WorkshopPage.css';
import { Fab } from '@mui/material';

import coin from '../images/coin.png'
import { IoEyeSharp } from 'react-icons/io5';

const WorkshopPage = () => {
  const dispatch = useDispatch();
  const { allWorkshops, userWorkshops } = useSelector(
    (store) => store.workshopReducer
  );
  const { isAuthenticated } = useSelector((store) => store.userReducer);

  useEffect(() => {
    if (allWorkshops.length === 0) {
      dispatch(getAllWorkshops());
    }
  }, [dispatch, allWorkshops]);

  useEffect(() => {
    if (isAuthenticated && !userWorkshops) {
      dispatch(getUserWorkshops());
    }
  }, [dispatch, isAuthenticated, userWorkshops]);

  const isPurchased = (workshop) => {
    if (!userWorkshops) {
      return false;
    } else {
      return userWorkshops.some((w) => w.id === workshop.id);
    }
  };

  return (
    <div className='WorkshopPage'>
      <Navbar activeMenuItem={'workshop'} />

      <div className='WorkshopPage-title-container'>
     
        <h1>Workshops</h1>
        <p>
          Attending a workshop is like gifting yourself a new possibility to
          learn something new from peers who have better experience and
          knowledge to share with you a new bee into the world of professionals.
          Come gift yourself a better experience.{' '}
        </p>
      </div>
      <div className='workshop-card-container'>
        {allWorkshops?.map((workshop) => (
          <WorkshopCard
            workshop={workshop}
            locked={!isAuthenticated || !isPurchased(workshop)}
            isAuthenticated={isAuthenticated}
          />
        ))}
      </div>

      
      <Footer />
    </div>
  );
};

export default WorkshopPage;
