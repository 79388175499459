import * as api from './api';
import { GET_ALL_VIDEOS } from '../actionsType';

export const getWatchVideo=(userId,subjectId,chapterId)=>async(dispatch)=>{
try {

    const {data}=await api.getAllWatchVideo(userId,subjectId,chapterId);

    console.log('data from action', data)
    dispatch({
        type:GET_ALL_VIDEOS,
        payload:data
    })
    
} catch (error) {
    return error
}
}