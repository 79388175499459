import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import TeacherNavbar from "../../../../components/Teacher/TeacherNavbar";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import { getSubMegaExams } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";
import SubjectiveTable from "./MegaTestTable";
import { getAllUsers } from "../../../../api/AllUsers/Users";

const MegaTest = () => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);

  //for edit modal open and closed
  const handleEditClick = (formData) => {
    setSelectedFormData(formData);
    setIsEditOpen(true);
  };

  // Use React Query to fetch topic data
  const {
    data: megaTestExamData,
    error: megaTestExamError,
    isLoading: megaTestExamIsLoading,
    isError: megaTestExamIsError,
  } = useQuery({
    queryKey: QueryKeys.subjective_mega_exam,
    queryFn: getSubMegaExams,
  });

  // Use React Query to fetch users data
  const {
    data: usersData,
    error: usersError,
    isLoading: usersIsLoading,
    isError: usersIsError,
  } = useQuery({
    queryKey: QueryKeys.all_users,
    queryFn: getAllUsers,
  });

  if (megaTestExamIsLoading && usersIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (megaTestExamIsError && usersIsError) {
    return (
      <>
        <p>Error: {megaTestExamError.message}</p>
        <p>Error: {usersError.message}</p>
      </>
    );
  }

  const displayData = megaTestExamData?.filter(
    (item) => item.testType.toLowerCase() === "mega_test"
  );
  console.log("displayDataM", displayData);
  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="Mega Test"
          title="Mega Test Details"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          {/* Container for the button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              variant="contained"
              onClick={handleEditClick}
              className="common-color"
              sx={{
                width: "auto",
                height: "36px", // Maintain the height of the button
                padding: "6px 16px", // Ensure padding is applied correctly
                transition: "background 0.1s ease, transform 0.1s ease",
                marginTop: "-50px", // Adjust the position without altering shape
                "&:hover": {
                  background: "linear-gradient(to right, #edb038, #fb794a)",
                  transform: "scale(1.07)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              Add Exam
            </Button>
          </Box>

          {/* Import table */}
          <Box sx={{ marginTop: "55px" }}>
            <SubjectiveTable data={displayData} usersData={usersData} />
          </Box>
        </Box>
        {isEditOpen && (
          <AddExamModal
            formData={selectedFormData}
            open={isEditOpen}
            formName="Add Mega Test"
            onClose={() => setIsEditOpen(false)}
          />
        )}
      </Box>
    </>
  );
};

export default MegaTest;
