import { GET_ALL_PROJECTS,
        UPDATE_PROJECT_APPROVAL,
        USER_PROJECT,
        EDIT_PROJECT_SUBMISSION,
        MAKE_PROJECT_SUBMISSION} from '../actionsType';
import * as api from './api';


export const getAllProjects = () => async (dispatch) => {
  try {
    const { data } = await api.getAllProjects();
    dispatch({
      type: GET_ALL_PROJECTS,
      payload: data,
    });
    return data;
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message); // You can dispatch an error action here if needed
    
  }
};

export const userProjects = () => async (dispatch) => {
  try {
    const { data } = await api.userProjects();
    dispatch({
      type: USER_PROJECT,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
   
  }
};

export const updateApprovalByTeacher = (id, approval) => async (dispatch) => {
  try {
    const { data } = await api.updateApproval(id, approval);
    dispatch({
      type: UPDATE_PROJECT_APPROVAL,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
 
  }
};

export const makeProjectSubmission = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.makeProjectSubmission(id, formData);
    dispatch({
      type: MAKE_PROJECT_SUBMISSION,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  
  }
};

export const checkProjectSubmission = (projectId) => async (dispatch) => {
  try {
    const { data } = await api.checkProjectSubmission(projectId);
    // You can dispatch an action here if needed
    return data;
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
    
  }
};

export const editProjectSubmission = (projectId, formData) => async (dispatch) => {
  try {
    const { data } = await api.editProjectSubmission(projectId, formData);
    dispatch({
      type: EDIT_PROJECT_SUBMISSION,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
    
  }
};
