import React from "react";
import { Route, Routes } from "react-router-dom";
import MCQ from "../../../pages/Students/ViewTest/MCQ_TYPE/MCQ";
import TestScreen from "../../../pages/Students/ViewTest/MCQ_TYPE/TestScreen";
import UserSubjective from "../../../pages/Students/ViewTest/Subjective_Type/UserSubjective";
import UserMegaTest from "../../../pages/Students/ViewTest/Mega_Test_Type/UserMegaTest";
import MCQExamResult from "../../../pages/Students/ViewTest/MCQ_TYPE/MCQExamResult";

const StudentTestRoutes = () => {
  return (
    <Routes>
      <Route path="/student_mega-test" element={<UserMegaTest />} />

      <Route path="/student_mcq-test" element={<MCQ />} />
      <Route path="/student_mcq-test-result" element={<MCQExamResult />} />
      <Route path="/student_mcq-test-screen" element={<TestScreen />} />

      <Route path="/student_subjective-test" element={<UserSubjective />} />
    </Routes>
  );
};

export default StudentTestRoutes;
