
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { studentRequests } from "../redux/actions/requestActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getMeetings } from "../redux/actions/meetingActions";

export default function ForgotLectures({ closeModal,setLoginModal }) {
  const { meetings } = useSelector((store) => store.meetingReducer);
  const {userData}=useSelector((store)=>store.userReducer)
 

  const studentName = userData?.firstname + " " + userData?.lastname;
  const standard = userData?.standard;

  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((store) => store.userReducer);
  const dispatch = useDispatch();
  // const [loginModel, setLoginModal] = useState(false);
  const [formData, setFormData] = useState({
    studentName: studentName,
    chapterName: "",
    reason: "",
    teacherId: "",
    standard: standard,
    subjectName: "",
    date: "",
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!meetings) {
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]);

  const meetingData = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const currentDate = new Date();
  const twentyFourHoursAgo = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
  const filteredMeetings = meetingData.filter((item) => {
    const meetingDate = new Date(item.date);
    return meetingDate >= twentyFourHoursAgo && meetingDate <= currentDate;
  });

  let classWiseMeetings;

  // If not authenticated, show dummy data
  if (!isAuthenticated) {
    classWiseMeetings = [
      { class:"9",chapter: "World War 1", subject: "History", unit: "Class 9" },
      {class:"9", chapter: "Singapore", subject: "Geography ", unit: "Class 10" },
      { class:"9",chapter: "DFS & BFS Search", subject: "Data Structures", unit: "Class 11" },
      { class:"9",chapter: "Deep learning", subject: "Artificial Intelligance", unit: "Class 12" },
    ];
  } else {
    classWiseMeetings = filteredMeetings?.filter((item) => item.class == standard);
    console.log('first', classWiseMeetings)
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {

      console.log('triggered')
setLoginModal(true)
      // closeModal()
     
  
  
    } else {
      try {
        const selectedLecture = classWiseMeetings?.find(
          (item) => item.chapter === formData.chapterName
        );

        console.log('selected lect', selectedLecture);
        if (selectedLecture) {
          const { createdBy, date, subject, unit, teacherId } = selectedLecture;

          const requestData = {
            ...formData,
            teacherId: teacherId,
            subjectName: subject,
            date: date,
            createdBy: createdBy,
            unit: unit,
          };

          console.log(requestData);

          await dispatch(studentRequests(requestData));
          closeModal();
          toast.success("Request submitted successfully");
        } else {
          toast.error("Invalid lecture selected");
        }
      } catch (error) {
        toast.error("Failed to submit request");
      }
    }
  };

  // return (

    
  //   <div className="m-4">
  //     {loginModel && (
  //    <LoginModal setLoginModal={setLoginModal} />
  //  )}
  //     <form onSubmit={handleSubmit}>
  //       <div className="row justify-content-end justify-items-center ">
  //         <button
  //           type="btn-2"
  //           className="close"
  //           onClick={closeModal}
  //           aria-label="Close"
  //         >
  //           X
  //         </button>
  //       </div>

  //      {classWiseMeetings && (
  //         <div className="form-group mb-2">
  //           <label htmlFor="exampleInputPassword1">
  //             <strong>Select Lecture</strong>
  //           </label>

  //           <select
  //             className="form-control"
  //             onChange={onChange}
  //             name="chapterName"
  //             value={formData.chapterName}
  //           >
  //             <option value="">Select Lecture</option>
  //             {classWiseMeetings.map((item, index) => (
  //               <option key={index} value={item?.chapter}>
  //                 {item?.subject} - {item?.chapter} - {item?.unit}
  //               </option>
  //             ))}
  //           </select>
  //         </div>
  //       )} 
  //      {!classWiseMeetings && (
  //         <p className="font-weight-bold " style={{ 'color': 'red' }}>No lectures are available for this class.</p>
  //       )}
 

  //       <div className="form-group mb-4">
  //         <label>
  //           <strong>Enter reason</strong>
  //         </label>
  //         <textarea
  //           name="reason"
  //           className="form-control"
  //           onChange={onChange}
  //           rows="3"
  //           required
  //           value={formData.reason}
  //         ></textarea>
  //       </div>

  //       <button type="submit" className="btn btn-primary">
  //         Submit
  //       </button>
  //     </form>
  //   </div>
    
  // );


  return (
    <div>
    
      
      <div className="m-4">
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-end justify-items-center ">
            <button
           
              type="button"
              className="close"
              onClick={closeModal}
              aria-label="Close"
            >
              X
            </button>
          </div>
  
          {classWiseMeetings && (
            <div className="form-group mb-2">
              <label htmlFor="exampleInputPassword1">
                <strong>Select Lecture</strong>
              </label>
              <select
                className="form-control"
                onChange={onChange}
                name="chapterName"
                value={formData.chapterName}
              >
                <option value="">Select Lecture</option>
                {classWiseMeetings.map((item, index) => (
                  <option key={index} value={item?.chapter}>
                    {item?.subject} - {item?.chapter} - {item?.unit}
                  </option>
                ))}
              </select>
            </div>
          )}
          
          {!classWiseMeetings && (
            <p className="font-weight-bold" style={{ color: 'red' }}>
              No lectures are available for this class.
            </p>
          )}
  
          <div className="form-group mb-4">
            <label>
              <strong>Enter reason</strong>
            </label>
            <textarea
              name="reason"
              className="form-control"
              onChange={onChange}
              rows="3"
              required
              value={formData.reason}
            ></textarea>
          </div>
  
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
  


  

  
}
