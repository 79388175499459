import { GET_ALL_USERS_BY_TEACHER,CREATE_SINGLE_USER_BY_TEACHER,CREATE_BULK_USER,ALL_PENDING_USERS} from '../actionsType';

const initialState={
pendingData:null,
legitUserData:null
}

const bulkUserReducer=(state=initialState,action)=>{

    switch(action.type){
        case ALL_PENDING_USERS:
            return{
                ...state,
                pendingData: action?.payload
            }
        case GET_ALL_USERS_BY_TEACHER:
            return{
                ...state,
                legitUserData: action?.payload
            }

         default:
            return state;
    }

}

export default bulkUserReducer;