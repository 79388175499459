import { client } from "../../api_client";
import config from "../../../config";
const URL = config.BASE_URL;

const endPoints = {
  addQuestion: "create",
};

const API_URLS = {
  CREATE_MCQ_QUESTION: `${URL}/api/question/${endPoints.addQuestion}`,
};

export const createMcqQuestions = async (postData) => {
  try {
    const url = `${API_URLS.CREATE_MCQ_QUESTION}`;

    

    const formData = new FormData();
    if( postData.get("examId")===null){
     console.log('first', postData.get("examId"))
    }
    formData.append("examId", postData.get("examId"));
    formData.append("write_question", postData.get("write_question"));
    formData.append("correct_answer", postData.get("correct_answer"));

    // Get study options as an array
    const AllOptions = postData.getAll("options");

    // Append options files using the same key
    AllOptions.forEach((file, index) => {
      formData.append("options", file);
    });

    const response = await client.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response?.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error(
      error.message || "An error occurred while creating the post."
    );
  }
};
