import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MCQExamAttendByUserCol } from "../../../../../data/mockData";

const MCQStudAttemptedTable = ({ studentDetails, examData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //   const classes = useStyles(); // Apply the styles
  const [searchQuery, setSearchQuery] = useState("");

  console.log("studentDetailsResult", studentDetails);
  console.log("examDataResult", examData);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = studentDetails?.filter((item) =>
    item?.firstname.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  console.log("filteredData", filteredData);
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const totalLength = filteredData && filteredData?.length;

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {MCQExamAttendByUserCol?.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    textAlign: "center",
                    borderColor: "black", // Set border color
                    borderWidth: 1, // Set border width
                    borderStyle: "solid", // Set border style
                  }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((item, index) => {
              const studentExamData = examData?.find(
                (exam) => exam.userId == item.id
              );
              return (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item?.firstname}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item?.standard}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {studentExamData?.obtainedMarks}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {studentExamData?.percentage?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {studentExamData.rupeesEarned?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {studentExamData.loyaltyPointsEarned?.toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          padding: "0px !important",
          margin: { lg: "0", md: "0", sm: "0", xs: "10px 0px" },
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography sx={{ pl: 1 }}>Total Students : {totalLength}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              alignItems: "baseline",
              paddingLeft: "24px", // Ensure this matches the console change
              paddingRight: "24px", // Ensure this matches the console change
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MCQStudAttemptedTable;
