import { client } from "../api_client";
import config from "../../config/index";
const URL = config.BASE_URL;

const endPoints = {
  addUser: "create",
  getAllUser: "getAllUsers",
};

const API_URLS = {
  GET_ALL_USERS: `${URL}/api/user/${endPoints.getAllUser}`,
  CREATE_USERS: `${URL}/api/user/${endPoints.addUser}`,
};

export const getAllUsers = async () => {
  try {
    const url = `${API_URLS.GET_ALL_USERS}`;
    const response = await client.post(url);

    const data = response.data;

    return data;
  } catch (error) {
    throw new Error("An error occurred while fetching the data.");
  }
};

export const createUsers = async (postData) => {
  try {
    const url = `${API_URLS.CREATE_USERS}`;

    const response = await client.post(url, postData);

    const data = response.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("An error occurred while creating the post.");
  }
};
