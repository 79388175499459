import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from './Navbar';
import Footer from './Footer';
import BlogCard from './BlogCard';
import { getAllBlogs } from '../redux/actions/blogActions';
import '../styles/BlogsPage.css';

const BlogsPage = () => {
  const dispatch = useDispatch();
  const { allBlogs } = useSelector((store) => store.blogReducer);

  useEffect(() => {
    if (allBlogs.length === 0) {
      dispatch(getAllBlogs());
    }
  }, [dispatch, allBlogs]);

  return (
    <div className='BlogsPage'>
      <Navbar activeMenuItem={'blogs'} />
      <div className='BlogsPage-title-container'>
        <h1>Blogs</h1>
        <p>
          “IT ADDS UP!!! “ If you just read 15 mins a day you end up reading
          10,00,000 words in a year, and when you don’t it’s not just the words
          that you miss out it’s a whole lot of experience and knowledge.{' '}
        </p>
      </div>
      <div className='events-card-container'>
        {allBlogs?.map((blog) => (
          <BlogCard blog={blog} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default BlogsPage;
