import React from "react";
import moment from "moment";
import "../styles/Receipt.css";
import { toWords } from "number-to-words";
import { capitalizeFirstLetter } from "../util/helperFunctions";
const Invoice = ({ billData }) => {
  const TAX = 9;
  const SAC = 999299;
  const TOTAL=billData?.amount || 0;


  const GST = billData?.state==='Maharashtra'? 0: (TOTAL * 0.09)?.toFixed(2);  // Restricting to 2 decimal places
  const IGST=billData?.state!=='Maharashtra'? 0: (TOTAL * 0.18)?.toFixed(2); 
  const TDS = Math.ceil( (TOTAL * 0.1).toFixed(2)).toFixed(2);   // Restricting to 2 decimal places
  
  const TAXABLE_AMOUNT = (TOTAL - (parseFloat(GST) + parseFloat(TDS))).toFixed(2); 
  
   
  const tdStyle = {
    border: "1px solid black",
  };
  return (
    <>
      <div className="text-center">
        {" "}
        <h6 style={{ marginTop: "2rem", padding: 0 }}>
          <strong>INVOICE</strong>
        </h6>
        <div
          className="container"
          style={{
            backgroundColor: "white",
            border: "1px solid",
            padding: 0,
            marginTop: 0,
          }}
        >
          <div className="row">
            <div className="text-center">
              <h4 style={{ margin: 0 }}>
                <strong>Enrouting Careers Private Limited</strong>
              </h4>
              <p style={{ margin: 0, fontWeight: "initial" }}>
                PIRIYATH HOUSE 14/35 CHEMBRA OTTAPPALAM
              </p>
              <p style={{ margin: 0 }}>
                Phone no.: +919600722736 Email:enroutingcareers@gmail.com
              </p>
              <p style={{ margin: 0 }}>
                GSTIN:32AAFCE5000L1Z1, State:32-Kerala{" "}
              </p>
            </div>
          </div>

          <br />
          <div>
            <table
              className="table "
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginBottom: 0,
                width: "100%",
              }}
            >
              <thead
                // style={{
                //   color: "white",
                //   backgroundColor: "black",
                //   border: "1px solid black",
                // }}
                className="invoice-table  "
              >
                <tr style={{ margin: 0, padding: 0 }}>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "start",
                      fontSize: "14px",
                      width: "50%",
                    }}
                  >
                    Bill To
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "end",
                      fontSize: "14px",
                      width: "50%",
                    }}
                  >
                    Invoice Details
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    <p style={{ textAlign: "start" }}>
                      <strong>{billData?.userName}</strong>
                      <br />
                      {billData?.address}
                      <br />
                      Contact No.: {billData?.phone}
                      <br />
                      State: {billData?.state}
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "end" }}>
                      Invoice No.:EC{billData?.invoiceNumber}
                      <br />
                      Date: {moment(billData?.issueData).format("DD/MM/YYYY")}
                      <br />
                      Place of supply: 32-Kerala
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* ========================================================== */}

            <table
              className="table "
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginBottom: 0,
              }}
            >
              <thead className="invoice-table">
                <tr>
                  <th>
                    <h5>#</h5>
                  </th>
                  <th>
                    <h5>Item name</h5>
                  </th>
                  <th>
                    <h5>HSN / SAC</h5>
                  </th>
                  <th>
                    <h5>Quantity</h5>
                  </th>
                  <th>
                    <h5>Unit</h5>
                  </th>
                  <th>
                    <h5>Price/unit</h5>
                  </th>
                  <th>
                    <h5>Taxable amount</h5>
                  </th>
                  <th>
                    <h5>CGST</h5>
                  </th>
                  <th>
                    <h5>SGST</h5>
                  </th>
                  <th>
                    <h5>IGST</h5>
                  </th>
                  <th>
                    <h5>Amount</h5>
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td style={tdStyle}>
                    <strong>1</strong>
                  </td>
                  <td style={tdStyle}>
                    <strong>{billData?.description}</strong>
                  </td>
                  <td style={tdStyle}>{SAC}</td>
                  <td style={tdStyle}>1</td>
                  <td style={tdStyle}>1</td>
                  <td style={tdStyle}>₹ {billData?.amount}</td>
                  <td style={tdStyle}>₹ {TAXABLE_AMOUNT}</td>
                  <td style={tdStyle}>
                    ₹ {GST}
                    <br />({TAX}%)
                  </td>
                  <td style={tdStyle}>
                    ₹ {GST}
                    <br />({TAX}%)
                  </td>
                  <td style={tdStyle}>
                    ₹ {IGST}
                    <br />({TAX+TAX}%)
                  </td>

                  <td style={tdStyle}>₹ {billData?.amount}</td>
                </tr>
              </tbody>

              <tbody
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}>
                    <strong>Total</strong>
                  </td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}>₹ {TAXABLE_AMOUNT}</td>
                  <td style={tdStyle}>₹ {GST}</td>
                  <td style={tdStyle}>₹ {GST}</td>
                  <td style={tdStyle}>₹ {IGST}</td>
                  <td style={tdStyle}>
                    <span>₹{TOTAL}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* ================================================================= */}

            <div style={{ display: "ruby", width: "100%" }}>
              {/* Parent Table */}
              <table
                className="table"
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  marginBottom: 0,
                  width: "50%",
                }}
              >
                <tbody style={{ border: "1px solid black" }}>
                  <tr>
                    <td style={{ ...tdStyle, textAlign: "start" }}>
                      Tax Details
                    </td>
                    <td style={{ ...tdStyle, textAlign: "end" }}>{TAX}%</td>
                  </tr>
                  <tr>
                    <td style={{ ...tdStyle, textAlign: "start" }}>CGST</td>
                    <td style={{ ...tdStyle, textAlign: "end" }}>₹ {GST}</td>
                  </tr>
                  <tr>
                    <td style={{ ...tdStyle, textAlign: "start" }}>SGST</td>
                    <td style={{ ...tdStyle, textAlign: "end" }}>₹ {GST}</td>
                  </tr>
                </tbody>
              </table>

              {/* New Accounts Table */}
              {/* <table
                className="table"
                style={{
                  border: "1px solid black",
                  marginBottom: 0,
                  width: "50%",
                }}
              >
                <thead
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <tr
                    className="invoice-table "
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      background: "linear-gradient(to right, #fb794a, #edb038)",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                        border: "none",
                        background: "transparent",
                        color: "white",
                        fontSize: "inherit",
                      }}
                    >
                      Amounts
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        border: "none",
                        background: "transparent",
                      }}
                    ></td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "none",
                        padding: "10px 0px 0 0px",
                        textAlign: "start",
                      }}
                    >
                      Sub Total
                    </td>
                    <td style={{ border: "none", padding: "10px 0px 0 0" }}>
                     ₹ {TOTAL}
                    </td>
                  </tr>

                

                  <tr>
                    <th
                      style={{
                        padding: "5px 0px",
                        textAlign: "start",
                        border: "none",
                      }}
                    >
                      Total
                    </th>
                    <th style={{ padding: "5px 0px", border: "none" }}>₹ {TOTAL_AFTER_DEDUCTION}</th>
                  </tr>

                  <tr>
                    <td style={{ padding: "0 0px", textAlign: "start" }}>
                      Received
                    </td>
                    <td style={{ padding: "0 0px" }}>₹ {TOTAL_AFTER_DEDUCTION}</td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        padding: "5px 0px",
                        textAlign: "start",
                        border: "none",
                      }}
                    >
                      Balance
                    </td>
                    <td style={{ padding: "5px 0px", border: "none" }}>₹ 0</td>
                  </tr>

                  <tr>
                    <th style={{ padding: "5px 0px", textAlign: "start" }}>
                      Invoice Value Before TDS
                    </th>
                    <th>₹ {TOTAL}</th>
                  </tr>
                </tbody>
              </table> */}
            </div>

            <table
              className="table"
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginBottom: 0,
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead className="invoice-table">
                <tr>
                  <th style={{ width: "50%" }}>
                    <h5>Invoice Amount in Words</h5>
                  </th>
                  <th style={{ width: "50%" }}>
                    <h5>Description</h5>
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td
                    style={{ ...tdStyle, textAlign: "start", padding: "10px" }}
                  >
                    {billData?.amount
                      ? capitalizeFirstLetter(toWords(billData?.amount)) + " Rupees Only"
                      : "-"}
                  </td>
                  <td
                    style={{ ...tdStyle, textAlign: "start", padding: "10px" }}
                  >
                    {" "}
                    Billing Period Month of{" "}
                    {moment(billData?.issueData).format("MMMM YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              className="table"
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginBottom: 0,
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead className="invoice-table">
                <tr>
                  <th style={{ width: "50%" }}>
                    <h5>Bank Details</h5>
                  </th>
                  <th style={{ width: "50%" }}>
                    <h5>Terms and Conditions</h5>
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td style={{ textAlign: "start", padding: "10px" }}>
                    Name: ICICIBANK LIMITED, PATTAMBI, KERALA
                    <br />
                    Account No.: 117405000842
                    <br />
                    IFSC code:ICICI0001174
                    <br />
                    Account holder's name: Enrouting Careers Pvt Ltd
                  </td>
                  <td
                    style={{ ...tdStyle, textAlign: "start", padding: "10px" }}
                  >
                    Thank you for doing business with us. This invoice is
                    Computer Generated & Does not require any signature.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
