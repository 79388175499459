import React, { useState, Fragment } from 'react';
import closeIcon from '../images/ic_close.svg';
import '../styles/RegisterModal.css';

const RegisterProjects = (props) => {
  const { setRegisterModal, buyNow, item } = props;
  const [participationType, setParticipationType] = useState('');
  // const [memberNames, setMemberNames] = useState('');
  const [inputList, setInputList] = useState([{ name: '' }]);

  const handleChange = (e) => {
    setParticipationType(e.target.value);
  };

  const handleRegister = () => {
    const memberString = inputList.map((x) => x.name).join(',');
    buyNow(participationType, memberString);
    setRegisterModal(false);
  };

  // const handleMembersChange = (e) => {
  //   setMemberNames(e.target.value);
  // };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: '' }]);
  };

  return (
    <div className='RegisterModal'>
      <div className='RegisterModal-content'>
        <span onClick={() => setRegisterModal(false)}>
          <img
            src={closeIcon}
            alt='close-icon'
            className='RegisterModal-close-icon'
          />
        </span>
        <h1>Registration Details</h1>
        <div
          className={`${
            item?.isVideo
              ? 'RegisterModal-type-div'
              : 'RegisterModal-type-div-center'
          }`}
        >
          <p style={{ margin: '16px 32px' }}>Price : ₹{item?.price}</p>
          
        </div>
     
        <div className='RegisterModal-button-div'>
          <button className='RegisterModal-button' onClick={handleRegister}>
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterProjects;
