import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./Footer";

import "../styles/HomePage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TeacherNavbar from "./Teacher/TeacherNavbar";
import Cards from "./Cards";
import Dropdown from "./Teacher/Dropdown";
import "../styles/Cards.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import TeacherCards from "./teacherCard";

const TeacherHome = () => {
  const token = Cookies.get("teacherJWT");
  let decoded = null;
  if (token) {
    try {
      decoded = jwtDecode(token);
    //  console.log("token decoded", decoded);
    } catch (error) {
     // console.error("Invalid token:", error);
    }
  } else {
   /// console.error("Token is not available or invalid");
  }
  return (
    <div className="HomePage">
      <TeacherNavbar activeMenuItem={"home"} />

      <div className="container-fluid">
        <div className="row justify-content-between m-5">
          {/* Left Column for Upcoming Classes */}
          <div className=" col-12 col-md-8 col-sm-8 mt-5 order-1 order-md-0">
            <h3>Upcoming Live Classes</h3>
            <div className="row">
              <Cards />
              {/* You can continue adding more Cards components here */}
            </div>
          </div>

          {/* Right Column for Schedule Live Class */}
          <div className=" col-12 col-md-4 col-sm-4 p-0 mt-5 order-0 order-md-1">
            <h3 className="mb-4">Schedule Live Class</h3>
            <Dropdown />
            {/* <ScheduleMeeting/> */}
          </div>
        </div>
      </div>

      <Footer className="homepg mt-6" />
    </div>

    // <div className='HomePage'>
    //     <TeacherNavbar activeMenuItem={'home'} />

    //     <div className="container-fluid  pt-4"> {/* Added mx-auto for horizontal centering */}
    //         <div className="row m-3">
    //             {/* Left Column for Upcoming Classes */}
    //             <div className="col-md-8"> {/* Added px-4 for horizontal padding */}
    //                 <h2 className="mb-4">Upcoming Live Classes</h2>
    //                 <div className="row">
    //                  <Cards/>
    //                     {/* Continue adding more Cards components here */}
    //                 </div>
    //             </div>

    //             {/* Right Column for Schedule Live Class */}
    //             <div className='col-md-4'> {/* Added px-4 for horizontal padding */}
    //                 <h2 className="mb-4">Schedule Live Class</h2>
    //                 <Dropdown />
    //                 {/* <ScheduleMeeting /> */}
    //             </div>
    //         </div>
    //     </div>

    //     <Footer className='homepg' />
    // </div>
  );
};

export default TeacherHome;
