import { GET_ALL_PROJECTS,
    UPDATE_PROJECT_APPROVAL,
    USER_PROJECT,
    EDIT_PROJECT_SUBMISSION,
    MAKE_PROJECT_SUBMISSION} from '../actionsType';

const initialState={
projectData:null,
userProject:null
}

const projectReducer=(state=initialState,action)=>{

    switch(action.type){
        case GET_ALL_PROJECTS:
            return{
                ...state,
                projectData: action?.payload
            }


        case USER_PROJECT:
            return{
                ...state,
                userProject:action?.payload
            }
        

         default:
            return state;
    }

}

export default projectReducer;