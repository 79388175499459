import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../redux/actions/meetingActions";
import { formatDate, getMinuteDifference, calculateCountdown } from "../functions/date";
import maleTeacher from "../images/front-view-man-classroom.jpg";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { ZoomMtg } from '@zoom/meetingsdk';
import "../styles/Cards.css";
import config from "../config";

const TeacherCards = () => {
  const authEndpoint = `${config.BASE_URL}/api/signature`; 
  const sdkKey = "UwiPZGu7QGCDrs7Im38vTw";
  const meetingNumber = '83302746639'; // Assign meetingNumber here
   const passWord = 'YpXL1M'; // Assign passWord here

  const role = 1;
  const userName = "Enrouting Careers";
  const leaveUrl = "https://enroutingcareers.com/teacher-home"; //"http://localhost:3000/teacher-home";

  const userEmail = '';
  const registrantToken = '';
  const zakToken = '';

  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const token = jwtDecode(Cookies.get("teacherJWT"));
  const teacherId = token?.id;

  const getSignature = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(authEndpoint, {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meetingNumber, role })
      });
      const response = await res.json();
      startMeeting(response.signature);
    } catch (error) {
      console.error("Error fetching signature:", error);
    }
  };

  const startMeeting = (signature) => {
    ZoomMtg.init({
      leaveUrl,
      success: () => {
        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: () => {
            console.log("Successfully joined meeting");
          },
          error: (error) => {
            console.error("Error joining meeting:", error);
          }
        });
      },
      error: (error) => {
        console.error("Error initializing Zoom SDK:", error);
      }
    });
  };

  useEffect(() => {
    if (!meetings) {
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]);

  const meeetingData = meetings?.data?.meeting ? [...meetings.data.meeting].reverse() : [];
  const currentTime = new Date().getTime();
  const activeMeetings = meeetingData.filter((meeting) => new Date(meeting.endTime).getTime() > currentTime);
  const meetingByTeacher = activeMeetings.filter((item) => item.teacherId == teacherId);

  if (!meetingByTeacher || meetingByTeacher.length === 0) {
    return (
      <div className="custom-card_no_meet mt-3">
        <div className="custom-flex">
          <div className="custom-body">
            <p className="custom-text">No lectures are scheduled</p>
          </div>
        </div>
      </div>
    );
  }

  console.log('meeting by teacher', meetingByTeacher)

  return (
    <>
     {meetingByTeacher.map((item, index) => {
  const differenceMinutes = getMinuteDifference(item.startTime, item.endTime);
  const startTimeMillis = new Date(item?.startTime).getTime();
  const endTimeMillis = new Date(item?.endTime).getTime();
  const { label, disabled, color } = calculateCountdown(startTimeMillis, endTimeMillis);

  
  return (
    <div key={index} className="custom-card m-3">
      <div className="custom-flex">
        <div className="custom-image">
          <img src={maleTeacher} className="custom-img" alt="Card" />
        </div>
        <div className="custom-body">
          <div className="custom-date">
            {formatDate(item?.date)} | {differenceMinutes}mins
          </div>
          <h5 className="custom-title">{item?.chapter} | {item?.subject}</h5>
          <p className="custom-text">{item?.createdBy}</p>
          <p className="custom-class">{item?.class}th</p>
          <span style={{ color }}>{label}</span>
          {!disabled && (
            <button className={`custom-btn ${color}`} onClick={getSignature}>
              JOIN
            </button>
          )}
        </div>
      </div>
    </div>
  );
})}

    </>
  );
};

export default TeacherCards;
