import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from './Navbar';
import Footer from './Footer';
import { validator } from '../util/helperFunctions';
import { helpMail } from '../redux/actions/helpEmailActions';
import helpImage from '../images/img_help.svg';
import '../styles/HelpPage.css';

const initialData = {
  firstname: '',
  lastname: '',
  email: '',
  description: '',
};

const HelpPage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ['firstname', 'lastname', 'email', 'description'];
    
    const result = validator(formData, requiredFields);
    if (result === true) {
      setErrors({});
      dispatch(helpMail(formData)).then((res) => {
        setFormData(initialData);
        alert(res.message);
      });
    } else {
      setErrors(result);
    }
  };

  return (
    <div className='HelpPage'>
      <Navbar activeMenuItem={'help'} />
      <div className='HelpPage-container'>
        <div className='HelpPage-container-left'>
          <h1 className="jj">Let's Connect</h1>
          <img src={helpImage} alt='help-img' className='help-img' />
        </div>
        <div className='HelpPage-container-right'>
          <div className='HelpPage-text-box'>
            <h1 className="kk">Let's connect</h1>
            <p>
              Let us know if you have something interesting to say and share it
              with us. If you have any questions, just fill in the contact form
              and we will answer you shortly.
            </p>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <div className='HelpPage-name-container'>
                <div className='HelpPage-inputDiv-name'>
                  <input
                    type='text'
                    placeholder='First name'
                    name='firstname'
                    value={formData.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`marginRight ${errors.firstname && touched.firstname ? 'error' : ''}`}
                  />
                  {errors.firstname && touched.firstname && (
                    <label className='errorMessage' htmlFor='firstname'>
                      {errors.firstname}
                    </label>
                  )}
                </div>
                <div className='HelpPage-inputDiv-name'>
                  <input
                    type='text'
                    placeholder='Last name'
                    name='lastname'
                    value={formData.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`marginLeft ${errors.lastname && touched.lastname ? 'error' : ''}`}
                  />
                  {errors.lastname && touched.lastname && (
                    <label className='errorMessage' htmlFor='lastname'>
                      {errors.lastname}
                    </label>
                  )}
                </div>
              </div>
              <div className='HelpPage-inputDiv'>
                <input
                  type='text'
                  placeholder='Email address'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`HelpPage-input ${errors.email && touched.email ? 'error' : ''}`}
                />
                {errors.email && touched.email && (
                  <label className='errorMessage' htmlFor='email'>
                    {errors.email}
                  </label>
                )}
              </div>
              <div className='HelpPage-inputDiv'>
                <textarea
                  rows='5'
                  placeholder='Type message here...'
                  name='description'
                  value={formData.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`HelpPage-input ${errors.description && touched.description ? 'error' : ''}`}
                />
                {errors.description && touched.description && (
                  <label className='errorMessage' htmlFor='description'>
                    {errors.description}
                  </label>
                )}
              </div>
              <div className='HelpPage-button-container'>
                <button type='submit'>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpPage;
