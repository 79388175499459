import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

// import '../styles/AboutUsPage.css';
import StudentCard from './StudentCard';
import { useSelector } from 'react-redux';
import DummyCards from './DummyCards';
import ForgotLectures from './ForgotLectures';
import { Grid, Typography } from '@mui/material';
import { Box } from 'victory';
import { Button } from 'react-bootstrap';
import LoginModal from "./LoginModal";

const Live = () => {


const {isAuthenticated }=useSelector((store)=>store.userReducer)
 const [requestLectureModel,setLectureRequestModel]=useState(false)
 const [loginModel, setLoginModal] = useState(false);

    return (
      <>
        <Navbar />
        <div className='container mb-5 no-flex'>
     
       
     
       
        <div className='d-flex align-items-center justify-content-between'> 
      <h3>Upcoming Live Classes</h3>

      <button
         style={{'padding':'0px 8px 0px 8px',
         'background': 'linear-gradient(to right, #fb7b49, #edaf38)',
         'color': 'aliceblue',
         'border':'none',
         'borderRadius':'8px',
         'marginLeft':'4px'
      }}onClick={()=>setLectureRequestModel(true)}>Request a lecture</button>
   
   </div>


<div className='row'> {!isAuthenticated? <DummyCards/>
         : <StudentCard/>} 
      </div>
         
      

      {loginModel && (
        <div>
          <LoginModal setLoginModal={() => setLoginModal(false)} />{" "}
        </div>
      )}
      
      {requestLectureModel && (
        <div className="model-overlay">
          <div className="model">
          
            <ForgotLectures closeModal={() => setLectureRequestModel(false)} setLoginModal={setLoginModal} />
          </div>
        </div>
      )}
   
          
          </div>
          <Footer />
        </>
   
    );
  
};

export default Live;