import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MegaExamAttendByUserCol } from "../../../../../data/mockData";
import FlakyIcon from "@mui/icons-material/Flaky";
import AddExamModal from "../../../../../components/addExamModal/AddExamModal";

const MegaTableMarks = ({
  isEditOpen,
  setIsEditOpen,
  studentData,
  examData: initialExamData,
}) => {
  const [examData, setExamData] = useState(initialExamData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  console.log("examDataF", examData);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //for exam modal
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [selectedExamData, setSelectedExamData] = useState(null);

  //for edit modal open and closed
  const handleMarksClick = (user, userExamData) => {
    setSelectedFormData(user?.id);
    setSelectedExamData(userExamData?.examId);
    setIsEditOpen(true);
  };

  const handleMarksUpdate = (userId, newMarks) => {
    setExamData((prevData) =>
      prevData.map((exam) =>
        exam.userId === userId ? { ...exam, obtainedMarks: newMarks } : exam
      )
    );
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = studentData?.filter((item) =>
    item?.firstname?.toLowerCase().startsWith(searchQuery.toLowerCase())
  );
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const totalLength = filteredData && filteredData?.length;

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {MegaExamAttendByUserCol?.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    textAlign: "center",
                    borderColor: "black", // Set border color
                    borderWidth: 1, // Set border width
                    borderStyle: "solid", // Set border style
                  }}
                >
                  {column.name}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  textAlign: "center",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((user) => {
              const userExamData = examData.find(
                (exam) => exam.userId === user.id
              );
              return (
                <TableRow key={user.id}>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {user?.firstname}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {user?.standard}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {userExamData && (
                      <a
                        href={userExamData?.AnswerPaperPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        Read Answer Sheet
                      </a>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      <Tooltip
                        title={
                          userExamData?.obtainedMarks
                            ? "Marks already added"
                            : "Add Marks"
                        }
                      >
                        <span>
                          <FlakyIcon
                            sx={{
                              cursor: userExamData?.obtainedMarks
                                ? "not-allowed"
                                : "pointer",
                              opacity: userExamData?.obtainedMarks ? 0.5 : 1,
                            }}
                            onClick={() =>
                              !userExamData?.obtainedMarks &&
                              handleMarksClick(user, userExamData)
                            }
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          padding: "0px !important",
          margin: { lg: "0", md: "0", sm: "0", xs: "10px 0px" },
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography sx={{ pl: 1 }}>Total Students : {totalLength}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              alignItems: "baseline",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          />
        </Box>
        {isEditOpen && (
          <AddExamModal
            formData={selectedFormData}
            exam_Id={selectedExamData}
            open={isEditOpen}
            formName="Add Mega Marks"
            onClose={() => setIsEditOpen(false)}
            setIsEditOpen={setIsEditOpen}
            onMarksUpdate={handleMarksUpdate}
          />
        )}
      </Box>
    </>
  );
};

export default MegaTableMarks;
