import React, { useState } from 'react';
import TeacherNavbar from './TeacherNavbar';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from '../Footer';

function Setting(props) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="d-flex flex-column min-vh-100" style={{ backgroundColor: "#f5f5f5" }}>
            <div className="container-fluid">
                <TeacherNavbar />
            </div>

            <div className="container my-5" style={{ paddingTop: "80px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="card shadow-lg border-0 rounded-lg overflow-hidden">
                            <div className="card-header text-center py-4" style={{ backgroundColor: "#000000", color: "#ffffff" }}>
                                <h3 className="mb-0 fw-bold">Teacher Profile</h3>
                            </div>
                            <div className="card-body" style={{ backgroundColor: "#ffffff" }}>
                                <form>
                                    <div className="row mb-4">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="firstName" className="form-label fw-bold">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="firstName"
                                                placeholder="Demo"
                                                value="Demo"
                                                readOnly
                                                style={{ backgroundColor: "#f8f9fa", color: "#212529", borderColor: "#ced4da" }}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="lastName" className="form-label fw-bold">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="lastName"
                                                placeholder="Teacher"
                                                value="Teacher"
                                                readOnly
                                                style={{ backgroundColor: "#f8f9fa", color: "#212529", borderColor: "#ced4da" }}
                                            />
                                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role" className="form-label fw-bold">Your Role</label>
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="role"
                                placeholder="Teacher"
                                value="Teacher"
                                readOnly
                                style={{ backgroundColor: "#f8f9fa", color: "#212529", borderColor: "#ced4da" }}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title" className="form-label fw-bold">Title</label>
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="title"
                                placeholder="Title"
                                style={{ backgroundColor: "#ffffff", color: "#212529", borderColor: "#ced4da" }}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="form-label fw-bold">Password</label>
                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="form-control form-control-lg"
                                id="password"
                                placeholder="********"
                                style={{ backgroundColor: "#ffffff", color: "#212529", borderColor: "#ced4da" }}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-dark"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-bold">Subjects</label>
                        <div className="row">
                            {["Math", "Vocabulary", "Life Skills", "Language Arts", "Social Studies", "Science"].map((subject, index) => (
                                <div className="col-md-4 mb-2" key={index}>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id={`subject${index}`}
                                            checked={subject === "Social Studies"}
                                            readOnly={subject === "Social Studies"}
                                        />
                                        <label className="form-check-label" htmlFor={`subject${index}`}>{subject}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-bold">Grades</label>
                        <div className="d-flex flex-wrap gap-2">
                            {[...'K123456789101112'].map((grade, index) => (
                                <button 
                                    key={index}
                                    type="button"
                                    className={`btn ${grade === '5' ? 'btn-dark' : 'btn-outline-dark'}`}
                                    style={{ width: '50px', height: '50px' }}
                                >
                                    {grade}
                                </button>
                            ))}
                            <button 
                                type="button"
                                className="btn btn-outline-dark"
                                style={{ width: '50px', height: '50px' }}
                            >
                                All
                            </button>
                        </div>
                    </div>

                    <button type="button" className="btn btn-dark btn-lg w-100">
                        Save Changes
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
</div>

            <div className="mt-auto">
                <Footer />
            </div>
        </div>
    );
}

export default Setting;