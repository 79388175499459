import React from 'react';
import '../styles/Footer.css';
import { useNavigate } from 'react-router-dom';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

const Footer = () => {
  const navigate = useNavigate(); // Fix the hook name from history to navigate

  return (
    <>
      <footer className="footer-area footer--light">
        <div className="footer-big">
          <div className="container">
            <div className="row">
              {/* First Column */}
              <div className="col-md-3 col-sm-12 ft-1">
                <h3>
                  <span>Enrouting </span>Careers
                </h3>
                <p>
                  Enrouting Careers helps us to develop skills & interest at an early age of learning in students & by conducting events to find the best of their talents.
                </p>

                <div className="social-media-links">
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <Facebook />
                  </a>
                  <a href="https://www.instagram.com/enrouting_careers/" target="_blank" rel="noopener noreferrer">
                    <Instagram />
                  </a>
                  <a href="https://www.linkedin.com/company/enrouting-careers-pvt-ltd" target="_blank" rel="noopener noreferrer">
                    <LinkedIn />
                  </a>
                  <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                    <Twitter />
                  </a>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <h4 className="footer-widget-title">Explore Us</h4>
                    <ul className='no-padding'>
                      <li>
                        <p className="links-text" onClick={() => navigate('/about')}>About us</p>
                      </li>
                      <li>
                        <p className="links-text" onClick={() => navigate('/story')}>Our story</p>
                      </li>
                      <li>
                        <p className="links-text" onClick={() => navigate('/vision')}>Vision</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <h4 className="footer-widget-title">Support Us</h4>
                    <ul className='no-padding'>
                      <li>
                        <p className="links-text" onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
                      </li>
                      <li>
                        <p className="links-text" onClick={() => navigate('/t&c')}>Terms & Conditions</p>
                      </li>
                      <li>
                        <p className="links-text" onClick={() => navigate('/help')}>Help</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Fourth Column */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <h4 className="footer-widget-title">Contact Us</h4>
                    <p><i className="fas fa-inbox"></i> enroutingcareers@gmail.com</p>
                    <p><i className="fas fa-phone-volume"></i> +91 9600722736</p>
                    <p><i className="fas fa-map-marker-alt"></i> Kerala, India</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mini-footer">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-text">
                <p>&copy; 2021 Enrouting Careers Private Limited. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
