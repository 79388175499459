import { ALL_SUBJECTS} from '../actionsType'

const InitialState={
    allSubjects:[]
}



export const dropdownReducer=(state=InitialState,action)=>{
    switch(action.type){
        case ALL_SUBJECTS:
            return{
                ...state,
                allSubjects:action?.payload
            }
            default:
                return state;
    }

}



export default dropdownReducer;