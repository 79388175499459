import React, { useState, useEffect } from 'react';
import '../styles/TransactionCard.css';

const options = { year: 'numeric', month: 'short', day: '2-digit' };

const ChapterTransactionCard = (props) => {
  const {
    chapter,
    subject,
    yearlyPrice,
    monthlyPrice,
    quarterlyPrice,
    date,
    purchaseDetails,
    handleDownloadInvoice
  } = props;


  const [boolVal, setBoolVal] = useState(false);
  const [details, setDetails] = useState({
    OgAmount: '',
    paidAmount: '',
    message: '',
    date:''
  });
  useEffect(() => {
    if (!boolVal) {
      const subsType = purchaseDetails[purchaseDetails.length - 1].typeofSubscription;

      console.log('subsType', subsType)

      if (subsType === 'Yearly') {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' / Year',
          date:purchaseDetails[purchaseDetails.length - 1].purchasedOn
        });
      } else if (subsType === 'Quarterly') {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' for 3 months',
          date:purchaseDetails[purchaseDetails.length - 1].purchasedOn
        });
      } else {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' for a month',
          date:purchaseDetails[purchaseDetails.length - 1].purchasedOn
        });
      }
      setBoolVal(true);
    }
  }, [boolVal, monthlyPrice, yearlyPrice, quarterlyPrice, purchaseDetails]);

  return (
    <div className='TransactionCard'>
      <div className='TransactionCard-row-1'>
        <p className='first-col'>
           {subject} notes
        </p>
        <p className='second-col'>
          {/* Showing discount if applicable */}
          ₹{details?.paidAmount !== details?.OgAmount ? (
            <>
              {details.paidAmount}{' '}
              <span
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                  marginLeft: '10px',
                  color: '#999',
                }}
              >
                ₹{details?.OgAmount}
              </span>
            </>
          ) : (
            details?.paidAmount
          )}
          
           {details?.message}
        </p>
        <p className='third-col'>
           {details?.date}
         
        </p>
      </div>
      <div className='TransactionCard-row-2'>
        <p className='all-chapters-text'>Single chapter</p>
        <div className='flex'>
        <p className='download-text'
                onClick={() => handleDownloadInvoice('chapter',chapter,'receipt')}
        >Download receipt</p>
        <p className='download-text'
                onClick={() => handleDownloadInvoice('chapter',chapter,'invoice')}
        >Download invoice</p>
        </div>
      </div>
    </div>
  );
};

export default ChapterTransactionCard;
