// // import React, { useEffect } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { useLocation } from 'react-router-dom';
// // import Navbar from './Navbar';
// // import Footer from './Footer';
// // import ChapterCard from './ChapterCard';
// // import '../styles/CoursePage.css';
// // import { Fab } from '@mui/material';
// // import { IoEyeSharp } from 'react-icons/io5';

// // import { getUserCourses } from '../redux/actions/courseActions';

// // const CoursePage = () => {
// //   const location = useLocation();
// //   const dispatch = useDispatch();
// // const courseData = location?.state;
// //   //const courseData = location?.state;



// //   const { isAuthenticated } = useSelector((store) => store.userReducer);
// //   const { userCourses ,userChapters} = useSelector((store) => store.courseReducer);

// //   useEffect(() => {
// //     if (isAuthenticated && !userCourses) {
// //       dispatch(getUserCourses());
// //     }
// //   }, [dispatch, isAuthenticated, userCourses]);
// //   console.log('usercouses', userCourses)

// //   var isPurchased = false;

// //   if (userCourses)
// //     isPurchased = userCourses.some((course) => {
// //       // console.log(course.UserCourses);
// //       let len = course?.UserCourses?.length;
// //       const isValid =
// //         new Date(course?.UserCourses[len - 1].validTill) >= new Date();
// //       return course?.id === courseData?.id && isValid;
// //     });

// //   return (
// //     <div className='CoursePage'>
// //       <Navbar activeMenuItem={'course'} />
// //       <div className='CoursePage-title-container'>
// //         <h1>Welcome to class {courseData?.class}th</h1>
// //         <h2>{courseData?.subject}</h2>
// //         <p>{courseData?.desc}</p>
// //       </div>
// //       <div className='chapter-card-container'>
// //         {!isAuthenticated || !isPurchased
// //           ? courseData?.chapters?.map((chapter, index) =>
// //               index < 2 ? (
// //                 <ChapterCard
// //                   isLocked={false}
// //                   chapter={chapter}
// //                   course={courseData}
// //                 />
// //               ) : (
// //                 <ChapterCard
// //                   isLocked={true}
// //                   chapter={chapter}
// //                   course={courseData}
// //                 />
// //               )
// //             )
// //           : courseData?.chapters?.map((chapter) => (
// //               <ChapterCard
// //                 isLocked={false}
// //                 chapter={chapter}
// //                 course={courseData}
// //               />
// //             ))}
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default CoursePage;


// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import Navbar from './Navbar';
// import Footer from './Footer';
// import ChapterCard from './ChapterCard';
// import '../styles/CoursePage.css';

// import { getUserCourses, getUserChapters } from '../redux/actions/courseActions';
// import { getUserLecturesbyYear } from '../redux/actions/lectureActions';

// const CoursePage = () => {
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const courseData = location?.state;

//   const { isAuthenticated } = useSelector((store) => store.userReducer);
//   const { userData } = useSelector((store) => store.userReducer);
//   const isRegistrationFeesPaid=userData?.isRegistrationFeesPaid
//   // console.log('userData', userData)
  

//   const { userCourses, userChapters} = useSelector((store) => store.courseReducer);
//   const {userLecturesByYEAR}=useSelector((store)=>store.lectureReducer)

//   // Fetch user courses and chapters when authenticated
//   useEffect(() => {
//     if (isAuthenticated) {
//       dispatch(getUserLecturesbyYear())
//       dispatch(getUserCourses());
//       dispatch(getUserChapters());
      
//     }
//   }, []);


//   console.log('lectures', userLecturesByYEAR)
//   console.log('courses', userChapters)
//   console.log('userCourses', userCourses)


//   // Check if the user purchased the full course
//   const isCoursePurchased = userCourses?.some((course) => {
//     const courseInstances = course?.UserCourses?.length;
//     console.log('courseInstances', courseInstances)
//     const validTill = course?.UserCourses[courseInstances - 1]?.validTill;
//     const isValid = new Date(validTill) >= new Date();
//     return course?.id === courseData?.id && isValid;
//   });

//   const isLiveLecturePurchased = userLecturesByYEAR?.some((lectures) => {
//     const courseInstances = lectures?.userPurchasedLectures?.length;
//     console.log('courseInstances', courseInstances)

//      const validTill = course?.userPurchasedLectures[courseInstances - 1]?.validTill;
//      const isValid = new Date(validTill) >= new Date();
//     return lectures?.id === courseData?.id && isValid;
//   });

 
//   console.log('isCouseP', isCoursePurchased)
//   // Check which chapters are unlocked for this course
//   const unlockedChapters = userChapters?.filter((chapter) => chapter.courseId === courseData?.id) || [];
// console.log('unlockedChapters', unlockedChapters)
//   return (
//     <div className="CoursePage">
//       <Navbar activeMenuItem={'course'} />
//       <div className="CoursePage-title-container">
//         <h1>Welcome to class {courseData?.class}th</h1>
//         <h2>{courseData?.subject}</h2>
//         <p>{courseData?.desc}</p>
//       </div>

//       <div className="chapter-card-container">
//         {/* If not authenticated, show all chapters unlocked */}
//         {!isAuthenticated &&
//           courseData?.chapters?.map((chapter) => (
//             <ChapterCard key={chapter.id} isLocked={false} chapter={chapter} course={courseData} isAuthenticated={isAuthenticated} isRegistrationFeesPaid={isRegistrationFeesPaid}/>
//           ))}

     

//         {/* If full course purchased, unlock all chapters */}
//         {isAuthenticated && isCoursePurchased &&
//           courseData?.chapters?.map((chapter) => (
//             <ChapterCard key={chapter.id} isLocked={false} chapter={chapter} course={courseData} isAuthenticated={isAuthenticated} isRegistrationFeesPaid={isRegistrationFeesPaid} />
//           ))}


//              {/* If authenticated but course not purchased, show first 2 chapters unlocked */}
//         {isAuthenticated && !isCoursePurchased &&
//           courseData?.chapters?.map((chapter, index) => (
//             <ChapterCard
//               key={chapter.id}
//               isLocked={index >= 2 && !unlockedChapters.some((unlocked) => unlocked.id === chapter.id)}
//               chapter={chapter}
//               course={courseData}
//               isAuthenticated={isAuthenticated}
//               isRegistrationFeesPaid={isRegistrationFeesPaid}
//             />
//           ))}
//       </div>

//       <Footer />
//     </div>
//   );
// };

// export default CoursePage;




















import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import ChapterCard from './ChapterCard';
import '../styles/CoursePage.css';

import { getUserCourses, getUserChapters } from '../redux/actions/courseActions';
import { getUserLecturesbyYear } from '../redux/actions/lectureActions';

const CoursePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const courseData = location?.state;

  const { isAuthenticated } = useSelector((store) => store.userReducer);
  const { userData } = useSelector((store) => store.userReducer);
  const isRegistrationFeesPaid = userData?.isRegistrationFeesPaid;

  const { userCourses, userChapters } = useSelector((store) => store.courseReducer);
  const { userLecturesByYEAR } = useSelector((store) => store.lectureReducer);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserLecturesbyYear());
      dispatch(getUserCourses());
      dispatch(getUserChapters());
    }
  }, [dispatch, isAuthenticated]);

  // Check if the user purchased the full course
  const isCoursePurchased = userCourses?.some((course) => {
    const courseInstances = course?.UserCourses?.length;
    const validTill = course?.UserCourses[courseInstances - 1]?.validTill;
    const isValid = new Date(validTill) >= new Date();
    return course?.id === courseData?.id && isValid;
  });

  // Check if the user purchased live lectures
  const isLiveLecturePurchased = userLecturesByYEAR?.some((lectures) => {
    const lectureInstances = lectures?.userPurchasedLectures?.length;
    const validTill = lectures?.userPurchasedLectures[lectureInstances - 1]?.validTill;
    const isValid = new Date(validTill) >= new Date();
    return lectures?.id === courseData?.id && isValid;
  });

  // Combined check for full access
  const hasFullAccess = isCoursePurchased || isLiveLecturePurchased;

  // Check which chapters are unlocked for this course
  const unlockedChapters = userChapters?.filter((chapter) => chapter.courseId === courseData?.id) || [];

  return (
    <div className="CoursePage">
      <Navbar activeMenuItem={'course'} />
      <div className="CoursePage-title-container">
        <h1>Welcome to class {courseData?.class}th</h1>
        <h2>{courseData?.subject}</h2>
        <p>{courseData?.desc}</p>
      </div>

      <div className="chapter-card-container">
        {/* If not authenticated, show all chapters unlocked */}
        {!isAuthenticated &&
          courseData?.chapters?.map((chapter) => (
            <ChapterCard key={chapter.id} isLocked={false} chapter={chapter} course={courseData} isAuthenticated={isAuthenticated} isRegistrationFeesPaid={isRegistrationFeesPaid} />
          ))
        }

        {/* If full course or live lectures purchased, unlock all chapters */}
        {isAuthenticated && hasFullAccess &&
          courseData?.chapters?.map((chapter) => (
            <ChapterCard key={chapter.id} isLocked={false} chapter={chapter} course={courseData} isAuthenticated={isAuthenticated} isRegistrationFeesPaid={isRegistrationFeesPaid} />
          ))
        }

        {/* If authenticated but neither full course nor live lectures purchased, show first 2 chapters unlocked */}
        {isAuthenticated && !hasFullAccess &&
          courseData?.chapters?.map((chapter, index) => (
            <ChapterCard
              key={chapter.id}
              isLocked={index >= 2 && !unlockedChapters.some((unlocked) => unlocked.id === chapter.id)}
              chapter={chapter}
              course={courseData}
              isAuthenticated={isAuthenticated}
              isRegistrationFeesPaid={isRegistrationFeesPaid}
            />
          ))
        }
      </div>

      <Footer />
    </div>
  );
};

export default CoursePage;

