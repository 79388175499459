import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

// PrivateRoute component to wrap protected routes
const PrivateRoute = ({ element }) => {
  const isAuthenticated = Cookies.get("userJWT") || Cookies.get("teacherJWT") || Cookies.get("parentJWT");

  return isAuthenticated ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;
