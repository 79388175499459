
 
 export const ORIGIN = "https://api.enroutingcareers.com";    //PRODUCTION
  export const LEAVE="https://enroutingcareers.com"   //PRODUCTION
// export const ORIGIN = "http://localhost:8080";    //DEVELOPMENT
// export const LEAVE="http://localhost:3000"   //DEVELOPMENT




const BASE_URL = `${ORIGIN}`;
const ZOOM_SDK_KEY='UwiPZGu7QGCDrs7Im38vTw'
const ZOOM_LEAVE_URL=LEAVE

const config = {
  BASE_URL,
  ZOOM_SDK_KEY,
  ZOOM_LEAVE_URL
};

export default config;
