import React, { useState } from 'react';
import BlogModal from './BlogModal';
import nextIcon from '../images/ic_arrow_right.svg';
import { blogSize } from '../util/screenSize';
import '../styles/BlogCard.css';

import '../styles/style.css'; // Assuming you have this file for custom styles


const BlogCard = (props) => {
  const { blog } = props;
  const [blogModal, setBlogModal] = useState(false);
  var screen = window.innerWidth;
  blogModal
    ? (document.querySelector('body').style.overflow = 'hidden')
    : (document.querySelector('body').style.overflow = 'auto');

  // if (screen > screenSize) {
  //   return (
  //     <div className='BlogCard'>
  //       {blogModal && <BlogModal setBlogModal={setBlogModal} blog={blog} />}
  //       <div className='BlogCard-title-row'>
  //         <span className='BlogCard-title-text'>{blog.topic}</span>
  //       </div>
  //       <div className='BlogCard-content-row'>
  //         <div className='BlogCard-img-container'>
  //           <img src={blog.img} className='main' alt='blog-img' />
  //         </div>
  //         <div className='BlogCard-content-div'>
  //           <p className='blog-text'>
  //             {blog.desc
  //               .replace(/(<([^>]+)>)/gi, '')
  //               .replace('&nbsp;', ' ')
  //               .substring(0, 120)}
  //             ...
  //           </p>
  //           <div className='BlogCard-date-row'>
  //             <p className='blog-text'>
  //               Written by {blog.author} posted on {blog.date} will take a
  //               maximum {blog.readingTime} minutes to read
  //             </p>
  //             <div
  //               onClick={() => setBlogModal(true)}
  //               className='BlogCard-detail-div'
  //             >
  //               <span className='BlogCard-details-text'>Read more</span>
  //               <img
  //                 src={nextIcon}
  //                 alt='forward-icon'
  //                 className='BlogCard-forward-icon'
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div className='BlogCard'>
  //       {blogModal && <BlogModal setBlogModal={setBlogModal} blog={blog} />}
  //       <div className='BlogCard-content-row'>
  //         <div className='BlogCard-img-container'>
  //           <img src={blog.img} className='main' alt='blog-img' />
  //         </div>
  //         <div className='BlogCard-content-div'>
  //           <div className='BlogCard-title-row'>
  //             <span className='BlogCard-title-text'>{blog.topic}</span>
  //           </div>
  //           <p className='blog-text'>
  //             {blog.desc
  //               .replace(/(<([^>]+)>)/gi, '')
  //               .replace('&nbsp;', ' ')
  //               .substring(0, 60)}
  //             ...
  //           </p>
  //           <div className='BlogCard-date-row'>
  //             <p className='blog-text'>
  //               Written by {blog.author} posted on {blog.date} will take a
  //               maximum {blog.readingTime} minutes to read
  //             </p>
  //           </div>
  //           <div
  //             onClick={() => setBlogModal(true)}
  //             className='BlogCard-detail-div'
  //           >
  //             <span className='BlogCard-details-text'>Read more</span>
  //             <img
  //               src={nextIcon}
  //               alt='forward-icon'
  //               className='BlogCard-forward-icon'
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
const description=' Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit rem iure, aperiam explicabo. Lorem ipsum dolor sit amet consectetur adipisicing elit.aperiam explicabo. Lorem ipsum dolor sit amet consectetur adipisicing elit'


return(
 <>
   {/* Big Screen Container */}

   {blogModal && <BlogModal setBlogModal={setBlogModal} blog={blog} />}
   <div className="big-screen-container">
   
        <div className="container-top">
          <h4>{blog?.topic}</h4>

        </div>
        <div className="container-down">
          <div className="leftbox1">
            <img
              src={blog?.img}
              alt="Event"
            />
          </div>
          <div className="rightbox1">
            <p>
             {description.replace(/(<([^>]+)>)/gi, '')
                 .replace('&nbsp;', ' ')
               .substring(0, 120)}
            ...
            
             
            </p>
            <div className="detail">
              <div className="register">
                <p>
                Written by {blog?.author} posted on {blog?.date} will take a
                              maximum {blog?.readingTime} minutes to read
                </p>
              </div>
              <div className="more" onClick={() => setBlogModal(true)} >
            
                  <strong>Read More</strong>
                  <img
                   src={nextIcon}
                   alt='forward-icon'
                   className='forward-icon'
                 />
              
               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Small Screen Container */}
      <div className="small-screen-container">
        <div className="container-top">
          <img
            src={blog?.img}
            alt="Event"
          />
        </div>
        <div className="container-down">
          <h4>{blog?.topic}</h4>
          <p>
          {blog?.desc?.replace(/(<([^>]+)>)/gi, '')
                 .replace('&nbsp;', ' ')
               .substring(0, 120)}
            ...
            
          </p>
          <div className="detail">
            <div className="register">
              <p>
              Written by {blog?.author}  posted on {blog?.date} will take a
              maximum {blog?.readingTime} minutes to read
              </p>
            </div>

            <div className="more" onClick={() => setBlogModal(true)}>
                  <strong>Read More</strong>
                  <img
                   src={nextIcon}
                   alt='forward-icon'
                   className='forward-icon'
                 />
                
            </div>
          </div>
        </div>
      </div>
 
 </>
)




};

export default BlogCard;
