import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { userSubjectiveCol } from "../../../../data/mockData";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const UserSubjectiveTable = ({ data ,userData }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedResultData, setSelectedResultData] = useState(null);
  const [examData, setExamData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = data?.filter((item) =>
    item.select_subject.toLowerCase().startsWith(searchQuery.toLowerCase())
  );
  const paginatedData = filteredData?.slice(startIndex, endIndex);
  const totalLength = filteredData?.length;

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const examIds = data.map((item) => item.id);
        const examDataPromises = examIds.map((id) =>
          axios.get(`https://api.enroutingcareers.com/api/userAttendExam/${id}/attended-users`)
        );
        const examDataResponses = await Promise.all(examDataPromises);
        const examsData = examDataResponses.map((response) => response.data.data);
        setExamData(examsData.flat());
      } catch (error) {
        console.error("Failed to fetch exam data:", error);
      }
    };
    fetchExamData();
  }, [data]);

  const handleExamData = (item) => {
    setSelectedFormData(item);
    setIsEditOpen(true);
  };

  const handleCheckResult = (item) => {
    setSelectedResultData(item);
    setIsResultOpen(true);
  };

  // Use media query to determine if the screen is mobile
  const isMobile = useMediaQuery("(max-width:900px)");

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        {!isMobile ? ( // Render Table for Desktop
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {userSubjectiveCol?.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      textAlign: "center",
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      backgroundColor: "black",
                      color: "white", // Header background color
                      fontWeight: "bold", // Header text bold
                    }}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.map((item) => {
                const userExamData = examData?.find(
                  (exam) => exam?.examId === item?.id && exam?.userId === userData?.id
                );
                return (
                  <TableRow key={item.id} hover sx={{ "&:hover": { backgroundColor: "#e3f2fd" } }}>
                    <TableCell sx={cellStyle}>{item.examName}</TableCell>
                    <TableCell sx={cellStyle}>{item.select_subject}</TableCell>
                    <TableCell sx={cellStyle}>{item.select_chapter}</TableCell>
                    <TableCell sx={cellStyle}>{item.total_marks}</TableCell>
                    <TableCell sx={cellStyle}>{item.select_date}</TableCell>
                    <TableCell sx={cellStyle}>{item.start_time}</TableCell>
                    <TableCell sx={cellStyle}>{item.end_time}</TableCell>
                    <TableCell sx={cellStyle}>
                      <a
                        href={item?.questionPaperPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#1976d2" }} // Link color
                      >
                        View Questions
                      </a>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <Button
                        size="small"
                        variant="contained"
                        className="common-color"
                        disabled={userExamData && !userExamData?.attendedBy}
                        sx={{
                          opacity: userExamData?.attendedBy ? 0.5 : 1,
                        }}
                        onClick={() => !userExamData?.attendedBy && handleExamData(item)}
                      >
                        {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                      </Button>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <Button
                        size="small"
                        variant="contained"
                        className="common-color"
                        disabled={userExamData && userExamData?.obtainedMarks}
                        sx={{
                          opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                        }}
                        onClick={() =>
                          userExamData?.obtainedMarks && handleCheckResult(userExamData)
                        }
                      >
                        {userExamData?.attendedBy ? "Check Result" : "Not Check"}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : ( // Render Mobile Layout
          <Box sx={{ p: 2 }}>
            {paginatedData?.map((item) => {
              const userExamData = examData?.find(
                (exam) => exam?.examId === item?.id && exam?.userId === userData?.id
              );
              return (
                <Box
                  key={item.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  }}
                >
                  <Typography variant="h6">{item.examName}</Typography>
                  <Typography variant="body2">Subject: {item.select_subject}</Typography>
                  <Typography variant="body2">Chapter: {item.select_chapter}</Typography>
                  <Typography variant="body2">Total Marks: {item.total_marks}</Typography>
                  <Typography variant="body2">Date: {item.select_date}</Typography>
                  <Typography variant="body2">Start Time: {item.start_time}</Typography>
                  <Typography variant="body2">End Time: {item.end_time}</Typography>
                  <a
                    href={item?.questionPaperPath}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#1976d2" }} // Link color
                  >
                    View Questions
                  </a>
                  <Box sx={{ mt: 1 }}>
                    <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && !userExamData?.attendedBy}
                      sx={{
                        opacity: userExamData?.attendedBy ? 0.5 : 1,
                        mr: 1,
                      }}
                      onClick={() => !userExamData?.attendedBy && handleExamData(item)}
                    >
                      {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && userExamData?.obtainedMarks}
                      sx={{
                        opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                      }}
                      onClick={() =>
                        userExamData?.obtainedMarks && handleCheckResult(userExamData)
                      }
                    >
                      {userExamData?.attendedBy ? "Check Result" : "Not Check"}
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </TableContainer>

      <Box
        sx={{
          width: "100%",
          padding: "0px !important",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={totalLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <AddExamModal
        open={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        formData={selectedFormData}
      />
      
      <AddExamModal
        open={isResultOpen}
        handleClose={() => setIsResultOpen(false)}
        formData={selectedResultData}
      />
    </>
  );
};

const cellStyle = {
  textAlign: "center",
  borderColor: "black",
  borderWidth: 1,
  borderStyle: "solid",
};

export default UserSubjectiveTable;
