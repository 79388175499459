export const userMCQExamsCol = [
  { name: "Exam Type" },
  { name: "Exam Name" },
  { name: "Subject" },
  { name: "Chapter" },
  { name: "Marks" },
  { name: "Date" },
  { name: "Start time" },
  { name: "End time" },
  { name: "Status" },
  { name: "Result" },
];
export const addMCQExamsCol = [
  { name: "Exam Type" },
  { name: "Exam Name" },
  { name: "Class" },
  { name: "Subject" },
  { name: "Chapter" },
  { name: "Marks" },
  { name: "Date" },
  { name: "Start time" },
  { name: "End time" },
  { name: "Add Q." },
  { name: "Stu. attempt" },
];

export const addSubjectiveExamsCol = [
  { name: "Exam Name" },
  { name: "Class" },
  { name: "Subject" },
  { name: "Chapter" },
  { name: "Total Marks" },
  { name: "Date" },
  { name: "Start time" },
  { name: "End time" },
  { name: "Question Paper" },
  { name: "Answer Key" },
];

export const addMegaTestExamsCol = [
  { name: "Exam Name" },
  { name: "Class" },
  { name: "Subject" },
  { name: "Chapter" },
  { name: "Total Marks" },
  { name: "Date" },
  { name: "Start time" },
  { name: "End time" },
  { name: "Question Paper" },
  { name: "Answer Key" },
];

export const userSubjectiveCol = [
  { name: "Exam Name" },
  { name: "Subject" },
  { name: "Chapter" },
  { name: "Total Marks" },
  { name: "Date" },
  { name: "Start time" },
  { name: "End time" },
  { name: "Question Paper" },
  { name: "Action" },
  { name: "Result" },
];

export const subExamAttendByUserCol = [
  { name: "Full Name" },
  { name: "Class" },
  { name: "Answer" },
  { name: "Marks Obtained" },
];

export const MegaExamAttendByUserCol = [
  { name: "Full Name" },
  { name: "Class" },
  { name: "Answer" },
];

export const MCQExamAttendByUserCol = [
  { name: "Full Name" },
  { name: "Class" },
  { name: "Obtained Marks" },
  { name: "Percentage" },
  { name: "Rupees Earned" },
  { name: "Loyalty Points Earned" },
];
