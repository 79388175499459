import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import config from "../../../../config";
import { userMCQExamsCol } from "../../../../data/mockData";
import "bootstrap/dist/css/bootstrap.min.css";

const MCQTable = ({ data }) => {
  const tokenString = Cookies.get("userJWT");
  let token;
  if (tokenString) {
    try {
      token = jwtDecode(tokenString);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedResultData, setSelectedResultData] = useState(null);
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)"); // Check if the device is mobile

  useEffect(() => {
    const fetchExamData = async () => {
      setLoading(true);
      try {
        const examIds = data.map((item) => item.id);
        const examDataPromises = examIds.map((id) =>
          axios.get(`${config.BASE_URL}/api/userAttendExam/${id}/attended-users`)
        );
        const examDataResponses = await Promise.all(examDataPromises);
        const examsData = examDataResponses.map((response) => response.data.data);
        setExamData(examsData.flat());
      } catch (error) {
        console.error("Failed to fetch exam data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExamData();
  }, [data]);

  const filteredData = data?.filter((item) =>
    item.select_subject.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = (item) => {
    navigate("/student-test/student_mcq-test-screen", {
      state: { item: item },
    });
  };

  const handleCheckResult = async (item) => {
    setSelectedResultData(item);
    setIsResultOpen(true);
  };

  const isButtonEnabled = (selectDate, startTime, endTime) => {
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toTimeString().split(" ")[0];

    return (
      currentDate === selectDate &&
      currentTime >= startTime &&
      currentTime <= endTime
    );
  };

  const totalLength = filteredData?.length;
  const paginatedData = filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      {isMobile ? ( // Mobile Layout
        <Box>
          {loading ? (
            <Typography align="center">Loading...</Typography>
          ) : (
            paginatedData?.map((item, index) => {
              const buttonEnabled = isButtonEnabled(item.select_date, item.start_time, item.end_time);
              const userExamData = examData?.find(
                (exam) => exam?.examId === item?.id && exam?.userId === token?.id
              );

              return (
                <Paper key={item.id} sx={{ padding: 2, marginBottom: 2, borderRadius: 2, boxShadow: 3 }}>
                  <Typography variant="h6">{item.examName}</Typography>
                  <Typography>Subject: {item.select_subject}</Typography>
                  <Typography>Chapter: {item.select_chapter}</Typography>
                  <Typography>Total Marks: {item.total_marks}</Typography>
                  <Typography>Date: {item.select_date}</Typography>
                  <Typography>Time: {item.start_time} - {item.end_time}</Typography>
                  <Box display="flex" justifyContent="space-between" marginTop={2}>
                    {buttonEnabled ? (
                      <Button
                        variant="contained"
                        onClick={() => handleNavigate(item)}
                      >
                        Start Test
                      </Button>
                    ) : (
                      <Typography color="grey">Not Available</Typography>
                    )}
                    <Button
                      variant="contained"
                      disabled={!userExamData?.obtainedMarks}
                      onClick={() => userExamData?.obtainedMarks && handleCheckResult(userExamData)}
                      sx={{
                        opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                        backgroundColor: !userExamData?.obtainedMarks ? '#b0bec5' : '#1976d2',
                      }}
                    >
                      {userExamData?.obtainedMarks ? "Check Result" : "Not Check"}
                    </Button>
                  </Box>
                </Paper>
              );
            })
          )}
        </Box>
      ) : ( // Desktop Layout
        <>
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {userMCQExamsCol?.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        borderColor: "black",
                        borderWidth: 1,
                        borderStyle: "solid",
                        fontWeight: 'bold',
                        backgroundColor: 'black',
                        color: 'white',
                      }}
                    >
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={userMCQExamsCol.length} align="center">
                      <Typography>Loading...</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData?.map((item, index) => {
                    const buttonEnabled = isButtonEnabled(item.select_date, item.start_time, item.end_time);
                    const userExamData = examData?.find(
                      (exam) => exam?.examId === item?.id && exam?.userId === token?.id
                    );

                    return (
                      <TableRow key={item.id} hover sx={{ '&:hover': { backgroundColor: '#e3f2fd' }, backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                        <TableCell sx={cellStyle}>{item.testType}</TableCell>
                        <TableCell sx={cellStyle}>{item.examName}</TableCell>
                        <TableCell sx={cellStyle}>{item.select_subject}</TableCell>
                        <TableCell sx={cellStyle}>{item.select_chapter}</TableCell>
                        <TableCell sx={cellStyle}>{item.total_marks}</TableCell>
                        <TableCell sx={cellStyle}>{item.select_date}</TableCell>
                        <TableCell sx={cellStyle}>{item.start_time}</TableCell>
                        <TableCell sx={cellStyle}>{item.end_time}</TableCell>
                        <TableCell sx={cellStyle}>
                          <Box display="flex" justifyContent="center">
                            {buttonEnabled ? (
                              <Button
                                size="small"
                                variant="contained"
                                className="common-color"
                                onClick={() => handleNavigate(item)}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#4caf50',
                                  },
                                }}
                              >
                                Start Test
                              </Button>
                            ) : (
                              <Typography color="grey">Not Available</Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell sx={cellStyle} onClick={() => userExamData?.obtainedMarks && handleCheckResult(userExamData)}>
                          <Button
                            size="small"
                            variant="contained"
                            className="common-color"
                            disabled={!userExamData?.obtainedMarks}
                            sx={{
                              opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                              backgroundColor: !userExamData?.obtainedMarks ? '#b0bec5' : '#1976d2',
                              '&:hover': {
                                backgroundColor: userExamData?.obtainedMarks ? '#115293' : '#b0bec5',
                              },
                            }}
                          >
                            {userExamData?.obtainedMarks ? "Check Result" : "Not Check"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      <AddExamModal
        open={isResultOpen}
        onClose={() => setIsResultOpen(false)}
        data={selectedResultData}
      />
    </Box>
  );
};

export default MCQTable;

const cellStyle = {
  textAlign: "center",
  borderColor: "black",
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 1,
};
