import React from "react";
import { Route, Routes } from "react-router-dom";
import MegaTest from "../../../pages/Teachers/AddQuestions/Mega_Test_Type/MegaTest";
import MCQ from "../../../pages/Teachers/AddQuestions/MCQ_TYPE/MCQ";
import Subjective from "../../../pages/Teachers/AddQuestions/Subjective_Type/Subjective";
import SubExamAttendByStu from "../../../pages/Teachers/AddQuestions/Subjective_Type/SubExamAttendByUser/SubExamAttendByStu";
import MegaExamAttendByStu from "../../../pages/Teachers/AddQuestions/Mega_Test_Type/MegaExamAttendByUser/MegaExamAttendByStu";
import MCQStudAttempted from "../../../pages/Teachers/AddQuestions/MCQ_TYPE/MCQExamAttendByUser/MCQStudAttempted";

const TestRoutes = () => {
  return (
    <Routes>
      <Route path="/mega-test" element={<MegaTest />} />

      <Route path="/mcq-test" element={<MCQ />} />
      <Route
        path="/mcq-test-attempt-by-students"
        element={<MCQStudAttempted />}
      />

      <Route path="/subjective-test" element={<Subjective />} />

      <Route
        path="/sub-test-attempt-by-students"
        element={<SubExamAttendByStu />}
      />

      <Route
        path="/mega-test-attempt-by-students"
        element={<MegaExamAttendByStu />}
      />
    </Routes>
  );
};

export default TestRoutes;
