import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import { getSubMegaExams } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";
import Navbar from "../../../../components/Navbar";
import UserSubjectiveTable from "./UserSubjectiveTable";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const UserSubjective = () => {
  // Fetch user by token


  const { userData }=useSelector((store)=>store.userReducer)
  
  // Use React Query to fetch topic data
  const {
    data: subjectiveExamData,
    error: subjectiveExamError,
    isLoading: subjectiveExamIsLoading,
    isError: subjectiveExamIsError,
  } = useQuery({
    queryKey: QueryKeys.subjective_mega_exam,
    queryFn: getSubMegaExams,
  });

  if (subjectiveExamIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (subjectiveExamIsError) {
    return <p>Error: {subjectiveExamError.message}</p>;
  }

  const displayData = subjectiveExamData?.filter(
    (item) => item.testType.toLowerCase() == "subject_wise"
  );

  const classData = displayData?.filter(
    (item) => item?.select_class == userData?.standard
  );

 
  console.log("displayData", displayData);
  console.log("classData", classData);
  return (
    <>
      <Navbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="Subjective"
          title="Subjective Exam Details"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <UserSubjectiveTable data={classData} userData={userData}/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserSubjective;
