import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import aboutUsImage from '../images/img_aboutus.svg';
import '../styles/AboutUsPage.css';

const AboutUsPage = () => {
  return (
    <div className='AboutUsPage'>
      <Navbar activeMenuItem={'about'} />
      <div className='AboutUsPage-container'>
        <div className='AboutUsPage-container-left'>
          <img src={aboutUsImage} alt='About Us' className='aboutUs-img' />
        </div>
        <div className='AboutUsPage-container-right'>
          <div className='AboutUsPage-text-box'>
            <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>About Us</h1>
            <p>
              As a firm, our main objective is to build a sense of responsibility toward future goals. By the time students finish high school, they should be well aware of the path they need to follow rather than making confused decisions. Parents need to understand that more than marks, talents, and abilities of a candidate are tested in every field they try to enter. We aim to build practical knowledge and abilities in students that they can leverage after acquiring basic theoretical concepts taught in schools.
            </p>
            <p>
              The most important fact is that there is no conventional way of learning or teaching a topic. Some learn visually, others from study materials, and some seek help from notes that many students don't use effectively. Our goal is to provide detailed notes and adapt students to the learning methods they will require in their graduation levels. Learning through notes has its benefits, as it helps in quick revision and understanding.
            </p>
            <p>
              We conduct technical and non-technical events like exhibitions, workshops, and mental health classes to help students explore their interests and relax. These activities provide platforms for talents to be showcased and help students understand the value of education beyond academics.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
