import * as api from './api';
import { GET_ALL_MEETINGS} from '../actionsType';

// export const scheduleMeeting = (date, time) => async (dispatch) => {
//   try {
//     await api.scheduleMeeting(date, time);
//     dispatch({ 
//       type: SCHEDULE_MEETING,
//       payload: { date, time }
//     });
//   } catch (err) {
//     const message = err?.response?.data?.message
//       ? err.response.data.message
//       : 'Something went wrong';
//     console.log(message);
//   }
// };



export const getMeetings = () => async (dispatch) => {
  try {
    const res  = await api.getAllMeetings();
  // console.log("meeting data:",res)
    dispatch({
      type: GET_ALL_MEETINGS,
      payload: res,
    });

   // console.log(history)
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong while fetching meeting';
    console.log(message);
  }
};

