import { client } from "../../api_client";
import config from "../../../config";
const URL = config.BASE_URL;

const endPoints = {
  addExam: "upload",
};

const API_URLS = {
  UPLOAD_SUB_MEGA_ANS: `${URL}/UserAttendExam/${endPoints.addExam}`,
};

export const uploadUserSubMegaAns = async (postData) => {
  try {
    const url = `${API_URLS.UPLOAD_SUB_MEGA_ANS}`;

    const formData = new FormData();
    formData.append("testType", postData.get("testType"));
    formData.append("userId", postData.get("userId"));
    formData.append("examId", postData.get("examId"));
    formData.append("uploadPaper", postData.get("uploadPaper"));

    const response = await client.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("An error occurred while creating the post.");
  }
};
