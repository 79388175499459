import React, { useState, useEffect } from "react";
import TeacherNavbar from "./Teacher/TeacherNavbar";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Stack,
  TextField,
  Grid,
  MenuItem,
  Popover,
} from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import AddUserModal from "./addUserModal/addUserModal";
import {
  createBulkUser,
  getAllPendingUsers,
  updateUserStatus
} from "../redux/actions/bulkUserActions";
import { getAllUsersByTeacher } from "../redux/actions/bulkUserActions";
import { toast } from "react-toastify";
import {
  Approval,
  Clear,
  Done,
  ThumbDown,
  ThumbDownAlt,
} from "@mui/icons-material";
import { ConfirmUserApprove } from "./ConfirmDialog";

export default function BulkUserPage() {
  const dispatch = useDispatch();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { legitUserData, pendingData } = useSelector(
    (store) => store.bulkUserReducer
  );
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For Popover
  const [selectedFile, setSelectedFile] = useState(null); // Track selected file
  const [uploading, setUploading] = useState(false); // Track loading state
  const [status, setStatus] = useState("successful");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [approveUser, setApproveUser] = useState(null);



  const handleApproveUser = (id, isApproved) => {
    setCurrentId(id);
    setApproveUser(isApproved);
    setOpenDialog(true);
  };
  
  const handleConfirm = () => {
    if (approveUser) {
      dispatch(updateUserStatus(currentId, { status: true }))
      .then(() => {
        dispatch(getAllUsersByTeacher());
        dispatch(getAllPendingUsers());
      })
      .catch(error => {
        console.error('Error updating user status:', error);
        toast.error("Failed to update user status"); // Show a user-friendly error message
      });
      
    } else {

      dispatch(updateUserStatus(currentId, { status: false }))
      .then(() => {
        dispatch(getAllUsersByTeacher());
        dispatch(getAllPendingUsers());
      })
      .catch(error => {
        console.error('Error updating user status:', error);
        toast.error("Failed to update user status"); // Show a user-friendly error message
      });
      
      
    }



   




    setOpenDialog(false);
  };


  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  // Open the modal for adding a user
  const handleAddClick = () => {
    setSelectedFormData(null);
    setIsAddOpen(true);
    setIsEdit(false);
  };
  
  // Open the modal for editing a user
  const handleEditClick = (formData) => {
    setSelectedFormData(formData);
    setIsAddOpen(true);
    setIsEdit(true);
  };

  useEffect(() => {
    if (!legitUserData) {
      dispatch(getAllUsersByTeacher());
      dispatch(getAllPendingUsers());
    }
  }, [dispatch]);

  // Open popover for file upload
  const handleUploadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Set the selected file
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (
      selectedFile &&
      (selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setUploading(true); // Start loading indicator

      const formData = new FormData();
      formData.append("file", selectedFile); // 'file' is the key, which will be received in backend

      try {
        // Dispatch the upload action
         dispatch(createBulkUser(formData)).then(()=>{
          setUploading(false); // Stop loading indicator after upload
          handleClosePopover(); // Close popover on success
          dispatch(getAllUsersByTeacher());
          dispatch(getAllPendingUsers());
          toast.success("File uploaded successfully!"); // Success message
         })
        
      } catch (error) {
        setUploading(false); // Stop loading on error

        // Check for specific error message
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during the upload";
        toast.error(errorMessage); // Display the error message to the user
      }
    } else {
      toast.error("Please select a valid Excel file (.xls or .xlsx)"); // Show error for invalid file format
    }
  };

  // Close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedFile(null); // Clear selected file on close
  };

  const open = Boolean(anchorEl);

  console.log("legitUserData", legitUserData);
  console.log("pendingData", pendingData);
  const dataToDisplay =
    status === "successful" ? legitUserData?.data : pendingData?.data;

  return (
    <>
      <TeacherNavbar />
      <Box mt={12}>
        <div className="d-flex justify-content-between">
          <Grid item sx={{ paddingLeft: 2, minWidth: 120 }}>
            <TextField
              select
              size="small"
              required
              fullWidth
              label="Status"
              name="status"
              value={status}
              onChange={handleChangeStatus}
            >
              <MenuItem value="successful">Successful</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </TextField>
          </Grid>


            {/* <Button
               size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              Create
            </Button> */}
           
         
        </div>

        <Box mt={7} mx={2}>
          {uploading && (
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <CircularProgress />
              <p>Uploading... Please wait.</p>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{backgroundColor:"#383838"}}>
                <TableCell sx={{ color: "#FAFAFA" }}>Student Id</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Full Name</TableCell>
                  {status === "successful" && <TableCell sx={{ color: "#FAFAFA" }}>Password</TableCell>}
                  <TableCell sx={{ color: "#FAFAFA" }}>Email</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Standard</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Section</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>School/College</TableCell>
                  {status === "successful" && <TableCell sx={{ color: "#FAFAFA" }}>Status</TableCell>}
                  {status !== "successful" && <TableCell sx={{ color: "#FAFAFA" }}>Reason</TableCell>}
                  {status !== "successful" && <TableCell sx={{ color: "#FAFAFA" }}>Action</TableCell>}
                  {status === "successful" && <TableCell sx={{ color: "#FAFAFA" }}>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToDisplay?.map((item) => (
                  <TableRow key={item.id}>
                     <TableCell>
                      {item.custom_id}
                    </TableCell>
                    <TableCell>
                      {item.firstname + " " + item.lastname}
                    </TableCell>
                    {status === "successful" && <TableCell>{item?.temp_pass}</TableCell>}
                    <TableCell>{item.email}</TableCell>
                  
                    <TableCell>{item.standard}</TableCell>
                    <TableCell>{item.section}</TableCell>
                 
                    <TableCell>
                      {item.school ? item.school : item.college}
                    </TableCell>

                    {status === "successful" && (
                       <TableCell>{item?.status === true ? (
                        <span class="badge rounded-pill text-bg-success">
                          Approved
                        </span>
                      ) : (
                        <span class="badge rounded-pill text-bg-warning">
                          Not Approved
                        </span>
                      )}</TableCell>    
                    )}



                    {status !== "successful" && (
                      <TableCell>{item?.validation_errors}</TableCell>
                    )}
                    {status !== "successful" && (
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleEditClick(item)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    )}

                     {status === "successful" && (
                        <TableCell>
                        {item?.status === false && (
                          <Button
                            variant="outlined" color="success"
                            size="small"
                           onClick={() => handleApproveUser(item.id, true)}
                            style={{ cursor: "pointer"}}
                            startIcon={<Done />}
                          >
                            Approve
                          </Button>
                        )}
                        {item?.status === true && (
                          <Button
                          
                            size="small"
                            variant="outlined" color="error"
                            onClick={() => handleApproveUser(item.id, false)}
                            style={{ cursor: "pointer" }}
                            startIcon={<Clear />}
                          >
                            disapprove
                          </Button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>


      <ConfirmUserApprove
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
        title={approveUser ? "Approve User" : "Disapprove User"}
      />

      {isAddOpen && (
        <AddUserModal
          formData={selectedFormData}
          open={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          isEdit={isEdit}
        />
      )}

      {/* Popover for file upload */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box p={2}>
          <input
            type="file"
            accept=".xls, .xlsx"
            onChange={handleFileChange}
            style={{ display: "block", marginBottom: "10px" }}
          />
          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
          <Button
            variant="contained"
            onClick={handleFileUpload}
            disabled={uploading}
          >
            {uploading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Popover>
    </>
  );
}
