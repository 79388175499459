import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Grid,
  MenuItem,
  DialogTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createSingleUserByTeacher, getAllUsersByTeacher } from "../../redux/actions/bulkUserActions";
import { toast } from "react-toastify";

const AddUserModal = ({ open, onClose, formData, isEdit }) => {
  console.log('formData', formData)
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    standard: "",
    section: "",
    institutionType: "School", // default to School
    school: "",
    college: "",
  });

  // Populate form fields if editing
  useEffect(() => {
    if (isEdit && formData) {
      setFormState(formData);
    } else {
      setFormState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        phone: "",
        standard: "",
        section: "",
        institutionType: "School", // default to School
        school: "",
        college: "",
      });
    }
  }, [formData, isEdit]);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    console.log('setFormState', formState)
  };

  const handleSubmit = async () => {
    try {
      if (isEdit) {
        await dispatch(createSingleUserByTeacher(formState));
        dispatch(getAllUsersByTeacher()); // Wait for the dispatch to complete
      } else {
        console.log('formState', formState);
        await dispatch(createSingleUserByTeacher(formState));
        dispatch(getAllUsersByTeacher()); // Wait for the dispatch to complete
      }
      onClose(); // Close the modal only if successful
    } catch (error) {
      // Catch the error from the action creator and display the message
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message); // Display specific error message
      } else {
        toast.error(error.message || "An error occurred while submitting the form.");
      }
    }
  };

  const sectionOptions = ["a", "b", "c", "d", "e", "f", "g", "h"];
  const standardOptions = ["4", "5", "6", "7", "8", "9", "10", "11", "12"];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEdit ? "Edit User" : "Add User"}</DialogTitle>
      <DialogContent>
        <Box sx={{ padding: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="First Name"
                name="firstname"
                value={formState.firstname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Last Name"
                name="lastname"
                value={formState.lastname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                required
                fullWidth
                label="Email"
                name="email"
                value={formState.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                required
                fullWidth
                label="Password"
                name="password"
                value={formState.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                required
                fullWidth
                label="Phone"
                name="phone"
                value={formState.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                size="small"
                fullWidth
                label="Standard"
                name="standard"
                value={formState.standard}
                onChange={handleChange}
              >
                {standardOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                size="small"
                fullWidth
                label="Section"
                name="section"
                value={formState.section}
                onChange={handleChange}
              >
                {sectionOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                size="small"
                required
                fullWidth
                label="Institution Type"
                name="institutionType"
                value={formState.institutionType}
                onChange={handleChange}
              >
                <MenuItem value="School">School</MenuItem>
                <MenuItem value="College">College</MenuItem>
              </TextField>
            </Grid>

            {/* Conditionally render the school or college field based on institutionType */}
            {formState.institutionType === "School" ? (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  label="School"
                  name="school"
                  value={formState.school}
                  onChange={handleChange}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  label="College"
                  name="college"
                  value={formState.college}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          {isEdit ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
