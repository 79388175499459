import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { getMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import MCQTable from "./MCQTable";
import Navbar from "../../../../components/Navbar";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
const MCQ = () => {
  // Fetch user 

  

const {userData}=useSelector((store)=>store.userReducer)
  // Use React Query to fetch topic data
  const {
    data: mcqExamData,
    error: mcqExamError,
    isLoading: mcqExamIsLoading,
    isError: mcqExamIsError,
  } = useQuery({
    queryKey: QueryKeys.mcq_exams,
    queryFn: getMCQExams,
  });

  if (mcqExamIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (mcqExamIsError) {
    return <p>Error: {mcqExamError.message}</p>;
  }

  const displayData = mcqExamData?.filter(
    (item) => item?.testType.toLowerCase() === "mcq"
  );

  const classData = displayData?.filter(
    (item) => item?.select_class == userData?.standard
  );

  // console
  console.log("classDataMCQ", classData);
  console.log("displayData", displayData);
  return (
    <>
      <Navbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Student"
          subPageName="MCQ"
          title="MCQ Exam Details"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <MCQTable data={classData} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MCQ;
