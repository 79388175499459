import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import  { useState, useEffect } from 'react';

import aboutUsImage from '../images/img_aboutus.svg';
import { screenSize } from '../util/screenSize';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicyPage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderTitle = () => (
    <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>
      Privacy Policy
    </h1>
  );
  return (
    
    <div className='AboutUsPage'>
      
      <Navbar />
      <div className='AboutUsPage-container'>
        <div className='AboutUsPage-container-left'>
        {screenWidth <= screenSize && renderTitle()}
          <img src={aboutUsImage} alt='aboutUs-img' className='aboutUs-img' />
        </div>
        <div className='AboutUsPage-container-right'>
          <div className='AboutUsPage-text-box'>
          {screenWidth > screenSize && renderTitle()}
            <p className={'term-cond-p'}>
              These terms and conditions of Use (hereinafter referred as
              End-User License Agreement known as “EULA”) describe and govern
              the User’s use of the content and services offered by ENROUTING
              CAREERS PVT. LTD through www.enroutingcareers.com.
            </p>
            <p className={'term-cond-p'}>
              This End-User License Agreement (\EULA\) is a legal agreement
              between you and Enroutingcareers private ltd. This EULA agreement
              governs your acquisition and use of our ENROUTING CAREERS website
              directly from Enroutingcareers private ltd or indirectly through a
              Enrouting Careers private ltd authorized reseller or distributor
              (a \Reseller\). Please read this EULA agreement carefully before
              completing the registration process and using the website
              www.enroutingcareers.com. It provides a license to use the
              contents of www.enroutingcareers.com for their personal use and
              contains warranty information and liability disclaimers. If you
              are entering into this EULA agreement on behalf of a company or
              other legal entity, you represent that you have the authority to
              bind such entity and its affiliates to these terms and conditions.
              If you do not have such authority or if you do not agree with the
              terms and conditions of this EULA agreement, do not register or
              use the contents on this website, and you must not accept this
              EULA agreement. This EULA agreement shall apply only to the
              Contents supplied by Enrouting careers private ltd herewith
              regardless of whether other software is referred to or described
              herein. The terms also apply to any Enrouting Careers Private ltd
              updates, supplements, Internet-based services, and support
              services for the Website, unless other terms accompany those items
              on delivery. If so, those terms apply. This EULA was created by
              EULA Template for Enrouting Careers Private Limited.
            </p>
            <h3>GENERAL DESCRIPTION</h3>
            <p className={'term-cond-p'}>
              ENROUTING CAREERS is offering digital contents and services over
              the internet which is accessible across the globe, based on the
              regional laws and restrictions. These contents are provided for
              informational educational and quality use. Also, there are
              educational opinions provided by PhD scholars as digital reports
              via various mediums like (but not limited to) voice, video, pdf,
              jpeg and emails (hereinafter referred as “Content”). The website
              is offering “Paid Services” and “Free Services” (Collectively
              referred as “Services” hereinafter). For accessing all types of
              Services user need to register with their email address and verify
              the same by responding to the autogenerated mail sent by Enrouting
              Careers Pvt Ltd. By doing so, a Member agrees to: To provide
              complete and accurate information about herself/himself as asked
              by www.enroutingcareers at various stages. To update and maintain
              the above information as required and submitted by you with the
              view to maintain the accuracy of the information being complete
              and current.
            </p>
            <h3>LICENSE GRANT</h3>
            <p className={'term-cond-p'}>
              Enrouting careers private ltd hereby grants you a personal,
              non-transferable, nonexclusive licence to use the contents on the
              website www.enroutingcareers.com on your devices in accordance
              with the terms of this EULA agreement. You are permitted to load
              www.enroutingcareers.com website (for example a PC, laptop, mobile
              or tablet) under your control. You are responsible for ensuring
              your device meets the minimum requirements of the Enroutingcareers
              website. You are not permitted to: Edit, alter, modify, adapt,
              translate or otherwise change the whole or any part of the Website
              nor permit the whole or any part of the Software to be combined
              with or become incorporated in any other software, nor decompile,
              disassemble or reverse engineer the website contents or attempt to
              do any such things Reproduce, copy, distribute, resell or
              otherwise use the website contents for any commercial purpose
              Allow any third party to use the website contents on behalf of or
              for the benefit of any third party Use the website contents in any
              way which breaches any applicable local, national or international
              law Use the website contents in any way to upload offensive
              content, pornographic or political messages. use the Software for
              any purpose that Enrouting Careers private ltd considers is a
              breach of this EULA agreement.
            </p>
            <h3>ELIGIBILITY and REGISTRATION</h3>
            <p className={'term-cond-p'}>
              The User of the website must be a person who can form legally
              binding contracts under Indian Contract Act, 1872. A minor under
              the age of eighteen (18) in most jurisdictions, are not permitted
              to Use the services provided by the website alone. Parents can use
              the website contents to obtain services for their minor children.
              The website shall not be held responsible for any misuse that may
              occur by virtue of any person not meeting the eligibility criteria
              and still decide to use the website. User need to register with
              the website using email to avail any service on the website. A
              User registration on the website www.enroutingcareers.com can be
              commenced by entering email id and a verification mail sent to the
              email id mentioned by the user at the time of registration by the
              website backend. Users will require to provide personal
              identifiable info like (but not limited to) name, email and data
              of birth to avail some of the services,. You (User) agree to
              provide update, current and accurate information when asked by the
              website. All information that you fill and provide to the website
              and all updates therefore are referred to in these Terms of Usage
              as "User Data." Please do note that right to use this website via
              registration with email id is solely with you and not transferable
              to any other person or entity. The User would be fully responsible
              for all the activities that occur under the User’s account. The
              website cannot and will not be liable for any loss or damage
              arising from the User’s failure to maintain secrecy and
              confidentiality. The User shall notify the website administrators
              immediately if they become aware of any unauthorized use of their
              Account(s) or breach of any security. You(User) also agrees to use
              modest, decent and appropriate language with the partners while
              communicating and use of any derogatory, demeaning or offending
              language/remarks may attract disqualificationand may be subjected
              to legal action based on the gravity of the situation. The User
              also agrees, understands and confirms that his/ her personal data
              including without limitation to details relating to debit card/
              credit card transmitted over the Internet may be susceptible to
              misuse, hacking, theft and/ or fraud and that the website or the
              Payment Service Provider(s) have no control over such issues. User
              also agree to use the website in a mobile device or a PC which is
              in proper condition, not broken, secure and safe to operate. User
              also agree to not do act of hijack, jailbreak, hack, or tamper the
              website contents in anyways which constitute reverse engineering
              or intentionally try to crash the website etc., other than normal
              allowed usage. The website does not permit the use of the Services
              by any User under the following conditions: - If your
              local/regional jurisdiction/laws does not permit use of the
              services or service’s payment transactions rendered by the
              website. Due to any religious practices. The User shall not have
              more than one active account with the website at a time.
            </p>
            <h3>INTELLECTUAL PROPERTY AND OWNERSHIP</h3>
            <p className={'term-cond-p'}>
              Enrouting careers private ltd shall at all times retain ownership
              of the website as originally downloaded by you and all subsequent
              downloads of the website contents by you. The website contents
              (and the copyright, and other intellectual property rights of
              whatever nature in the website, including any modifications made
              thereto) are and shall remain the property of Enrouting careers
              private ltd. Enrouting careers private ltd reserves the right to
              grant licences to use the Software to third parties.
            </p>
            <h3>UPDATION</h3>
            <p className={'term-cond-p'}>
              Our website keep getting updated for software releases, new
              contents, and update on terms of usage and other pertaining policy
              changes. It is the responsibility of the user to check on time to
              time and verify the Terms and conditions laid by the platform are
              in sync with your expectations and remain in compliance with the
              terms of use and in compliance with law of the land, from where
              you are using this.
            </p>
            <h3>USER CONSENT</h3>
            <p className={'term-cond-p'}>
              By using our website, you (“Member”, “You”, “Your”) indicate that
              you understand the terms and conditions, and also express the
              consent to the Terms of Usage. If you do not agree with the Terms
              of Usage, please do not click on the “AGREE and PROCEED” button.
              It's recommended that The User should read the Terms of Usage
              carefully before using or registering on our platform or accessing
              any material, information or services through the Website. Your
              continued use of our website (no matter whether amendments are
              made to policies, website or term of use) shall signify your
              acceptance of the terms of usage and your agreement to be legally
              bound by the same
            </p>
            <h3>TERMINATION</h3>
            <p className={'term-cond-p'}>
              This EULA agreement is effective from the date you first use the
              Software and shall continue until terminated. You may terminate it
              at any time upon written notice to Enrouting careers private ltd.
              It will also terminate immediately if you fail to comply with any
              term of this EULA agreement. Upon such termination, the licenses
              granted by this EULA agreement will immediately terminate and you
              agree to stop all access and use of the website. The provisions
              that by their nature continue and survive will survive any
              termination of this EULA agreement. The website may, in whole or
              in part, without informing the User, modify, discontinue, change
              or alter the services ordered or the Account of the User
              registered with the Website. The Website may or may not issue
              notice or provide any reason for such action taken by the Website.
            </p>
            <h3>PRICING POLICY</h3>
            <p className={'term-cond-p'}>
              Our service is offered for sale by Enrouting careers Pvt Ltd for
              your personal educational and development needs. We at
              Enroutingcareers, have pricing customized according to the
              services provided. Most of the services are priced based on the
              duration, materials and skill set required for each of the
              services. Typically, all the prices are fixed ones and no discount
              shall be offered on these service pricing.
            </p>
            <h3>SCHEDULE OF PAYMENT</h3>
            <p className={'term-cond-p'}>
              Most of the services have fixed pricing and need to be paid
              upfront at the time of enrolment upon which the user will be able
              to immediately start using the services provided by the website
              and also will be able to see them on the “ Registered” Tab.{' '}
            </p>
            <h3>PRICING ERRORS</h3>
            <p className={'term-cond-p'}>
              It is with utmost care we devise these pricing policies and
              values, and we strive hard hard to ensure the accuracy of pricing.
              There could be human errors, technical glitches or software bugs
              and due to this pricing errors can still occur, despite our
              sincere efforts. If there exist any pricing errors for the
              enrolled services, the payment shall be cancelled from our end and
              you shall be intimated about it. We reserve the right to refuse to
              sell to any person whom we believe may be misusing the service.
            </p>
            <h3>USER OBLIGATION</h3>
            <p className={'term-cond-p'}>
              The User represents that she/he is an individual and not a
              corporation or other legal business entity. The website is for
              personal use of the User. User shall adhere to the the terms and
              conditions which are inclusive, but not limited to:- The User
              shall not share or transmit any messages that is incorrect,
              misrepresenting , harmful, defamatory, breaches another's privacy,
              violates or encourages any conduct that would violate any
              applicable law or regulation or would give rise to civil
              liability, infringes another's rights including any intellectual
              property rights or copyright or trademark, promotes racism, hatred
              or harm against any individual or group or religion or caste. The
              User shall not collect screen names and email addresses of members
              who are registered on the website for purposes of advertisement,
              solicitation or spam. The User shall not indulge in unsolicited
              communication with other users on the website, including the
              Partners. The User shall not upload or distribute files that
              contain viruses, malware, adware, ransomware etc., or corrupted
              files, or any other similar software or programs that may damage
              the operation of the website or another’s devices. The User shall
              not resell or make any commercial use of the Services without the
              written consent from the website. The User is under an obligation
              to report any misuse or abuse or defects of the website. If you
              notice any abuse or defect or misuse of the website or anything
              which is in violation of this Agreement, you shall forthwith
              report such violation to Enrouting Careers Pvt Ltd by writing to
              Customer Care ( cs.enroutingcareers@gmail.com).
            </p>
            <h3>USER’s DATA</h3>
            <p className={'term-cond-p'}>
              The website shall have access to the user account and related
              info. User hereby consents for the unconditional access of the
              account by the website, its employees, agents and other appointed
              persons in such regard. Your communication with our customer
              service and/or correspondence may be recorded for training purpose
              and quality assurance.
            </p>           
            <h3>PERSONAL IDENTIFIABLE INFORMATION</h3>
            <p className={'term-cond-p'}>
              The information qualifies as personal in nature when the
              information collected identifies a specific end user, such as (but
              not limited to) Full name, Address, Telephone Number,
              Email-address, Date of Birth, Time of Birth, Gender, Location,
              Photograph, any other items of ‘sensitive personal data or
              information” as such term is defined under the Information
              Technology (Reasonable Security Practices And Procedures And
              Sensitive Personal Data Of Information) Rules, 2011 enacted under
              the Information Technology Act, 2000, and any other detail
              required for operation such as, financial information inclusive of
              bank account information, credit card or debit card details or
              other payment instrument details through a secure third party
              gateway, IP (Internet protocol) Address and any other information
              that a User may provide during enrolling into a paid service on
              the website. The website may log/store other statistics about the
              usage such as IP address and internet connection session data such
              as, cookies, time and date of the visit, viewing of page, length
              of time, registration or password information etc. Some of the
              services provided by the website may direct the User to platform
              of third parties. Any Information provided by the User on such
              platforms may be dealt by them in the manner provided by the
              privacy policy formulated by such third-party platforms. The
              website in this regard fully disclaims any liability(ies) or
              claim(s) which may arise by use/misuse of such information shared
              by the User, to any third party or any party not known to
              Enrouting careers pvt ltd. The website would not liable for the
              mis-use of such information shared by the User or by any third
              party. We also collect details including but not limited to User
              feedback, comments, etc. that may be disclosed/informed/mentioned
              on any article/blog or groups/forums or other pages which the User
              may have access to while visiting the website. For such
              information which is in public domain and accessible to all the
              Users and visitors of the Website, the User is advised to exercise
              its discretion before disclosing it as this information is
              susceptible to misuse.
            </p>
            <h3>NON-PERSONAL IDENTIFIABLE INFORMATION</h3>
            <p className={'term-cond-p'}>
              The information qualifies as non-personal in nature when the
              information collected does not identify a specific end user. Such
              information is collected when the user visits the Website, etc.
              and would include but not limited to, URL, Internet service
              provider/IP Address/Telecom service provider, Type of Device used
              for accessing the Website, Geographical Location etc. Such
              non-personal identifiable information is used by the Website for
              the purposes including but not limited to troubleshoot connection
              problems, administer the website, analyze trends, gather
              demographic information, frequency of visits, average length of
              usage, pages viewed, compliance with applicable law, and cooperate
              with law enforcement activities, etc. The information is used for
              improving the website content and performance and the website may
              share this information with Third Party Service Providers and
              Third-Party Advertisers to measure the overall effectiveness of
              the website’s online advertising, content, programming and for
              other bonafide purpose as required. THE USER HEREBY REPRESENT AND
              CONFIRMS THAT THE INFORMATION PROVIDED TO THE WEBSITE IS
              AUTHENTIC, CORRECT, CURRENT AND UPDATED AND HAVING THE
              RIGHT/PERMISSION TO PROVIDE SUCH INFORMATION. THE WEBSITE AND ITS
              ENTITIES SHALL NOT BE RESPONSIBLE FOR THE AUTHENTICITY OF THE
              INFORMATION THAT THE USER MAY PROVIDE. THE USER SHALL BE
              PERSONALLY LIABLE AND INDEMNIFY THE WEBSITE CONTENTS FOR THE
              BREACH OF ANY PROVISION.
            </p>
            <h3>CHILDREN PRIVACY POLICY</h3>
            <p className={'term-cond-p'}>
              The website requires that the User using the services are above 18
              years of age assisting their minors at the time of enrolment,
              however guardians/parents can provide info about a child user in
              order to obtain a service related to such a minor user. It is
              assumed that you as an information provider has full authority as
              a guardian or parent or having taken written permissions from
              their guardian/parent, to provide information about such a minor
              user.
            </p>
            <h3>OUR DISCLAIMER</h3>
            <p className={'term-cond-p'}>
              The information and data contained on www.enroutingcareers.com
              website is to be treated purely for your entertainment purposes
              only. Any astrology related prediction or other message that you
              receive is not substitute for advice, programs, or treatment that
              you would normally receive from a licensed professional such as a
              lawyer, doctor, psychiatrist, or financial advisor. Accordingly,
              we provide no guarantees, implied warranties, or assurances of any
              kind, and will not be responsible for any interpretation made or
              used by the recipient of the information and data mentioned above.
              Moreover, Enrouting Careers Pvt Ltd is a registered firm. All the
              transaction and gathered data is/will be accessed by Enrouting
              careers pvt ltd.
            </p>
            <h3>INDEMNIFICATION </h3>
            <p className={'term-cond-p'}>
              The User shall indemnify, defend and hold harmless the Website and
              its parent, subsidiaries, affiliates, officers, directors,
              employees, suppliers, consultants and agents from any and all
              third party claims, liability, damages and/or costs (including,
              but not limited to, attorney’s fees) arising from Your use of the
              Services, Your violation of the Privacy Policy or these Terms of
              Service, or Your violation of any third party's rights, including
              without limitation, infringement by You or any other user of Your
              account of any intellectual property or other right of any person
              or entity. These Terms of Service will insure to the benefit of
              Website’s successors, assigns, and licensees.
            </p>
            <h3>GOVERNING LAW</h3>
            <p className={'term-cond-p'}>
              This EULA agreement, and any dispute arising out of or in
              connection with this EULA agreement, shall be governed by and
              construed in accordance with the laws of ICA(INDIAN CONRTACT
              ACT,1982).
            </p>
            <h3>NOTICES</h3>
            <p className={'term-cond-p'}>
              Except as otherwise stated in these Terms of Service, all notices
              to a party shall be in writing and shall be made either via email
              or registered post. “Enrouting Careers Pvt Ltd, No, 14/35.
              Bhajanamadam, Piriyath House, Chembra Post, Palakad, Kerala679304.
            </p>
          </div>
        </div>
      </div>
          <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
