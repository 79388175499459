import { PARENTS_SIGN_IN,PARENT_LOGOUT,PARENTS_SIGN_UP,SET_PARENT_DETAILS } from '../actionsType';
import Cookies from 'js-cookie';

const initialState = {
  parentsData: null,
  isAuthenticated: null,
};

const parentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARENTS_SIGN_IN:
      Cookies.set('parentJWT', action?.payload?.token, {
        expires: 7,
         path: '/'
      });
      return {
        ...state,
        parentsData: action?.payload?.parentsDetails,
        isAuthenticated: true,
      };
      case SET_PARENT_DETAILS:
        return {
          ...state,
          parentsData: action?.payload,
          isAuthenticated: true,
        };
  
      case PARENTS_SIGN_UP:
      Cookies.set('parentJWT', action?.payload?.token, {
        expires: 7,path: '/'
      });
      return {
        ...state,
        parentsData: action?.payload?.parentsDetails,
        isAuthenticated: true,
      };
      case PARENT_LOGOUT:
      Cookies.remove('parentJWT',{path: '/'});
      return initialState;

      
    default:
        return state;
    }
}


export default parentReducer;