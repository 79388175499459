import { useFormik } from "formik";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { InputForm } from "../../../../components/inputField/InputForm";
import NormalSelector from "../../../../components/dropdownSelector/NormalSelector";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import * as Yup from "yup";
import FormButton from "../../../../components/FormButton/FormButton";
import { Slide, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format, isBefore } from "date-fns";
import { createMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import { BsClock } from "react-icons/bs";
import { createMcqQuestions } from "../../../../api/addExam/MCQ/mcq_question_api";


const McqQuestionForm = ({ exam_Id }) => {
  const initialValues = {
    examId: exam_Id,
    write_question: "",
    options: [""],
    correct_answer: "",
  };
  
  const queryClient = useQueryClient();
  const examId=exam_Id
  console.log("exam_Id_form", examId);

  


  // post the data
  const createPostMutation = useMutation({
    mutationFn: createMcqQuestions,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.mcq_question });
      toast.success("Question added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    },
    onError: (error) => {
      toast.error("An error occurred while adding the question.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const onSubmit = async (values) => {

    console.log(exam_Id)
    try {
      if (exam_Id===null) {
        toast.error("Exam ID is missing. Please check your data.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
        return;
      }
      console.log("Submitting with exam_Id:", exam_Id);

      const formData = new FormData();
      formData.append("examId", exam_Id);
      formData.append("write_question", values.write_question);
      formData.append("correct_answer", values.correct_answer);

      // Append options using the same key
      values.options.forEach((option) => {
        formData.append("options", option);
      });

      await createPostMutation.mutate(formData);
    } catch (error) {
      console.error(error.message || "An error occurred");
      toast.error("An error occurred while adding the questions.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    }
  };

  const validationSchema = Yup.object({
    write_question: Yup.string().required("Please enter the question"),
    correct_answer: Yup.string()
      .required("Please enter correct answer")
      .test(
        "is-lowercase",
        "Correct answer must be in lowercase",
        function (value) {
          return value && value === value.toLowerCase();
        }
      ),
    options: Yup.array()
      .of(Yup.string().required("Option is required"))
      .required("Please enter options")
      .min(1, "At least one option is required"),
  });

  const formik = useFormik({
    onSubmit,
    initialValues: { ...initialValues },
    validationSchema,
    validateOnMount: true,
  });

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
    handleChange,
  } = formik;

  const handleReset = () => {
    resetForm();
  };

  const addOption = () => {
    if (values.options.length === 4) {
      return;
    }
    setFieldValue("options", [...values.options, ""]);
  };

  const removeOption = (index) => {
    const newOptions = [...values.options];
    if (newOptions.length === 1) {
      return;
    }
    newOptions.splice(index, 1);
    setFieldValue("options", newOptions);
  };

  const handleChangeOptions = (e, index) => {
    const newOptions = [...values.options];
    newOptions[index] = e.target.value;
    setFieldValue("options", newOptions);
  };

  console.log("values", values);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
          width: "100%",
          mt: 3,
        }}
      >
        {/* row 1 questions*/}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          <InputForm
            type="text"
            formik={formik}
            name="write_question"
            placeholder="write question..."
          />
        </Box>

        {/* options */}
        <Box>
          {values.options.map((option, index) => (
            <Box key={index}>
              <Typography>{`Option ${index + 1}`}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  size="small"
                  id={`options-${index}`}
                  label={`Enter Option ${index + 1}`}
                  variant="outlined"
                  name={`options-${index}`}
                  required
                  value={option}
                  onChange={(e) => handleChangeOptions(e, index)}
                  onBlur={handleBlur}
                  error={touched.options && Boolean(errors.options)}
                  helperText={touched.options && errors.options}
                />
                <Box>
                  <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => removeOption(index)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              onClick={addOption}
              variant="contained"
              color="success"
              disabled={values.options.length === 4}
              size="small"
            >
              Add Option
            </Button>
          </Box>
          <Box
            sx={{
              width: "160px",
            }}
          ></Box>
        </Box>
      </Box>

      {/* submit button */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
        }}
      >
        <InputForm
          type="text"
          formik={formik}
          name="correct_answer"
          placeholder="Enter correct answer"
        />
        <FormButton handleSubmit={handleSubmit} handleReset={handleReset} />
      </Box>
    </>
  );
};

export default McqQuestionForm;
