import React, { useEffect, useState } from "react";

import "../styles/Cards.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../redux/actions/meetingActions";
import {
  formatDate,
  getMinuteDifference,
  StandardTime,
  calculateCountdown,
} from "../functions/date";
import maleTeacher from "../images/front-view-man-classroom.jpg";
import LivePlans from "./LivePlans";
// import "../styles/LivePlans.css";
import { getLecturePrice, getUserAllLectures } from "../redux/actions/lectureActions";
import { getAllCourses } from "../redux/actions/courseActions";

const StudentCard = (props) => {
  const [paymentModel, setPaymentModel] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const {userData}=useSelector((store)=>store.userReducer)

  



  const { userAllLiveLectures,lecturePrices } = useSelector((store) => store.lectureReducer);





  console.log('userAllLiveLectures', userAllLiveLectures)
  useEffect(() => {
    if (!meetings) {
      // Check if meetings is null or undefined
      dispatch(getLecturePrice(userData?.standard));
      dispatch(getMeetings());
     
    }
  }, [dispatch, meetings]); // Include meetings in the dependency array

  useEffect( ()=>{
    
     dispatch(getAllCourses())
     dispatch(getUserAllLectures())
   

  },[])
// console.log('lecturepr', lecturePrices)
  const standard=userData?.standard;
  const isPaid = false;
  //console.log('meetings', meetings?.data?.meeting);
  const meet = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const meeetingData = meet;

  const currentTime = new Date().getTime();
  const activeMeetings = meeetingData?.filter(
    (meeting) => new Date(meeting.endTime).getTime() > currentTime
  );

  const meetingByClass=activeMeetings.filter?.((item)=> standard == item.class)
  console.log('meetinfByClass', meetingByClass)

 
  if (!meetingByClass || meetingByClass?.length === 0) {
    return (
      <div className="custom-card col-md-6 mb-5 mt-5">
        <div className="custom-flex">
          <div className="custom-body">
            <p className="custom-text">
              Currently there are no live classes are scheduled
            </p>
          </div>
        </div>
      </div>
    );
  }

  const startTime = new Date(meeetingData[4].startTime).getTime();

  const date = new Date(startTime);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "pm" : "am";
  const formattedTime = `${hours % 12 || 12}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  const handleJoinClick = async (item) => {
    console.log('item', item)

    const isLecturePurchased = userAllLiveLectures?.some((lectures) => {
      const courseInstances = lectures;
  
      console.log('lectures', lectures)
      const validTill = courseInstances?.validTill;
      const isValid = new Date(validTill) >= new Date();
      return lectures?.courseId === item?.courseId && isValid;
    });


    console.log('isLecturePurchased', isLecturePurchased)
  
    if (!isLecturePurchased) {
      setPaymentModel(true);  //this should be true made "false" to test 
    } else {
     
 
    const studentMeetIdToView = await meetingByClass.find((meet) => meet.id === item.id);
    navigate(`/live/lobby/${item?.id}`, { state: { studentMeetData: studentMeetIdToView, userData:userData } });

    }
  };

  return (
    <>
      {meetingByClass.map((item, index) => {
        const differenceMinutes = getMinuteDifference(item?.startTime, item?.endTime);
        const startTimeMillis = new Date(item?.startTime).getTime();
        const endTimeMillis = new Date(item?.endTime).getTime();
        const { label, disabled, color } = calculateCountdown(startTimeMillis, endTimeMillis);
  
        return (
          <div key={index} className="custom-card col-12 col-sm-6 col-lg-4 m-1">
            <div className="custom-flex">
              <div className="custom-image">
                <img src={maleTeacher} className="custom-img" alt="Teacher" />
              </div>
              <div className="custom-body">
                <div className="custom-date">
                  {formatDate(item?.date)} | {differenceMinutes} mins
                </div>
                <h5 className="custom-title">{item?.chapter} | {item?.subject}</h5>
                <div className="custom-text">{item?.createdBy}</div>
                <div className="custom-class">{item?.class}th</div>
                <span className={`label-text ${color}`}>{label}</span>
  
                {!disabled && (
                  <button
                    className={`custom-btn ${color}`}
                    onClick={() => handleJoinClick(item)}
                  >
                    JOIN
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
  
      {paymentModel && (
        <div className="model-overlay">
          <div className="model">
            <LivePlans lecturePrices={lecturePrices} closeModal={() => setPaymentModel(false)} />
          </div>
        </div>
      )}
    </>
  );
  

//   return (
//     <>
//       {meetingByClass.map((item, index) => {
//         const differenceMinutes = getMinuteDifference(
//           item?.startTime,
//           item?.endTime
//         );
//         const startTimeMillis = new Date(item?.startTime).getTime();
//         const endTimeMillis = new Date(item?.endTime).getTime();
//         const { label, disabled, color } = calculateCountdown(
//           startTimeMillis,
//           endTimeMillis
//         );

//         // Start time in milliseconds
//       //   return (
//       //     <div key={index} className="custom-card col-md-6 m-3">
//       //       <div className="custom-flex">
//       //         <div className="custom-image">
//       //           <img src={maleTeacher} className="custom-img" alt="Card" />
//       //         </div>
//       //         <div className="custom-body">
//       //           <div className="custom-date">
//       //             {" "}
//       //             {formatDate(item?.date)} | {differenceMinutes}mins{" "}
//       //           </div>
//       //           <h5 className="custom-title">
//       //             {item?.chapter} | {item?.subject}
//       //           </h5>
//       //           <p className="custom-text">{item?.createdBy}</p>
//       //           <p className="custom-class">{item?.class}</p>
//       //           {/* {currentTime > startTimeMillis ?(
//       //             <Link className="custom-btn"  to={item?.url}>Join Now</Link>
//       //           ):`Classes will be starting from ${StandardTime(startTimeMillis)}`} */}
//       //           <span style={{ color }}>{label}</span>

//       //           {!disabled && (
//       //             <button
//       //               className={`custom-btn ${color}`}
//       //               onClick={() => handleJoinClick(item)}
//       //             >
//       //               JOIN
//       //             </button>
//       //           )}
//       //         </div>
//       //       </div>
//       //     </div>
//       //   );
//       // })}


//         // Start time in milliseconds
//         return (
//           <div key={index} className="custom-card col-md-6 m-3">
//             <div className="custom-flex">
//               <div className="custom-image">
//                 <img src={maleTeacher} className="custom-img" alt="Card" />
//               </div>
//               <div className="custom-body">
//                 <div className="custom-date">
//                   {" "}
//                   {formatDate(item?.date)} | {differenceMinutes}mins{" "}
//                 </div>
//                 <h5 className="custom-title">
//                   {item?.chapter} | {item?.subject}
//                 </h5>
//                 <p className="custom-text">{item?.createdBy}</p>
//                 <p className="custom-class">{item?.class}th</p>
//                 {/* {currentTime > startTimeMillis ?(
//                     <Link className="custom-btn"  to={item?.url}>Join Now</Link>
//                   ):`Classes will be starting from ${StandardTime(startTimeMillis)}`} */}
//                 <span style={{ color }}>{label}</span>
  
//                 {!disabled && (
//                   <button
//                     className={`custom-btn ${color}`}
//                     onClick={() => handleJoinClick(item)}
//                   >
//                     JOIN
//                   </button>
//                 )}
// {/* 
// {!disabled && (
//                   <button
//                     className={`custom-btn ${color}`}
//                     onClick={() => window.open(item?.viewer_url, '_blank')}
//                   >
//                     JOIN
//                   </button>
//                 )} */}
//               </div>
//             </div>
//           </div>
//         );
   
//     })}

//       {paymentModel && (
//         <div className="model-overlay" >
//           <div className="model">
          
//             <LivePlans lecturePrices={lecturePrices} closeModal={() => setPaymentModel(false)} />
//           </div>
//         </div>
//       )}
//     </>
//   );
};

export default StudentCard;








/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

