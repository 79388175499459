import React from 'react'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from "@mui/joy/Button";
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import { purchaseLive,verifyLivePayment } from '../redux/actions/api/index';
import { toast

} from 'react-toastify';

import ecLogo from '../images/ec_logo_square.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function  PlanCard  ({ title, price, originalPrice, features, isPopular = false, buttonText = "Buy now",selectedReward,pointsUsed,selectedSubjects,packageName }) {

console.log('selectedSubjects', selectedSubjects)

const{userData}=useSelector((store)=>store.userReducer)
const navigate=useNavigate()
    
  const handleBuyNow = async (title,price, selectedReward,pointsUsed,selectedSubjects,packageName) => {
    if (!selectedSubjects.length) {
      window.alert("Please select subjects first!"); // Show alert
      return; // Exit the function
    }
    console.log('selectedSubjects', selectedSubjects)
    let usedPoints = pointsUsed[title.toLowerCase()];
    try {
      const response = await purchaseLive({
        courseIds:selectedSubjects,
        subscriptionType:title,
        amount:price,
        rewardType: selectedReward,
        pointsUsed: usedPoints,
        packageName:packageName
      });
  
      if (response.status !== 201) throw new Error('Purchase failed');

      
  
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: response.data.response.amount,
        currency: response.data.response.currency,
        name: 'Enrouting Careers',
        description: response.data.response.notes.desc,
        image: ecLogo,  // Ensure ecLogo is imported
        order_id: response.data.response.id,
        handler: async (paymentResponse) => {
          try {
            const paymentDetails = {
              orderCreationId: response.data.response.id,
              razorpayPaymentId: paymentResponse.razorpay_payment_id,
              razorpaySignature: paymentResponse.razorpay_signature,
              userId: userData.id,
              amount:price,
              rewardType: selectedReward,
              pointsUsed: usedPoints,
              courseIds:selectedSubjects,
              subscriptionType:title,
              packageName:packageName

            };
  
            // Send payment details to backend for verification (optional but recommended)
             const verifyResponse = await verifyLivePayment(paymentDetails);
         
            if (verifyResponse.status === 200) {
             
              navigate('/subscription', {
                state: {
                  item: {  paymentDetails, type: 'Live lectures' },
                },
              });
            } else {
              throw new Error('Payment verification failed');
            }
  
          } catch (error) {
            console.error('Error verifying payment:', error);
            toast.error('Payment verification failed');
          }
  
          // handleClose();
          document.body.style.overflow = 'auto';
        },
        prefill: {
          name: `${userData.firstname} ${userData.lastname}`,
          email: userData.email,
          contact: userData.phone,
        },
        theme: { color: '#3399cc' },
      };
  
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', () => {
        toast.error('Payment failed');
        // handleClose();
        document.body.style.overflow = 'auto';
      });
      
    } catch (error) {
      console.error('Error during purchase:', error);
      toast.error(error.message || 'An error occurred during the purchase');
    } finally {
      document.body.style.overflow = 'auto';
    }
  };

    

return (
    <Card
      size={isPopular ? "md" : "md"}
      variant={isPopular ? "solid" : "solid"}
      color={isPopular ? "neutral" : "outlined"}
      invertedColors={isPopular}
      sx={isPopular ? { background: "linear-gradient(to right, #fb7b49, #edaf38)" } : {}}
    >
      <Chip size="sm" variant="outlined"  sx={{
      color: isPopular ? 'white' : 'black', // Text color
      borderColor: isPopular ? 'white' : 'grey', // Border color
      backgroundColor: isPopular ? 'transparent' : 'transparent', // Background color
    }}>
        {isPopular ? "MOST POPULAR" : "BASIC"}
      </Chip>
      <Typography level="h2" color={isPopular ? "white" : "inherit"}>
        {title}
      </Typography>
      <Divider inset="none" />
      <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))', color: isPopular ? 'white' : 'inherit' }}>
        {features.map((feature, index) => (
          <ListItem key={index}>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {feature}
          </ListItem>
        ))}
      </List>
      <Divider inset="none" />
      <CardActions>
        <Typography level="title-lg" sx={{ mr: 'auto' }} color={isPopular ? "white" : "inherit"}>
          {/* Check if originalPrice is greater than price to apply strikethrough */}
          ₹ {originalPrice > price ? (
            <>
              <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', marginRight: '8px' }}>
                {originalPrice}
              </span>
              {price}
            </>
          ) : (
            price
          )}
        </Typography>
        <Button variant={isPopular ? "outlined" : "soft"} color="neutral" endDecorator={<KeyboardArrowRight />} onClick={() => handleBuyNow(title,price,selectedReward,pointsUsed,selectedSubjects,packageName)}  >
        {/*  */}
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
}