import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../../components/Navbar";

const MCQExamResult = () => {
  // Fetch submitted test result
  const location = useLocation();
  const results = location?.state?.currentResult; // Ensure safe access
  console.log("resultsHH", results);
  return (
    <>
      <Navbar />
      <Box sx={{ margin: "75px 20px 0px 20px" }}>
        <Typography textAlign="center" variant="h5">
          You have successfully submitted the test
        </Typography>
        <hr />
        <Box>
          <Card
            sx={{
              width: "400px",
              background: "whiteSmoke",
              margin: "auto auto",
              padding: "20px",
            }}
            variant="outlined"
          >
            <Typography variant="h6">You score is : </Typography>
            <CardContent>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <p>Obtained Marks:</p>
                    </td>
                    <td>
                      <p>{results?.submission?.obtainedMarks}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Percentage:</p>
                    </td>
                    <td>
                      <p>{results?.submission?.percentage.toFixed(2)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Rupees Earned:</p>
                    </td>
                    <td>
                      <p>{results?.submission?.rupeesEarned.toFixed(2)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Time Taken:</p>
                    </td>
                    <td>
                      <p>{results?.submission?.timeTaken.toFixed(2)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Loyalty Points Earned:</p>
                    </td>
                    <td>
                      <p>
                        {results?.submission?.loyaltyPointsEarned.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default MCQExamResult;
