import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import closeIcon from "../images/ic_close.svg";
import backIcon from "../images/ic_back.svg";
import successIcon from "../images/ic_tick_green.svg";
import { validator } from "../util/helperFunctions";

import { login, signup } from "../redux/actions/userActions";
import { forgotPasswordEmail } from "../redux/actions/api";
import {
  teacher_login,
  setTeacherDetails,
} from "../redux/actions/teacherActions";

import "../styles/LoginModal.css";
import { toast } from "react-toastify";
import {parentsLogin,parentSignup} from '../redux/actions/parentActions'
import { setSelectedStudent } from "../redux/actions/getStudentActions";

const initialSignupDetails = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  password: "",
  standard: "",
  school: "",
  confirmPassword: "",
  addressLine1: "",
  addressLine2: "",
  institutionType: "",
  college: "",
  state: "",
};

const initialLoginDetails = {
  email: "",
  password: "",
};

const initialTeacherLoginDetails = {
  email: "",
  password: "",
};

const initialParentsSignupDetails={
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  password: "",
  studentPhones:[''],
  studentEmails:['']


}

const LoginModal = (props) => {
  const { setLoginModal } = props;
  const [modalType, setModalType] = useState("login");
  const [openInstitutionalMenu, setOpenInstitutionalMenu] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [signupData, setSignupData] = useState(initialSignupDetails);
  const [loginData, setLoginData] = useState(initialLoginDetails);
  const [inputForForgotPass, setInputForForgotPass] = useState({ email: "" });
  const [errors, setErrors] = useState(null);
  //Teacher

  const [teacherLoginData, setTeacherLoginData] = useState(initialTeacherLoginDetails);
  const [teacherLoginError, setTeacherLoginError] = useState(null);

  // Parents useState
  const [parentsSignupData, setParentsSignupData] = useState(initialParentsSignupDetails);

  const [parentsLoginData, setParentsLoginData] = useState(initialLoginDetails);
  const [parentsLoginError, setParentsLoginError] = useState(null);

  const dispatch = useDispatch();
  const history = useNavigate();

  const goTOLoginModal = () => {
    setModalType("login");
    setSignupData(initialSignupDetails);
    setErrors(null);
  };


  const goTOCreateAccountModal = () => {
    setModalType("create-account");
    setSignupData({
      ...signupData,
      addressLine1: "",
      addressLine2: "",
      institutionType: "",
      college: "",
      state: "",
      standard: "",
      school: "",
    });
    setOpenInstitutionalMenu(false);
    setErrors(null);
  };

  const goToParentsLoginModal = () => {
    setModalType("login-parent");
    setParentsSignupData(initialParentsSignupDetails);
    setErrors(null);
  };

  const changeToNextParentsModal=()=>{
    const requiredFields = [
      "firstname",
      "lastname",
      "email",
      "password",
      "confirmPassword",
      "phone",
    ];
    const flag = validator(parentsSignupData, requiredFields);

    if (flag === true) {
      setErrors(null);
      if (parentsSignupData.password === parentsSignupData.confirmPassword) {
        setModalType("few-parent-details");
      } else {
        setErrors({
          ...errors,
          password: "",
          confirmPassword: "Passwords don't match",
        });
      }
    } else {
      setErrors(flag);
    }
  }

  const handleInstitutionMenu = () => {
    if (errors) {
      setErrors({ ...errors, institutionType: "" });
    }
    if (openInstitutionalMenu) setOpenInstitutionalMenu(false);
    else setOpenInstitutionalMenu(true);
  };

  const handleInstitutionType = (type) => {
    // console.log(type, openInstitutionalMenu);
    setSignupData({ ...signupData, institutionType: type });
    if (errors) {
      setErrors({ ...errors, institutionType: "" });
    }
  };

  const handleSignupDetailsChange = (e) => {
    const { name } = e.target;
    setSignupData({ ...signupData, [name]: e.target.value });
    if (errors) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleLoginDetailsChange = (e) => {
    const { name } = e.target;
    setLoginData({ ...loginData, [name]: e.target.value });
    if (loginError) {
      setLoginError(false);
    }
    if (errors) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleForgotPassChange = (e) => {
    const { name } = e.target;
    setInputForForgotPass({ ...inputForForgotPass, [name]: e.target.value });
    if (errors) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  ///Teacher handle 

  const handleTeacherLoginDetailsChange = (e) => {
    const { name, value } = e.target;
    setTeacherLoginData({ ...teacherLoginData, [name]: value });
    if (teacherLoginError) {
      setTeacherLoginError(false);
    }
  };

  const handleTeacherLogin = async (e) => {
    e.preventDefault();
  
    const requiredFields = ["email", "password"];
    const isValid = validator(teacherLoginData, requiredFields);
  
    if (isValid) {
      setTeacherLoginError(null);
      try {
        const res = await dispatch(teacher_login(teacherLoginData, history));
         console.log('res', res)
        if (res?.response?.data?.success === false) {
          setTeacherLoginError(true);
          toast.error(res?.response?.data?.message || "Login failed. Please try again.");
        } else {
          setTeacherLoginData(initialTeacherLoginDetails);
          setLoginModal(false);
          history("/teacher-home", { state: { token: res?.accessToken, teacherDetails: res } });
          toast.success("Log in successfully!");
        }
      } catch (error) {
        setTeacherLoginError(true);
        console.log(error)
        toast.error(error?.response?.data?.message||"An error occurred during login. Please try again.");
      }
    } else {
      toast.error("Please fill in all required fields.");
    }
  };


//      Parents Sign up Details


const handleParentsSignupDetailsChange = (e) => {
  const { name } = e.target;
  setParentsSignupData({ ...parentsSignupData, [name]: e.target.value });
  if (errors) {
    setErrors({ ...errors, [name]: "" });
  }
};




//                    Parents login handles

  const handleParentsLoginDetailsChange = (e) => {
    const { name, value } = e.target;
    setParentsLoginData({ ...parentsLoginData, [name]: value });
    if (parentsLoginError) {
      setParentsLoginError(false);
    }
  };

  const handleParentsLogin = async (e) => {
    e.preventDefault();
  
    const requiredFields = ["email", "password"];
    const isValid = validator(parentsLoginData, requiredFields);
    console.log('parents login data ********************',parentsLoginData)
  
    if (isValid) {
      setParentsLoginError(null);
      try {
        const res = await dispatch(parentsLogin(parentsLoginData, history)); // Await the dispatch
        // console.log(res.parent?.id);
        if (res?.response?.data?.success === false) {
          setParentsLoginError(true);
          toast.error(res?.response?.data?.message || "Login failed. Please try again.");
        } else {
          setParentsLoginData(initialLoginDetails);
          setLoginModal(false)
          const parentsDetails = res;
          console.log('parentsDetails', parentsDetails)
          const selectedStudent = parentsDetails?.data?.result?.students[0]; 

          console.log('first', parentsDetails?.data.result?.students[0]?.id)
          console.log('selectedStudent', selectedStudent)
          // console.log(selectedStudent?.id,"88888888888888888888888888888888888888888888888888888")
          if (selectedStudent) {
       
            dispatch(setSelectedStudent(selectedStudent));
      

            history(`/parents/${selectedStudent.id}/dashboard`);
          }




          // history(`/parents/:${parentsDetails.students[0].id}/dashboard`,  { state: { token: res.accessToken, parentsDetails: res?.parent } });
          toast.success(res?.response?.data?.message ||"Log in successfully!");
        }
      } catch (error) {
        setParentsLoginError(true);
       console.log(error.response?.data?.message  )
        toast.error( error.response?.data?.message  || "An error occurred during login. Please try again.");
      }
    } else {
      toast.error("Please fill in all required fields.");
    }
  };
  



  const handleSignup = async (e) => {
    e.preventDefault();
    var requiredFields = ["addressLine1", "institutionType", "state"];
    if (signupData.institutionType === "College") {
      requiredFields = [...requiredFields, "college"];
    } else if (signupData.institutionType === "School") {
      requiredFields = [...requiredFields, "school", "standard"];
    }

    const flag = validator(signupData, requiredFields);

    if (flag === true) {
      setErrors(null);
      const formData = { ...signupData };
      const address = formData.addressLine1 + " " + formData.addressLine2;
      delete formData.addressLine1;
      delete formData.addressLine2;
      formData.address = address;
      try{
        const res=await dispatch(signup(formData))

       if (res?.response?.data?.success === false)
        {
          setErrors(flag);
      }
      else{
        setSignupData(initialSignupDetails);
        setModalType("success");

      }
      
      }catch(err){
        toast.error( err.response?.data?.message  || "Something went wrong")
      }
      
    } 
  };

  // student login
  const handleLogin = async (e) => {
    e.preventDefault();

    const requiredFields = ["email", "password"];
    // const flag = validator(loginData, requiredFields);
    
const flag=true
          if (flag === true) {
            setErrors(null);
            try{
           const res=await  dispatch(login(loginData, history))
        if (res?.response?.data?.success === false) {
          setLoginError(true);
          toast.error("Check Server");
        } else {
          setLoginData(initialLoginDetails);

          setLoginModal(false);
          toast.success("User has logged in!");
        }
     
    }
    catch(error){
      setParentsLoginError(true);
      console.log(error )
       toast.error( error.response?.data?.message  || "An error occurred during login. Please try again.");
  
    }
  }
  else{
    toast.error("Use Gmail Only");
  }


}
  
  const handleForgotPassSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ["email"];

    const flag = validator(inputForForgotPass, requiredFields);

    if (flag === true) {
      setErrors(null);
      await forgotPasswordEmail(inputForForgotPass)
        .then((res) => {
          alert("Email Send Successfully");
          setInputForForgotPass({ email: "" });
        })
        .catch((err) => {
          alert(err.response.data.message);
          setInputForForgotPass({ email: "" });
        });
    } else {
      setErrors(flag);
    }
  };

  const changeToNextModal = () => {
    const requiredFields = [
      "firstname",
      "lastname",
      "email",
      "password",
      "confirmPassword",
      "phone",
    ];
    const flag = validator(signupData, requiredFields);

    if (flag === true) {
      setErrors(null);
      if (signupData.password === signupData.confirmPassword) {
        setModalType("few-details");
      } else {
        setErrors({
          ...errors,
          password: "",
          confirmPassword: "Passwords don't match",
        });
      }
    } else {
      setErrors(flag);
    }
  };

  const handleSignupSuccess = () => {
    setLoginModal(false);
    history("/");
   
  };


  const handleParentSignup = async (e) => {
    e.preventDefault();
    
    const data = {
      studentPhones: parentsSignupData.studentPhones,
      studentEmails: parentsSignupData.studentEmails,
    };
  
    console.log(data);
  
    const requiredFields = ["email", "password","phone"];
    const flag = validator(parentsSignupData, requiredFields);
  
    if (!flag) {
      setErrors(flag);
      
    } else {
      setErrors(null);
      const formData = { ...parentsSignupData };
      
      try {
        const res = await dispatch(parentSignup(formData));
        console.log('res', res)
        if (res?.response?.data?.success === false) {
          toast.error( "Check Server");
        } else {
          setParentsSignupData(initialParentsSignupDetails);
          setModalType("success-parent");
        }
      } catch (error) {

        console.log(error)
        toast.error(error.response?.data?.message || "Something went wrong");
      }
    }
  };
  
  const handleParentSignupSuccess=()=>{
    setLoginModal(false);
    history("/parents/ranking");
    toast.success('Login successfull!')
  }



  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);

  const addFields = () => {
    setAdditionalFieldsVisible(true);
  };

  const removeFields = () => {
    setAdditionalFieldsVisible(false);
    setParentsSignupData(prevData => ({
      ...prevData,
      studentEmails: [''],
      studentPhones: ['']
  }));
  };


  const handleArraySignupDetailsChange = (event, index) => {
    const { name, value } = event.target;
    setParentsSignupData((prevState) => {
      const updatedField = [...prevState[name]];
      updatedField[index] = value;
      return {
        ...prevState,
        [name]: updatedField,
      };
    });
  };


  return (
    <div className="LoginModal">
      <div className="LoginModal-content">
        <span onClick={() => setLoginModal(false)}>
          <img
            src={closeIcon}
            alt="close-icon"
            className="LoginModal-close-icon"
          />
        </span>
        {modalType === "login" && (
          <div className="LoginModal-container">
            <h1 className="login-title">Login</h1>
            {loginError && (
              <p className="login-error-msg">Incorrect email or password</p>
            )}
            <div className="LoginModal-inputDiv">
              <input
                name="email"
                type="text"
                value={loginData.email}
                placeholder="Email ID"
                className={`LoginModal-input ${
                  errors && errors.email && errors.email !== "" ? "error" : ""
                }`}
                onChange={handleLoginDetailsChange}
              />
              {errors && errors.email !== "" && (
                <label className="errorMessage" htmlFor="emailError">
                  {errors.email}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="password"
                type="password"
                value={loginData.password}
                placeholder="Password"
                className={`LoginModal-input ${
                  errors && errors.password && errors.password !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleLoginDetailsChange}
              />
              {errors && errors.password !== "" && (
                <label className="errorMessage" htmlFor="passwordError">
                  {errors.password}
                </label>
              )}
            </div>
            <p
              className="forgot-password-msg"
              onClick={() => setModalType("forgot-password")}
            >
              Forgot password?
            </p>
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
            <div style={{ width: "86%" }}>
              <div className="LoginModal-divider"> </div>
            </div>

            <p>Don't have an account?</p>
            <button
              className="create-account-button"
              onClick={() => setModalType("create-account")}
            >
              Create an account
            </button>
            <div className="new-login-div">
              <button
                className="login-new"
                onClick={() => setModalType("login-teacher")}>
                Login As Teacher
              </button>
              <button className="login-new" onClick={() => setModalType("login-parent")} >Login As Parent</button>
            </div>
          </div>
        )}

        {modalType === "login-teacher" && (
          <div className="LoginModal-container-teacher">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={goTOLoginModal}
              />
            </div>
            <h1 className="login-title-teacher">Login as Teacher</h1>
            {teacherLoginError && (
              <p className="login-error-msg">Incorrect email or password</p>
            )}
            <div className="LoginModal-inputDiv">
              <input
                name="email"
                type="text"
                value={teacherLoginData.email}
                placeholder="Email ID"
                className={`LoginModal-input ${
                  teacherLoginError ? "error" : ""
                }`}
                onChange={handleTeacherLoginDetailsChange}
              />
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="password"
                type="password"
                value={teacherLoginData.password}
                placeholder="Password"
                className={`LoginModal-input ${
                  teacherLoginError ? "error" : ""
                }`}
                onChange={handleTeacherLoginDetailsChange}
              />
            </div>

            <button className="login-button" onClick={handleTeacherLogin}>
              Login
            </button>

            <div style={{ width: "86%" }}>
              <div className="LoginModal-divider"> </div>
            </div>

            <p>Don't have an account?</p>
            <button className="create-account-button">Contact Admin</button>
          </div>
        )}


                    {/* Parent Login model */}


         {modalType === "login-parent" && (
          <div className="LoginModal-container-teacher">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={goTOLoginModal}
              />
            </div>
            <h1 className="login-title-teacher">Login as Parent</h1>
            {parentsLoginError && (
              <p className="login-error-msg">Incorrect email or password</p>
            )}
            <div className="LoginModal-inputDiv">
              <input
                name="email"
                type="text"
                value={parentsLoginData.email}
                placeholder="Enter Parents Email ID"
                className={`LoginModal-input ${
                  parentsLoginError ? "error" : ""
                }`}
                onChange={handleParentsLoginDetailsChange}
              />
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="password"
                type="password"
                value={parentsLoginData.password}
                placeholder="Password"
                className={`LoginModal-input ${
                  parentsLoginError ? "error" : ""
                }`}
                onChange={handleParentsLoginDetailsChange}
              />
            </div>

            <button className="login-button" onClick={handleParentsLogin}>
              Login
            </button>

            <div style={{ width: "86%" }}>
              <div className="LoginModal-divider"> </div>
            </div>

            <p>Don't have an account?</p>
            <button className="create-account-button"  onClick={() => setModalType("create-parent-account")}>Create an account</button>
          </div>
        )}

                {/* Parents Sign Up model */}


         {modalType === "create-parent-account" && (
          <div className="LoginModal-container">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={goToParentsLoginModal}
              />
            </div>{" "}
            <h1 className="create-account-title">Create a parents account</h1>
            <div className="LoginModal-inputDiv">
              <input
                name="firstname"
                type="text"
                value={parentsSignupData.firstname}
                placeholder="First name"
                className={`LoginModal-input ${
                  errors && errors.firstname && errors.firstname !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.firstname !== "" && (
                <label className="errorMessage" htmlFor="firstnameError">
                  {errors.firstname}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="lastname"
                type="text"
                value={parentsSignupData.lastname}
                placeholder="Last name"
                className={`LoginModal-input ${
                  errors && errors.lastname && errors.lastname !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.lastname !== "" && (
                <label className="errorMessage" htmlFor="lastnameError">
                  {errors.lastname}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="email"
                type="text"
                value={parentsSignupData.email}
                placeholder="Email ID"
                className={`LoginModal-input ${
                  errors && errors.email && errors.email !== "" ? "error" : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.email !== "" && (
                <label className="errorMessage" htmlFor="emailError">
                  {errors.email}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="phone"
                type="tel"
                value={parentsSignupData.phone}
                placeholder="Parents Phone number"
                className={`LoginModal-input ${
                  errors && errors.phone && errors.phone !== "" ? "error" : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.phone !== "" && (
                <label className="errorMessage" htmlFor="phoneError">
                  {errors.phone}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="password"
                type="password"
                value={parentsSignupData.password}
                placeholder="Password"
                className={`LoginModal-input ${
                  errors && errors.password && errors.password !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.password !== "" && (
                <label className="errorMessage" htmlFor="passwordError">
                  {errors.password}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="confirmPassword"
                type="password"
                value={parentsSignupData.confirmPassword}
                placeholder="Confirm password"
                className={`LoginModal-input ${
                  errors &&
                  errors.confirmPassword &&
                  errors.confirmPassword !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleParentsSignupDetailsChange}
              />
              {errors && errors.confirmPassword !== "" && (
                <label className="errorMessage" htmlFor="confirmPasswordError">
                  {errors.confirmPassword}
                </label>
              )}
            </div>
            <button className="login-button" onClick={changeToNextParentsModal}>
              Proceed
            </button>
          </div>
        )}

          {modalType === "few-parent-details" && (
          <div className="LoginModal-container">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={() => setModalType('create-parent-account')}
              />
            </div>
            <h1 className="create-account-title">Few details about student</h1>
            <div className="LoginModal-inputDiv">
              <input
                name="studentPhones"
                type="text"
                value={parentsSignupData.studentPhones[0]||""}
                placeholder="Student's phone number"
                className={`LoginModal-input ${
                  errors && errors.studentPhones[0] && errors.studentPhones[0] !== ""
                    ? "error"
                    : ""
                }`}
                onChange={(e) => handleArraySignupDetailsChange(e, 0)}
              />
              {errors && errors.studentPhones[0] !== "" && (
                <label className="errorMessage" htmlFor="student_phone_0">
                  {errors.studentPhones[0]}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="studentEmails"
                type="text"
                value={parentsSignupData.studentEmails[0]||""}
                placeholder="Student's email"
                className="LoginModal-input"
                onChange={(e) => handleArraySignupDetailsChange(e, 0)}
              />
              {errors && errors.studentEmails[0] !== "" && (
                <label className="errorMessage" htmlFor="studentEmails_0">
                  {errors.studentEmails[0]}
                </label>
              )}
            </div>

            {additionalFieldsVisible && (
              <>
                  <h1 className="create-account-title">Few details</h1>
                <div className="LoginModal-inputDiv">
                  <input
                    name="studentPhones"
                    type="text"
                    value={parentsSignupData.studentPhones[1]||""}
                    placeholder="Student's additional phone number"
                    className={`LoginModal-input ${
                      errors && errors.studentPhones[1] && errors.studentPhones[1] !== ""
                        ? "error"
                        : ""
                    }`}
                    onChange={(e) => handleArraySignupDetailsChange(e, 1)}
                  />
                  {errors && errors.studentPhones[1] !== "" && (
                    <label className="errorMessage" htmlFor="student_phone_1">
                      {errors.studentPhones[1]}
                    </label>
                  )}
                </div>
                <div className="LoginModal-inputDiv">
                  <input
                    name="studentEmails"
                    type="text"
                    value={parentsSignupData.studentEmails[1]||""}
                    placeholder="Student's additional email"
                    className="LoginModal-input"
                    onChange={(e) => handleArraySignupDetailsChange(e, 1)}
                  />
                  {errors && errors.studentEmails[1] !== "" && (
                    <label className="errorMessage" htmlFor="studentEmails_1">
                      {errors.studentEmails[1]}
                    </label>
                  )}
                </div>
                <button className="create-account-button" onClick={removeFields}>
                  Remove Another Student
                </button>
              </>
            )}

            {!additionalFieldsVisible && (
              <button className="create-account-button" onClick={addFields}>
                Add Another Email & Phone
              </button>
            )}
            <button className="login-button" onClick={handleParentSignup}>
              Create account
            </button>
          </div>
        )}

        {modalType === "success-parent" && (
                  <div className="LoginModal-container">
                    <img
                      src={successIcon}
                      alt="success-icon"
                      className="success-icon"
                    />
                    <h1 className="success-title">Account created successfully</h1>
                    <p className="success-text">
                      Let's give wings to our innovations. Welcome to Enrouting Careers
                      Family
                    </p>
                    <button className="login-button" onClick={handleParentSignupSuccess}>
                      Done
                    </button>
                  </div>
                )}










        {modalType === "create-account" && (
          <div className="LoginModal-container">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={goTOLoginModal}
              />
            </div>{" "}
            <h1 className="create-account-title">Create account</h1>
            <div className="LoginModal-inputDiv">
              <input
                name="firstname"
                type="text"
                value={signupData.firstname}
                placeholder="First name"
                className={`LoginModal-input ${
                  errors && errors.firstname && errors.firstname !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.firstname !== "" && (
                <label className="errorMessage" htmlFor="firstnameError">
                  {errors.firstname}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="lastname"
                type="text"
                value={signupData.lastname}
                placeholder="Last name"
                className={`LoginModal-input ${
                  errors && errors.lastname && errors.lastname !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.lastname !== "" && (
                <label className="errorMessage" htmlFor="lastnameError">
                  {errors.lastname}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="email"
                type="text"
                value={signupData.email}
                placeholder="Email ID"
                className={`LoginModal-input ${
                  errors && errors.email && errors.email !== "" ? "error" : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.email !== "" && (
                <label className="errorMessage" htmlFor="emailError">
                  {errors.email}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="phone"
                type="tel"
                value={signupData.phone}
                placeholder="Phone number"
                className={`LoginModal-input ${
                  errors && errors.phone && errors.phone !== "" ? "error" : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.phone !== "" && (
                <label className="errorMessage" htmlFor="phoneError">
                  {errors.phone}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="password"
                type="password"
                value={signupData.password}
                placeholder="Password"
                className={`LoginModal-input ${
                  errors && errors.password && errors.password !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.password !== "" && (
                <label className="errorMessage" htmlFor="passwordError">
                  {errors.password}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="confirmPassword"
                type="password"
                value={signupData.confirmPassword}
                placeholder="Confirm password"
                className={`LoginModal-input ${
                  errors &&
                  errors.confirmPassword &&
                  errors.confirmPassword !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.confirmPassword !== "" && (
                <label className="errorMessage" htmlFor="confirmPasswordError">
                  {errors.confirmPassword}
                </label>
              )}
            </div>
            <button className="login-button" onClick={changeToNextModal}>
              Proceed
            </button>
          </div>
        )}
        {modalType === "few-details" && (
          <div className="LoginModal-container">
            <div className="back-icon-container">
              <img
                src={backIcon}
                alt="back-icon"
                className="back-icon"
                onClick={goTOCreateAccountModal}
              />
            </div>
            <h1 className="create-account-title">Few details</h1>
            <div className="LoginModal-inputDiv">
              <input
                name="addressLine1"
                type="text"
                value={signupData.addressLine1}
                placeholder="Address line 1"
                className={`LoginModal-input ${
                  errors && errors.addressLine1 && errors.addressLine1 !== ""
                    ? "error"
                    : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.addressLine1 !== "" && (
                <label className="errorMessage" htmlFor="addressLine1Error">
                  {errors.addressLine1}
                </label>
              )}
            </div>
            <div className="LoginModal-inputDiv">
              <input
                name="addressLine2"
                type="text"
                value={signupData.addressLine2}
                placeholder="Address line 2"
                className="LoginModal-input"
                onChange={handleSignupDetailsChange}
              />
            </div>
            <div className="menuDiv">
              <div
                className={`LoginModal-input LoginModal-menuDiv ${
                  errors &&
                  errors.institutionType &&
                  errors.institutionType !== ""
                    ? "error"
                    : ""
                }`}
                onClick={handleInstitutionMenu}
              >
                {!openInstitutionalMenu && (
                  <span
                    className={
                      signupData.institutionType === "" ? "placeholder" : ""
                    }
                  >
                    {signupData.institutionType === ""
                      ? "Institution type"
                      : signupData.institutionType}
                  </span>
                )}
                {openInstitutionalMenu && (
                  <div className="menu-container">
                    <div
                      className="menu-item"
                      onClick={() => handleInstitutionType("College")}
                    >
                      <span>College</span>
                    </div>
                    <div
                      className="menu-item"
                      onClick={() => handleInstitutionType("School")}
                    >
                      <span>School</span>
                    </div>
                  </div>
                )}
              </div>
              {errors && errors.institutionType !== "" && (
                <label className="errorMessage" htmlFor="institutionTypeError">
                  {errors.institutionType}
                </label>
              )}
            </div>
            {signupData.institutionType === "School" && (
              <Fragment>
                <div className="LoginModal-inputDiv">
                  <input
                    name="standard"
                    type="number"
                    value={signupData.standard}
                    placeholder="Standard of student"
                    onChange={handleSignupDetailsChange}
                    className={`LoginModal-input ${
                      errors && errors.standard && errors.standard !== ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {errors && errors.standard !== "" && (
                    <label className="errorMessage" htmlFor="standardError">
                      {errors.standard}
                    </label>
                  )}
                </div>
                <div className="LoginModal-inputDiv">
                  <input
                    name="school"
                    type="text"
                    value={signupData.school}
                    placeholder="School name"
                    onChange={handleSignupDetailsChange}
                    className={`LoginModal-input ${
                      errors && errors.school && errors.school !== ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {errors && errors.school !== "" && (
                    <label className="errorMessage" htmlFor="schoolNameError">
                      {errors.school}
                    </label>
                  )}
                </div>
              </Fragment>
            )}
            {signupData.institutionType === "College" && (
              <div className="LoginModal-inputDiv">
                <input
                  name="college"
                  type="text"
                  value={signupData.college}
                  placeholder="College name"
                  className={`LoginModal-input ${
                    errors && errors.college && errors.college !== ""
                      ? "error"
                      : ""
                  }`}
                  onChange={handleSignupDetailsChange}
                />
                {errors && errors.college !== "" && (
                  <label className="errorMessage" htmlFor="collegeNameError">
                    {errors.college}
                  </label>
                )}
              </div>
            )}

            {/* <div className="LoginModal-inputDiv">
              <input
                name="state"
                type="text"
                value={signupData.state}
                placeholder="State"
                className={`LoginModal-input ${
                  errors && errors.state && errors.state !== "" ? "error" : ""
                }`}
                onChange={handleSignupDetailsChange}
              />
              {errors && errors.state !== "" && (
                <label className="errorMessage" htmlFor="stateError">
                  {errors.state}
                </label>
              )}
            </div> */}




<div className="LoginModal-inputDiv">
  <select
    name="state"
    type="text"
    value={signupData.state}
    
    className={`LoginModal-input  select-without-icon ${errors && errors.state && errors.state !== "" ? "error" : ""}`}
    onChange={handleSignupDetailsChange}
  >
    <option value="" disabled>Select State</option>
    <option value="Andhra Pradesh">Andhra Pradesh</option>
    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
    <option value="Assam">Assam</option>
    <option value="Bihar">Bihar</option>
    <option value="Chhattisgarh">Chhattisgarh</option>
    <option value="Goa">Goa</option>
    <option value="Gujarat">Gujarat</option>
    <option value="Haryana">Haryana</option>
    <option value="Himachal Pradesh">Himachal Pradesh</option>
    <option value="Jharkhand">Jharkhand</option>
    <option value="Karnataka">Karnataka</option>
    <option value="Kerala">Kerala</option>
    <option value="Madhya Pradesh">Madhya Pradesh</option>
    <option value="Maharashtra">Maharashtra</option>
    <option value="Manipur">Manipur</option>
    <option value="Meghalaya">Meghalaya</option>
    <option value="Mizoram">Mizoram</option>
    <option value="Nagaland">Nagaland</option>
    <option value="Odisha">Odisha</option>
    <option value="Punjab">Punjab</option>
    <option value="Rajasthan">Rajasthan</option>
    <option value="Sikkim">Sikkim</option>
    <option value="Tamil Nadu">Tamil Nadu</option>
    <option value="Telangana">Telangana</option>
    <option value="Tripura">Tripura</option>
    <option value="Uttar Pradesh">Uttar Pradesh</option>
    <option value="Uttarakhand">Uttarakhand</option>
    <option value="West Bengal">West Bengal</option>
    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
    <option value="Chandigarh">Chandigarh</option>
    <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
    <option value="Delhi">Delhi</option>
    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
    <option value="Ladakh">Ladakh</option>
    <option value="Lakshadweep">Lakshadweep</option>
    <option value="Puducherry">Puducherry</option>
  </select>
  {errors && errors.state !== "" && (
    <label className="errorMessage" htmlFor="stateError">
      {errors.state}
    </label>
  )}
</div>


            <button className="login-button" onClick={handleSignup}>
              Create account
            </button>
          </div>
        )}
        {modalType === "success" && (
          <div className="LoginModal-container">
            <img
              src={successIcon}
              alt="success-icon"
              className="success-icon"
            />
            <h1 className="success-title">Account created successfully</h1>
            <p className="success-text">
              Let's give wings to our innovations. Welcome to Enrouting Careers
              Family
            </p>
            <button className="login-button" onClick={handleSignupSuccess}>
              Done
            </button>
          </div>
        )}
        {modalType === "forgot-password" && (
          <div className="LoginModal-container">
            <h1 className="forgot-password-title">Forgot Your Password?</h1>
            <p>
              No worries! Enter your registered email and we'll send you a link
              to reset your password.
            </p>
            <form onSubmit={handleForgotPassSubmit}>
              <div className="LoginModal-inputDiv">
                <input
                  name="email"
                  type="text"
                  value={inputForForgotPass.email}
                  placeholder="Email ID"
                  className={`LoginModal-input ${
                    errors && errors.email && errors.email !== "" ? "error" : ""
                  }`}
                  onChange={handleForgotPassChange}
                />
                {errors && errors.email !== "" && (
                  <label className="errorMessage" htmlFor="emailError">
                    {errors.email}
                  </label>
                )}
              </div>
              <button type="submit" className="login-button">
                Send link
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
