import React, { useState, useEffect } from "react";
import "../styles/LivePlans.css";
import { Form } from "react-bootstrap";
import Box from "@mui/joy/Box";
import PlanCard from "./PlanCard";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import RewardSelection from "./RewardSelection";
import { Button } from "@mui/material";
import { FaCross } from "react-icons/fa";
import { Cancel } from "@mui/icons-material";
import { getLecturePrice } from "../redux/actions/lectureActions";


export default function LivePlans({ closeModal }) {
  const dispatch=useDispatch()
  const { userData } = useSelector((store) => store.userReducer);
  const { lecturePrices } = useSelector((store) => store.lectureReducer);
  const { allCourses } = useSelector((store) => store.courseReducer);
  const { allBoards } = useSelector((store) => store.boardReducer);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState('CBSE'); // default board
  const [showRewardSelection, setShowRewardSelection] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [pointsUsed, setPointsUsed] = useState({ monthly: 0, quarterly: 0, yearly: 0 });
  const [finalPrice, setFinalPrice] = useState({ monthly: 0, quarterly: 0, yearly: 0 });
useEffect(()=>{
      dispatch(getLecturePrice(userData.standard))
},[])




  useEffect(() => {
    const defaultSubject = getFilteredCourses(userData?.standard, selectedBoard)?.[0];
    if (defaultSubject) setSelectedSubjects([{ id: defaultSubject.id, subject: defaultSubject.subject }]);
  }, [userData, allCourses, selectedBoard]);

  const getFilteredCourses = (standard, board) => {
    return allCourses?.filter(
      (course) => course?.class === standard.toString() && course?.board === board
    );
  };

  const handleSubjectSelection = (subject) => {
    setSelectedSubjects((prevSubjects) =>
      prevSubjects.some(s => s.subject === subject.subject)
        ? prevSubjects.filter(s => s.subject !== subject.subject)
        : [...prevSubjects, { id: subject.id, subject: subject.subject }]
    );
  };

  const removeReward = () => {
    updatePrices();
    setSelectedReward(null);
    setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
  };

  const updatePrices = () => {
    const packagePrices = lecturePrices.find(
      (lecture) => lecture.packageName === getPackageBySubjects()
    );
    if (packagePrices) {
      setFinalPrice({
        monthly: packagePrices.monthly_prices,
        quarterly: packagePrices.quarterly_prices,
        yearly: packagePrices.yearly_prices,
      });
      setSelectedReward(null);
      setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
    }
  };

  const getPackageBySubjects = () => {
    const subjectCount = selectedSubjects.length;
    if (subjectCount === 1) return "single";
    if (subjectCount === 2) return "double";
    if (subjectCount === 3) return "triple";
    if (subjectCount >= 4) return "premium";
  };

  useEffect(() => {
    updatePrices();
  }, [selectedSubjects, lecturePrices]);

  return (
    <div className="model-overlay" onClick={closeModal}>
      <div className="model" onClick={(e) => e.stopPropagation()}> {/* Prevent click from closing the modal */}
        <div className="mx-5 mt-4">
          <div className="row justify-content-end mb-4">
            <button
              type="button"
              className="close"
              onClick={closeModal}
              aria-label="Close"
              style={{
                backgroundColor: "#ff5e57",
                color: "#fff",
                border: "none",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "all 0.3s ease",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#e53935";
                e.target.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#ff5e57";
                e.target.style.transform = "scale(1)";
              }}
            >
              X
            </button>
          </div>

          {showRewardSelection ? (
            <RewardSelection
              userData={userData}
              closeModal={() => setShowRewardSelection(false)}
              setPointsUsed={setPointsUsed}
              setSelectedReward={setSelectedReward}
              finalPrice={finalPrice}
              setFinalPrice={setFinalPrice}
              setShowRewardSelection={setShowRewardSelection}
              getPackageBySubjects={getPackageBySubjects}
              lecturePrices={lecturePrices}
            />
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex" style={{ gap: '7px', alignItems: 'center' }}>

                <DropdownButton id="dropdown-basic-button" title="Select Board" className="dropdown-button button-center " >
                    {allBoards.map((board, index) => (
                      <Dropdown.Item
                        key={index}
                        as="button"
                        onClick={() => setSelectedBoard(board.name)}
                        style={{
                          padding: '10px',
                          borderRadius: '8px',
                          transition: 'background 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = "#e0e0e0"}
                        onMouseLeave={(e) => e.target.style.backgroundColor = ""}
                      >
                        {board.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>

                  <DropdownButton id="dropdown-basic-button" title="Select Subjects"  className="dropdown-button button-center ">
                    {getFilteredCourses(userData?.standard, selectedBoard).map((course, index) => (
                      <Dropdown.Item
                        key={index}
                        as="button"
                        onClick={() => handleSubjectSelection(course)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          padding: '10px',
                          borderRadius: '8px',
                          transition: 'background 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = "#e0e0e0"}
                        onMouseLeave={(e) => e.target.style.backgroundColor = ""}
                      >
                        <Form.Check
                          type="checkbox"
                          label={course.subject}
                          checked={selectedSubjects.some(s => s.subject === course.subject)}
                          onChange={() => handleSubjectSelection(course)}
                          style={{ cursor: 'pointer' }}
                        />
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>

                  
                </div>

                {!selectedReward ? (
                  <div>
                    <button className="btn-1"
                      onClick={() => setShowRewardSelection(true)}
                      style={{
                        background: "linear-gradient(to right, #fb7b49, #edaf38)",
                        color: "#fff",
                        border: "none",
                        borderRadius: "25px",
                        padding: "10px 20px",
                        cursor: "pointer",
                        fontSize: "16px",
                        transition: "all 0.3s ease",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.background = "linear-gradient(to right, #edaf38, #fb7b49)";
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.background = "linear-gradient(to right, #fb7b49, #edaf38)";
                        e.target.style.transform = "scale(1)";
                      }}
                    >
                      Use Points
                    </button>
                  </div>
                ) : (
                  <div>
                    <p>
                      <strong>
                        {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
                      </strong>
                      <Button sx={{color:'red'}} onClick={removeReward}>Remove</Button>
                    </p>
                  </div>
                )}
              </div>

              <div className="mb-4">
                <strong>Selected Subjects: </strong>
                {selectedSubjects.map(s => s.subject).join(', ') || "None"}
              </div>

              <div className="mb-5">
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px',justifyContent: 'center' }}>
                  <PlanCard
                    title="Monthly"
                    price={finalPrice.monthly}
                    originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.monthly_prices}
                    features={["Recorded Lectures", "Live Sessions", "API Integration"]}
                    pointsUsed={pointsUsed}
                    selectedReward={selectedReward}
                    selectedSubjects={selectedSubjects.map(s => s.id)}
                    packageName={getPackageBySubjects()}
                  />
                  <PlanCard
                    title="Quarterly"
                    price={finalPrice.quarterly}
                    originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.quarterly_prices}
                    features={["Virtual Credit Cards", "Financial Analytics", "API Integration"]}
                    pointsUsed={pointsUsed}
                    selectedReward={selectedReward}
                    selectedSubjects={selectedSubjects.map(s => s.id)}
                    packageName={getPackageBySubjects()}
                  />
                  <PlanCard
                    title="Yearly"
                    price={finalPrice.yearly}
                    originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.yearly_prices}
                    features={["Unlimited Access", "Live Classes", "Recorded Lectures", "Cancel Anytime"]}
                    pointsUsed={pointsUsed}
                    selectedReward={selectedReward}
                    selectedSubjects={selectedSubjects.map(s => s.id)}
                    isPopular
                    packageName={getPackageBySubjects()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}







