import React, { useEffect, useState } from 'react';
import '../styles/Zoom.css'
import { ZoomMtg } from '@zoom/meetingsdk';
import { useLocation } from 'react-router-dom';
import config from '../config';
import '../styles/Zoom.css'

function Lobby() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
  }, []);

  const location = useLocation();
  const ViewMeet = location?.state?.studentMeetData;
  const userData = location?.state?.userData;
  const Name = `${userData?.firstname || ''} ${userData?.lastname || ''}`;
  const authEndpoint = `${config.BASE_URL}/api/signature`;
  const sdkKey = config.ZOOM_SDK_KEY;
  const meetingNumber = ViewMeet?.meetId?.toString();
  const passWord = ViewMeet?.password?.toString();
  const role = 0;
  const userName = Name?.toString();
  const userEmail = userData?.email?.toString();
  const leaveUrl = `${config.ZOOM_LEAVE_URL}/live`;

  const getSignature = async () => {
    setIsLoading(true); // Start loading
    try {
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });

      if (!req.ok) {
        throw new Error(`Failed to fetch signature: ${req.statusText}`);
      }

      const res = await req.json();
      const signature = res.signature;

      if (!signature) {
        throw new Error('Signature is not available');
      }

      startMeeting(signature);
    } catch (error) {
      console.error("Error in getSignature:", error);
      alert("Failed to start the meeting. Please try again later.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  function startMeeting(signature) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (initSuccess) => {
        console.log("ZoomMtg.init success:", initSuccess);

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          success: (joinSuccess) => {
            console.log("ZoomMtg.join success:", joinSuccess);
          },
          error: (joinError) => {
            console.error("Error in ZoomMtg.join:", joinError);
            alert("Failed to join the meeting. Please check your meeting details or try again.");
          },
        });
      },
      error: (initError) => {
        console.error("Error in ZoomMtg.init:", initError);
        alert("Failed to initialize the meeting. Please try again later.");
      },
    });
  }

  const handleGoBack = () => {
    const userConfirmed = window.confirm('Are you sure you want to leave the meeting?');
    if (userConfirmed) {
      window.location.assign('/live');
    }
  };

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting SDK Sample React</h1>
        {isLoading ? <p>Loading...</p> : <button onClick={getSignature}>Join Meeting</button>}
        <button onClick={handleGoBack}>Go Back</button>
      </main>
    </div>
  );
}

export default Lobby;
