import React, { useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { addMCQExamsCol } from "../../../../data/mockData";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteExam } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { Slide, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModalExams from "../../../../components/deleteModal/deleteModalExams";
import config from "../../../../config";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from "react-redux";



const MCQTable = ({ data, usersData }) => {
  const {teacherData}=useSelector((store)=>store.teacherReducer)
  // Fetch user by token
  
  
  // console.log('teacherData', teacherData)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // for add exam modal
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);
  

  //for edit modal open and closed
  const handleEditClick = (exam_Id) => {
    setSelectedFormData(exam_Id);
    setIsEditOpen(true);
    console.log("selectedFormData", selectedFormData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const paginatedData = data?.slice(startIndex, endIndex);

  const totalLength = data && data?.length;

  const [examData, setExamData] = useState([]);

  // for delete modal
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const queryClient = useQueryClient();

  // delete data mutation
  const mutationDelete = useMutation({
    mutationFn: deleteExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.mcq_exams });
      toast.success("Exams deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
    onError: (error) => {
      toast.error("An error occurred while deleting the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const handleDeleteClick = (userId) => {
    // Show the delete confirmation dialog
    setShowDeleteConfirmation(true);
    setUserIdToDelete(userId);
  };

  const handleConfirmDelete = () => {
    // Trigger the delete mutation here
    if (userIdToDelete) {
      mutationDelete?.mutate(userIdToDelete);
    }

    // Close the confirmation dialog
    setShowDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Close the confirmation dialog without deleting
    setShowDeleteConfirmation(false);
  };
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const examIds = data?.map((item) => item.id);
        const examDataPromises = examIds.map((id) =>
          axios.get(
            `${config.BASE_URL}/api/userAttendExam/attended-users/${id}`
          )
        );
        const examDataResponses = await Promise.all(examDataPromises);
        const examsData = examDataResponses.map(
          (response) => response.data.data
        );
        setExamData(examsData.flat());
      } catch (error) {
        console.error("Failed to fetch exam data:", error);
      }
    };

    fetchExamData();
  }, [data]);

  // console.log('examData', examData)

  const navigate = useNavigate();
  const handleCheckResult = async (examData) => {
    // Ensure examData is an array
    const normalizedExamData = Array.isArray(examData) ? examData : [examData];

    console.log("mcqAttemptStu", normalizedExamData);

    // Find the corresponding user from usersData for each item in examData
    const studentDetails = normalizedExamData?.map((exam) => {
      return usersData?.find((user) => user.id == exam.userId);
    });

    console.log("studentDetailsMCQ", studentDetails);

    if (studentDetails) {
      navigate("/test/mcq-test-attempt-by-students", {
        state: { examData: normalizedExamData, studentDetails: studentDetails },
      });
    } else {
      alert("No student available");
    }
  };

  return (
    <>
  <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'auto' }}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          {addMCQExamsCol?.map((column) => (
            <TableCell
              key={column.id}
              sx={{
                textAlign: "center",
                borderColor: "black",
                borderWidth: 1,
                borderStyle: "solid",
                borderLeft: "none",
                borderRight: "none",
                fontWeight: 'bold',
                backgroundColor: "#121212",
                color: "white",
                '&:hover': {
                  backgroundColor: "#303f9f",
                },
                padding: "12px",
                borderBottom: "2px solid #303f9f"
              }}
            >
              {column.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {paginatedData?.map((item, index) => {
          const userExamData = examData?.find(
      (exam) => exam?.examId === item?.id 
          );
          return (
            <TableRow
              key={item.id}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                '&:nth-of-type(even)': { backgroundColor: '#ffffff' },
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
                transition: 'background-color 0.3s ease'
              }}
            >
              {[
                item.testType,
                item.examName,
                item.select_class,
                item.select_subject,
                item.select_chapter,
                item.total_marks,
                item.select_date,
                item.start_time,
                item.end_time,
             
                
              ].map((value, idx) => (
                <TableCell
                  key={idx}
                  sx={{
                    borderColor: "black",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderLeft: "none",
                    borderRight: "none",
                    textAlign: "center",
                    padding: "16px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  {value}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <Tooltip title="Add Question">
                    <AddCircleIcon
                      sx={{ cursor: "pointer", color: "#4caf50", '&:hover': { color: "#388e3c" } }}
                      onClick={() => handleEditClick(item.id)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      sx={{ cursor: "pointer", color: "#f44336", '&:hover': { color: "#c62828" } }}
                      onClick={() => handleDeleteClick(item.id)}
                    />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  userExamData?.attendedBy && handleCheckResult([userExamData])
                }
              >
              
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: userExamData?.attendedBy
                      ? "linear-gradient(45deg, #4caf50 30%, #388e3c 90%)"
                      : "linear-gradient(45deg, #f44336 30%, #d32f2f 90%)",
                    color: "white",
                    boxShadow: 3,
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    '&:hover': {
                      background: userExamData?.attendedBy
                        ? "linear-gradient(45deg, #388e3c 30%, #2c6b2f 90%)"
                        : "linear-gradient(45deg, #d32f2f 30%, #c62828 90%)",
                      boxShadow: 6,
                    },
                  }}
                  disabled={userExamData && userExamData?.attendedBy}
                >
                  {userExamData?.attendedBy===true ? (
                    <>
                      <span style={{ color: 'white' }}>Check Student</span>
                      <CheckCircleIcon sx={{ color: "white" }} />
                    </>
                  ) : (
                    <>
                      Not Attempted  
                      <HighlightOffIcon sx={{ color: "white" }} />
                    </>
                  )}
                </Button>
              </TableCell>
              {/* <TableBody>{userExamData?.attendedBy}</TableBody> */}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>

  <Box
    sx={{
      width: "100%",
      padding: "0px !important",
      margin: { lg: "0", md: "0", sm: "0", xs: "10px 0px" },
      display: "flex",
      flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
      justifyContent: "space-between",
      alignItems: "baseline",
    }}
  >
    <Box sx={{ display: "flex", alignItems: "baseline" }}>
      <Typography sx={{ pl: 1 }}>Total exams: {totalLength}</Typography>
    </Box>

    <Box sx={{ display: "flex", alignItems: "baseline" }}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          alignItems: "baseline",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      />
    </Box>
  </Box>

  {isEditOpen && (
    <AddExamModal
      exam_Id={selectedFormData}
      open={isEditOpen}
      formName="Add MCQ Question"
      onClose={() => setIsEditOpen(false)}
    />
  )}

  <DeleteModalExams
    showDeleteConfirmation={showDeleteConfirmation}
    handleCancelDelete={handleCancelDelete}
    handleConfirmDelete={handleConfirmDelete}
  />
</>

  );
};

export default MCQTable;
