import { client } from "../../api_client";
import config from "../../../config";
const URL = config.BASE_URL;

const endPoints = {
  addMarks: "saveMarks",
};

const API_URLS = {
  ADD_MARKS: `${URL}/UserAttendExam/${endPoints.addMarks}`,
};

export const addMarks = async (postData) => {
  try {
    const url = `${API_URLS.ADD_MARKS}`;

    const response = await client.post(url, postData);

    const data = response.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("An error occurred while creating the post.");
  }
};
