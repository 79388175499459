
// import React, { useEffect, useState } from 'react';
// import '../../styles/Zoom.css';
// import { ZoomMtg } from '@zoom/meetingsdk';
// import { useLocation } from 'react-router-dom';
// import config from '../../config';

// import logo from "../../images/ec_logo_nobg.png";

// const Room = () => {
//   const [loading, setLoading] = useState(false);  // State to show loading
//   const [error, setError] = useState(null);       // State to handle errors

//   useEffect(() => {
//     ZoomMtg.preLoadWasm();
//     ZoomMtg.prepareWebSDK();
//   }, []);

//   const location = useLocation();
//   const ViewMeet = location?.state?.meetData;
//   console.log('view meet', ViewMeet);

//   const authEndpoint = `${config.BASE_URL}/api/signature`;
//   const sdkKey = config.ZOOM_SDK_KEY;
//   const meetingNumber = ViewMeet?.meetId?.toString();
//   const passWord = ViewMeet?.password?.toString();
//   const role = 1;
//   const userName = 'Enrouting Careers';
//   const userEmail = '';
//   const registrantToken = '';
//   const zakToken = '';
//   const leaveUrl = 'https://enroutingcareers.com/teacher-home';

//   const getSignature = async (e) => {
//     e.preventDefault();
//     setLoading(true);  // Start loading
//     setError(null);    // Clear previous errors

//     try {
//       const res = await fetch(authEndpoint, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ meetingNumber, role }),
//       });
//       const response = await res.json();
//       if (res.ok) {
//         startMeeting(response.signature);
//       } else {
//         throw new Error(response.message || 'Failed to fetch signature');
//       }
//     } catch (error) {
//       console.error(error);
//       setError('Error fetching meeting signature. Please try again.');
//     } finally {
//       setLoading(false);  // Stop loading
//     }
//   };

//   const startMeeting = (signature) => {
//     document.getElementById("zmmtg-root").style.display = "block";

//     ZoomMtg.init({
//       leaveUrl,
//       patchJsMedia: true,
//       leaveOnPageUnload: true,
//       isSupportAV: true,
//       isSupportBreakout: false,
//       success: (success) => {
//         console.log(success);

//         ZoomMtg.join({
//           signature,
//           sdkKey,
//           meetingNumber,
//           passWord,
//           userName,
//           userEmail,
//           tk: registrantToken,
//           zak: zakToken,
//           success: (success) => {
//             console.log(success);
//             ZoomMtg.record({
//               record: true,
//             });
//           },
//           error: (error) => {
//             console.error(error);
//             setError('Error joining meeting. Please check the details and try again.');
//           },
//         });
//       },
//       error: (error) => {
//         console.error(error);
//         setError('Error initializing Zoom SDK. Please refresh and try again.');
//       },
//     });
//   };

//   return (
//     <div className="room-container">
//       <div className="image-container">
//         <img src={logo} alt="Zoom Meeting" style={{ height: '200px', objectFit: 'cover' }} />
//       </div>
//       <h1>Welcome To Zoom Meeting</h1>

//       {error && <div className="error-message">{error}</div>} {/* Display error messages */}

//       {loading ? (
//         <button className="btn fancy-btn" disabled>Joining Meeting...</button>  // Show loading state
//       ) : (
//         <button className="btn fancy-btn" onClick={getSignature}>Join Meeting</button>
//       )}
//     </div>
//   );
// };

// export default Room;











//////////////////////////////////////////////////////////////////////////////////////////





import { useEffect, useState } from "react";

import { ZoomMtg } from "@zoom/meetingsdk";
import config from '../../config';
import logo from "../../images/ec_logo_nobg.png";
import { useLocation } from "react-router-dom";
import '../../styles/Zoom.css';


function Room() {

  const location = useLocation();
  const ViewMeet = location?.state?.meetData;
  const TeacherData=location?.state?.teacherData
 

  useEffect(()=>{
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();


    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };

    return () => {
      // Cleanup if needed
    };
    
  },[])
  const authEndpoint =  `${config.BASE_URL}/api/signature`
  const sdkKey = config.ZOOM_SDK_KEY;
  const meetingNumber = ViewMeet?.meetId?.toString();
  const passWord = ViewMeet?.password?.toString();
  const role = 1;
  const userName = "Enrouting Careers";
  const userEmail = TeacherData?.email?.toString();
  const registrantToken = "";
  const zakToken = "";
  const leaveUrl = `${config.ZOOM_LEAVE_URL}/teacher-home`




  const getSignature = async () => {
    try {
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });
      const res = await req.json()
      const signature = res.signature;
      startMeeting(signature)
    } catch (e) {
      console.log(e);
    }
  };

  function startMeeting(signature) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (success) => {
        console.log(success);
        // can this be async?
        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }


  const handleGoBack = () => {
    const userConfirmed = window.confirm('Are you sure you want to leave the meeting?');
    if (userConfirmed) {
        // Redirect immediately without a smooth transition
    window.location.assign('/teacher-home');
    }
  };

  return (
    <div className="App">
      <main>
      <div className="image-container">
         <img src={logo} alt="Zoom Meeting" style={{ height: '200px', objectFit: 'cover' }} />
      </div>
  <h1>Welcome To Zoom Meeting</h1>
        <button onClick={getSignature}>Join Meeting</button>
        <button onClick={handleGoBack}>Go Back</button>
      </main>
    </div>
  );
}

export default Room;
