
import React from 'react'
import TeacherNavbar from './Teacher/TeacherNavbar'
import { useDispatch, useSelector } from 'react-redux'
import { IoEyeSharp } from "react-icons/io5";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import {
  Approval,
  Clear,
  Done,
  ThumbDown,
  ThumbDownAlt,
} from "@mui/icons-material";


export default function RequestLectures() {

const {requests}=useSelector((store)=>store.requestReducer)
const Data=requests?.user;
const Status=1
  return (
<>
<TeacherNavbar />
<Box mt={12}>
  <BreadCrumbs pageName="Teacher" title="Lecture requests" />
  <Box mt={7} mx={2}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{backgroundColor:"#383838"}}>
            <TableCell sx={{ color: "#FAFAFA" }}>Sr.No</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Student Name</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Subject</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Chapter</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Class</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Reason</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Status</TableCell>
            <TableCell sx={{ color: "#FAFAFA" }}>Action</TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {Data?.map((item,index) => (
            <TableRow key={item.id}>
              <TableCell>{index+1}</TableCell>
              <TableCell>{item.studentName}</TableCell>
              <TableCell>{item.subjectName}</TableCell>
              <TableCell>{item.chapterName}</TableCell>
              <TableCell>{item.standard}</TableCell>
              <TableCell>{item.reason}</TableCell>
              <TableCell>{Status === 1 ? (
                        <span class="badge rounded-pill text-bg-success">
                          Approved
                        </span>
                      ) : (
                        <span class="badge rounded-pill text-bg-warning">
                          Not Approved
                        </span>
                      )}</TableCell>    
              
              
              <TableCell>
                {Status === 0 && (
                  <Button
                    variant="outlined" color="success"
                    size="small"
                   // onClick={() => handleApprove(item.id, true)}
                    style={{ cursor: "pointer"}}
                    startIcon={<Done />}
                  >
                    Approve
                  </Button>
                )}
                {Status === 1 && (
                  <Button
                  
                    size="small"
                    variant="outlined" color="error"
                  //  onClick={() => handleApprove(item.id, false)}
                    style={{ cursor: "pointer" }}
                    startIcon={<Clear />}
                  >
                    disapprove
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
</Box>

</>
  )
}
