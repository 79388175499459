import {
  Box,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useFormik } from "formik";
import ConfirmModal from "../../../../components/dropdownSelector/confirmModal/ConfirmModal";
import config from "../../../../config";
import { useSelector } from "react-redux";


const initialValues = {
  userId: "",
  examId: "",
  testType: "",
  timeTaken: "",
  questions: [],
};

const TestScreen = () => {
  const location = useLocation();
  const item = location.state?.item; //fetch all exam data
  const{userData}=useSelector((store)=>store.userReducer)
  console.log("ItemHai", item);

  const navigate = useNavigate();

  // Digital clock state
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null); // store interval ID

  useEffect(() => {
    const id = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    setIntervalId(id); // store interval ID

    // Clear interval when component unmounts
    return () => clearInterval(id);
  }, []);


  // useEffect(() => {
   
  //   window.history.pushState(null, '', window.location.href);
  //   window.onpopstate = function (event) {
  //     window.history.go(1);
  //   };
  // }, []);



  // Format elapsed time as HH:MM:SS
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  // Convert seconds to floating-point minutes (e.g., 45.5 minutes)
  const convertSecondsToMinutes = (totalSeconds) => {
    return totalSeconds / 60;
  };

  const [currentResult, setCurrentResult] = useState([]); // State to store the result
  const onSubmit = async (values, callback) => {
   
    try {
      clearInterval(intervalId);
      const formattedValues = {
        ...values,
        userId: userData?.id,
        examId: item?.id,
        testType: item?.testType,
        timeTaken: convertSecondsToMinutes(seconds), // Converted to minutes
        questions: item.questions.map((ques, index) => ({
          selectedAnswer: selectedOptions[index] || "",
          correctAnswer: ques.correct_answer,
        })),
      };

      console.log("Formatted Values:", formattedValues);

      const response = await fetch(
        `${config.BASE_URL}/api/UserAttendExam/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedValues),
        }
      );

      const result = await response.json();
      console.log("result", result);

      if (response.ok) {
        setCurrentResult(result);
        toast.success("Exams submitted successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
        callback(result); // Pass the result to the callback
      } else {
        throw new Error(
          result.message || "An error occurred while submitting the exams."
        );
      }
    } catch (error) {
      console.error(error.message || "An error occurred");
      toast.error("An error occurred while adding the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    }
  };

  //for view confirmation box
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const handleConfirmSubmit = () => {
    setSubmitConfirmation(true);
    if (submitConfirmation) {
      handleSubmit((result) => {
        console.log("currentResult", result);
        navigate("/student-test/student_mcq-test-result", {
          state: { currentResult: result },
        });
      });
    }
  };

  useEffect(() => {
    if (submitConfirmation && currentResult?.length > 0) {
      navigate("/student-test/student_mcq-test-result", {
        state: { currentResult: currentResult },
      });
    }
  }, [submitConfirmation, currentResult, navigate]);

  const handleCancelSubmit = () => {
    setSubmitConfirmation(false);
  };

  const formik = useFormik({
    onSubmit: (values, { resetForm }) =>
      onSubmit(values, (result) => {
        console.log("Navigating with result:", result);
        resetForm();
        navigate("/student-test/student_mcq-test-result", {
          state: { currentResult: result },
        });
      }),
    initialValues,
    validateOnMount: true,
  });

  const { handleSubmit, values } = formik;

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (questionIndex, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionIndex]: option,
    }));
  };
  const optionLetters = ["a", "b", "c", "d"];
  console.log("Selected Options:", selectedOptions);
  console.log("values", values);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <h3 style={{ textAlign: "center", margin: "30px 0px 10px 0px" }}>
          {item?.examName}
        </h3>

        <Typography variant="h6" sx={{ textAlign: "right", mr: 2 }}>
          Time : {formatTime(seconds)}
        </Typography>
        <hr />
      </Box>

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        {item?.questions?.map((ques, index) => (
          <Box
            key={index}
            sx={{ marginTop: 2, width: "100%", textAlign: "left" }}
          >
            <Typography variant="h6">{`Q${index + 1}: ${
              ques?.write_question
            }`}</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={`question-${index}`}
                name={`question-${index}`}
                value={selectedOptions[index] || ""}
                onChange={(event) =>
                  handleOptionChange(index, event.target.value)
                }
              >
                {ques?.options?.map((option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    value={optionLetters[optionIndex]} // use letters 'a', 'b', 'c', 'd'
                    control={<Radio />}
                    label={`${optionLetters[
                      optionIndex
                    ].toUpperCase()}. ${option}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        ))}
      </Box>
      <Box sx={{ textAlign: "right", margin: "20px" }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          width="200px"
          onClick={handleConfirmSubmit}
        >
          Submit Test
        </Button>
      </Box>

      <ConfirmModal
        showConfirmation={submitConfirmation}
        showCancelation={handleCancelSubmit}
        handleConfirmSubmit={handleConfirmSubmit}
      />
    </Box>
  );
};

export default TestScreen;
