// import React from 'react';
// import lockIcon from '../images/ic_lock.svg';
// import nextIcon from '../images/ic_arrow_right.svg';
// import '../styles/MyProgress.css';
// import closeIcon from '../images/ic_close.svg';



// const MyProgress = ({handleClose,course,subjectName}) => {

// console.log('course', course, subjectName)
//   return (
//     <div className='UnlockChapterModal'>
//     <div className="UnlockChapterModal-content">
//     <span onClick={() => handleClose()}>
//           <img
//             src={closeIcon}
//             alt='close-icon'
//             className='UnlockChapterModal-close-icon'
//           />
//         </span>
//         <h1>{subjectName}</h1>
//   <div className='ChapterCard'>
 
//       <div className="ChapterCard-left">
//         <span>{course.index}</span>
//         <p>{course.name}</p>
//       </div>
   
       
     
//         <div className="ChapterCard-right">
//           <div  className="read-watch-button">
//             <span>50%</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//           {/* <div  className="read-watch-button">
//             <span>Watch lecture</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div> */}
//         </div>
//         </div>
//     </div>
//     </div>
//   );
// };

// export default MyProgress;

import React from 'react';
import lockIcon from '../images/ic_lock.svg';
import nextIcon from '../images/ic_arrow_right.svg';
import '../styles/MyProgress.css';
import closeIcon from '../images/ic_close.svg';

const MyProgress = ({ handleClose, course, subjectName }) => {
  console.log('course', course, subjectName);
  
  return (
    <div className='ProgressModal'>
      <div className="ProgressModal-content">
        <span onClick={handleClose}>
          <img
            src={closeIcon}
            alt='close-icon'
            className='ProgressModal-close-icon' // Kept original classname
          />
        </span>
        <h1 className="ProgressModal-title">{subjectName}</h1>
        
        {course.map((chapter, index) => (
          <div className='ProgressCard' key={index}>
            <div className="ProgressCard-info">
              <span className="ProgressCard-number">{index + 1}</span> 
              <span className="ProgressCard-name">{chapter.name}</span>
            </div>
            <div className="ProgressCard-actions">
              <div className="ProgressCard-progress">
                <span>50%</span>
                <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyProgress;
