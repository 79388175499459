// src/redux/reducers/studentReducer.js
import { SET_SELECTED_STUDENT, FETCH_ALL_EXAMS, SET_EXAM_ATTENDANCE, GET_RANKINGS } from '../actionsType';

const initialState = {
  selectedStudent: null,
  allExams:null,
  examAttendance:{},
  rankings:null
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: action.payload,
      };
      case FETCH_ALL_EXAMS:
        return{
          ...state,
          allExams:action?.payload
        }
        case SET_EXAM_ATTENDANCE:
          return {
            ...state,
            examAttendance: {
            attended: action.payload 
            },
          };

        case GET_RANKINGS:
          return{
            ...state,
            rankings:action?.payload
          }
              

          // case SET_EXAM_ATTENDANCE:
          //   const { examId } = action.payload?.length ? action.payload[0] : { examId: action.examId };
          //   return {
          //     ...state,
          //     examAttendance: {
          //       ...state.examAttendance,
          //       [examId]: action.payload.length ? action.payload[0] : null // Store null if no data
          //     }
          //   };
            
          
    default:
      return state;
  }
};

export default studentReducer;
