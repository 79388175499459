import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";

const ResultCard = ({ rowData }) => {
  return (
    <>
      <Card
        sx={{
          width: "400px",
          margin: "auto auto",
          padding: "20px",
        }}
        variant="outlined"
      >
        <Typography variant="h6">You score is : </Typography>
        <CardContent>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <p>Obtained Marks:</p>
                </td>
                <td>
                  <p>{rowData?.obtainedMarks}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Percentage:</p>
                </td>
                <td>
                  <p>{rowData?.percentage?.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Rupees Earned:</p>
                </td>
                <td>
                  <p>{rowData?.rupeesEarned?.toFixed(2)}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Loyalty Points Earned:</p>
                </td>
                <td>
                  <p>{rowData?.loyaltyPointsEarned?.toFixed(2)}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
      </Card>
    </>
  );
};

export default ResultCard;
