import React, { useState, useEffect } from "react";
import TeacherNavbar from "./Teacher/TeacherNavbar";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjects,
  updateApprovalByTeacher,
} from "../redux/actions/projectActions";
import ConfirmDialog from "./ConfirmDialog";
import {
  Approval,
  Clear,
  Done,
  ThumbDown,
  ThumbDownAlt,
} from "@mui/icons-material";

export default function ProjectsPage() {
  const dispatch = useDispatch();
  const { projectData } = useSelector((store) => store.projectReducer);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [approveAction, setApproveAction] = useState(null);

  useEffect(() => {
    if (!projectData) {
      dispatch(getAllProjects());
    }
  }, [dispatch, projectData]);

  const handleApprove = (id, isApproved) => {
    setCurrentId(id);
    setApproveAction(isApproved);
    setOpenDialog(true);
  };

  console.log('projectData', projectData)
  const handleConfirm = () => {
    if (approveAction) {
      dispatch(updateApprovalByTeacher(currentId, { approval: true })).then(
        () => {
          dispatch(getAllProjects());
        }
      );
    } else {
      dispatch(updateApprovalByTeacher(currentId, { approval: false })).then(
        () => {
          dispatch(getAllProjects());
        }
      );
    }
    setOpenDialog(false);
  };

  return (
    <>
      <TeacherNavbar />
      <Box mt={12}>
        <BreadCrumbs pageName="Teacher" title="Projects" />
        <Box mt={7} mx={2}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{backgroundColor:"#383838"}}>
                  <TableCell sx={{ color: "#FAFAFA" }}>Project</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Description</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Start Date</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>End Date</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Status</TableCell>
                  <TableCell sx={{ color: "#FAFAFA" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.project_name}</TableCell>
                    <TableCell>{item.project_desc}</TableCell>
                    <TableCell>{item.startTime}</TableCell>
                    <TableCell>{item.endTime}</TableCell>
                    <TableCell>
                      {item.approval === 1 ? (
                        <span class="badge rounded-pill text-bg-success">
                          Approved
                        </span>
                      ) : (
                        <span class="badge rounded-pill text-bg-warning">
                          Not Approved
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {item.approval === 0 && (
                        <Button
                          variant="outlined" color="success"
                          size="small"
                          onClick={() => handleApprove(item.id, true)}
                          style={{ cursor: "pointer"}}
                          startIcon={<Done />}
                        >
                          Approve
                        </Button>
                      )}
                      {item.approval === 1 && (
                        <Button
                        
                          size="small"
                          variant="outlined" color="error"
                          onClick={() => handleApprove(item.id, false)}
                          style={{ cursor: "pointer" }}
                          startIcon={<Clear />}
                        >
                          disapprove
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
        title={approveAction ? "Approve Project" : "Disapprove Project"}
      />
    </>
  );
}
