import React from 'react';
import Navbar from './Navbar';
import aboutUsImage from '../images/img_aboutus.svg';
//import { screenSize } from '../util/screenSize';
import '../styles/OurStoryPage.css';
import Footer from './Footer';

/*const OurStoryPage = () => {
  var screen = window.innerWidth;

  if (screen > screenSize) {
    return (
      <div className='OurStoryPage'>
        <Navbar />
        <div className='OurStoryPage-container'>
          <div className='OurStoryPage-container-left'>
            <img src={aboutUsImage} alt='aboutUs-img' className='aboutUs-img' />
          </div>
          <div className='OurStoryPage-container-right'>
            <div className='OurStoryPage-text-box'>
              <h1>Our Story</h1>
              <p>
                Our story begins in late 2015 where we “the founders of the
                company” see a 15-year-old extraordinarily talented boy walking
                down the streets of Connaught Place in New Delhi literally
                begging people for their time so that he can make them their
                portraits and earn some money for his mom’s heart surgery. When
                we spoke to the little kid we were emotionally down with his
                words and his story. The emotional part of the story is that he
                was a student of a well-reputed institute in New Delhi but could
                never pull up anyone’s attention towards his talent in order to
                procure enough amount from the staff and students for the
                surgery. We being sharing almost the same financial background
                couldn’t help him much which kept hunting our minds for long.
              </p>
              <p>
                He used to practice at home but had never got an opportunity to
                participate in any of the competitions just because no one has
                ever seen his talent or ever has he showcased it to someone.
                Just if he had got an opportunity to prove himself in front of
                those staff, they would have willingly taken their time and
                stood for him to complete the portrait and once that was done he
                could have earned a decent amount of money for the surgery and
                also a good amount of grant from the school. We don’t know where
                he is or what he is up to nowadays. But we are sure he would be
                happy to see us working for the betterment of the students of
                his age and caliber.
              </p>
              <p>
                Keeping these in mind we started observing the issues that the
                students faced in their daily life. Doing this on a regular
                scale we realized that the talents were innumerous but the
                opportunities to prove them were countable. Then comes the day
                we start off our company registering it in records, completing
                the incorporation during the month of May, the Year 2019.
                Talking to students and conducting campaigns at different
                schools to pull up the best talents. We started loving the job,
                and today we have conducted and segregated almost 1000’s of
                fresh talents and encouraged more than 2000 students to start
                off something to build curiosity in themselves. We have made
                many students realize through psychometric tests that each and
                everyone has a hidden talent in themselves it just needs good
                time and care to be delivered.
              </p>
              <p>
                Increase in digitization, the start-up ecosystem continuously
                innovating to provide new services, the ever-evolving consumer
                base, and the COVID-19 situation has given the Edtech sector
                both in India and globally a huge boost but being a monopoly in
                what we do is what makes us different from others. It clearly
                showcases that this has nothing to do with COVID-19 rather this
                sector is most affected just the same way as the students have
                been struck in terms of education.
              </p>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='OurStoryPage'>
        <Navbar />
        <div className='OurStoryPage-container'>
          <div className='OurStoryPage-container-left'>
            <h1>Our Story</h1>
            <img src={aboutUsImage} alt='aboutUs-img' className='aboutUs-img' />
          </div>
          <div className='OurStoryPage-container-right'>
            <div className='OurStoryPage-text-box'>
              <p>
                Our story begins in late 2015 where we “the founders of the
                company” see a 15-year-old extraordinarily talented boy walking
                down the streets of Connaught Place in New Delhi literally
                begging people for their time so that he can make them their
                portraits and earn some money for his mom’s heart surgery. When
                we spoke to the little kid we were emotionally down with his
                words and his story. The emotional part of the story is that he
                was a student of a well-reputed institute in New Delhi but could
                never pull up anyone’s attention towards his talent in order to
                procure enough amount from the staff and students for the
                surgery. We being sharing almost the same financial background
                couldn’t help him much which kept hunting our minds for long.
              </p>
              <p>
                He used to practice at home but had never got an opportunity to
                participate in any of the competitions just because no one has
                ever seen his talent or ever has he showcased it to someone.
                Just if he had got an opportunity to prove himself in front of
                those staff, they would have willingly taken their time and
                stood for him to complete the portrait and once that was done he
                could have earned a decent amount of money for the surgery and
                also a good amount of grant from the school. We don’t know where
                he is or what he is up to nowadays. But we are sure he would be
                happy to see us working for the betterment of the students of
                his age and caliber.
              </p>
              <p>
                Keeping these in mind we started observing the issues that the
                students faced in their daily life. Doing this on a regular
                scale we realized that the talents were innumerous but the
                opportunities to prove them were countable. Then comes the day
                we start off our company registering it in records, completing
                the incorporation during the month of May, the Year 2019.
                Talking to students and conducting campaigns at different
                schools to pull up the best talents. We started loving the job,
                and today we have conducted and segregated almost 1000’s of
                fresh talents and encouraged more than 2000 students to start
                off something to build curiosity in themselves. We have made
                many students realize through psychometric tests that each and
                everyone has a hidden talent in themselves it just needs good
                time and care to be delivered.
              </p>
              <p>
                Increase in digitization, the start-up ecosystem continuously
                innovating to provide new services, the ever-evolving consumer
                base, and the COVID-19 situation has given the Edtech sector
                both in India and globally a huge boost but being a monopoly in
                what we do is what makes us different from others. It clearly
                showcases that this has nothing to do with COVID-19 rather this
                sector is most affected just the same way as the students have
                been struck in terms of education.
              </p>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};*/
const OurStoryPage = () => {
  return (
    <div className='OurStoryPage'>
      <Navbar activeMenuItem={'ourstory'} />
      <div className='OurStoryPage-container'>
        <div className='OurStoryPage-container-left'>
          <img src={aboutUsImage} alt='About Us' className='aboutUs-img' />
        </div>
        <div className='OurStoryPage-container-right'>
          <div className='OurStoryPage-text-box'>
            <h1>Our Story</h1>
            <p>Our story begins in late 2015 where we “the founders of the
                company” see a 15-year-old extraordinarily talented boy walking
                down the streets of Connaught Place in New Delhi literally
                begging people for their time so that he can make them their
                portraits and earn some money for his mom’s heart surgery. When
                we spoke to the little kid we were emotionally down with his
                words and his story. The emotional part of the story is that he
                was a student of a well-reputed institute in New Delhi but could
                never pull up anyone’s attention towards his talent in order to
                procure enough amount from the staff and students for the
                surgery. We being sharing almost the same financial background
                couldn’t help him much which kept hunting our minds for long.
              
            </p>
            <p>
            He used to practice at home but had never got an opportunity to
                participate in any of the competitions just because no one has
                ever seen his talent or ever has he showcased it to someone.
                Just if he had got an opportunity to prove himself in front of
                those staff, they would have willingly taken their time and
                stood for him to complete the portrait and once that was done he
                could have earned a decent amount of money for the surgery and
                also a good amount of grant from the school. We don’t know where
                he is or what he is up to nowadays. But we are sure he would be
                happy to see us working for the betterment of the students of
                his age and caliber.
            </p>
            <p>
                Keeping these in mind we started observing the issues that the
                students faced in their daily life. Doing this on a regular
                scale we realized that the talents were innumerous but the
                opportunities to prove them were countable. Then comes the day
                we start off our company registering it in records, completing
                the incorporation during the month of May, the Year 2019.
                Talking to students and conducting campaigns at different
                schools to pull up the best talents. We started loving the job,
                and today we have conducted and segregated almost 1000’s of
                fresh talents and encouraged more than 2000 students to start
                off something to build curiosity in themselves. We have made
                many students realize through psychometric tests that each and
                everyone has a hidden talent in themselves it just needs good
                time and care to be delivered.
            </p>
            <p>
                Increase in digitization, the start-up ecosystem continuously
                innovating to provide new services, the ever-evolving consumer
                base, and the COVID-19 situation has given the Edtech sector
                both in India and globally a huge boost but being a monopoly in
                what we do is what makes us different from others. It clearly
                showcases that this has nothing to do with COVID-19 rather this
                sector is most affected just the same way as the students have
                been struck in terms of education.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};




























export default OurStoryPage;
