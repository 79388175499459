import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../images/ec_logo_nobg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { teacher_logout } from "../../redux/actions/teacherActions";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ReportIcon from "@mui/icons-material/Report";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Badge, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { setSelectedStudent } from '../../redux/actions/getStudentActions'
import { parent_logout } from "../../redux/actions/parentActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const drawerWidth = 240;

export const  Navbar=()=> {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElExams, setAnchorElExams] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [examsOpen, setExamsOpen] = useState(false); // Control for exams submenu

  const { parentsData, isAuthenticated } = useSelector((store) => store.parentReducer);
  const { selectedStudent } = useSelector((store) => store.studentReducer);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  console.log('setselectedstudent', selectedStudent)

  const handleOpenExamsMenu = (event) => {
    setAnchorElExams(event.currentTarget);
  };

  const handleCloseExamsMenu = () => {
    setAnchorElExams(null);
  };

  const handleLogout = () => {
    dispatch(parent_logout(navigate));
  };

  const handleToggleExams = () => {
    setExamsOpen(!examsOpen); // Toggle exams submenu
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {[
          { text: "Dashboard", path: `/parents/${selectedStudent?.id}/dashboard`, icon: <DashboardIcon /> },
          { text: "Rankings", path: "/parents/ranking", icon: <SchoolIcon /> },
          { text: "Live Class", path: "/parents/liveclass", icon: <LiveTvIcon /> },
          { text: "Reports", path: "/parents/reports", icon: <ReportIcon /> },
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(item.path);
                setMobileOpen(false); // Close the drawer after navigation
              }}
            >
              <ListItemIcon>
                {item.icon} {/* New icon based on the item */}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItemButton onClick={handleToggleExams}>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Exams" />
          {examsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={examsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                navigate("/parents/mcq");
                setMobileOpen(false); // Close the drawer after navigation
              }}
            >
              <ListItemIcon>
               
                <ChevronRightIcon/>
              </ListItemIcon>
              <ListItemText primary="MCQ Exam" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                navigate("/parents/subjective");
                setMobileOpen(false); // Close the drawer after navigation
              }}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="Subjective Exam" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                navigate("/parents/mega");
                setMobileOpen(false); // Close the drawer after navigation
              }}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="Mega Exam" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </div>
  );

  const handleStudentSelect = (student) => {
    dispatch(setSelectedStudent(student));
    navigate(`/parents/${student.id}/dashboard`);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#383838" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Hamburger Menu on the Left (Mobile View) */}
          <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Centered Logo in Mobile View */}
          <Box
            component="img"
            sx={{
              display: { xs: "flex", md: "none" },
              mx: 'auto', // Center the logo horizontally
              maxHeight: 50,
              cursor: "pointer",
            }}
            alt="The company logo"
            src={logo}
            onClick={() => handleNavigate(`/parents/${selectedStudent?.id}/dashboard`)}
          />

          {/* Laptop View (Logo on the Left) */}
          <Box
            component="img"
            sx={{
              display: { xs: "none", md: "flex" },
              maxHeight: { xs: 65, md: 65 },
              maxWidth: { xs: 65, md: 65 },
              mx: 5,
              cursor: "pointer",
            }}
            alt="The company logo"
            src={logo}
            onClick={() => handleNavigate(`/parents/${selectedStudent?.id}/dashboard`)}
          />

          {/* Desktop Menu Items */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate(`/parents/${selectedStudent?.id}/dashboard`)}
            >
              Dashboard
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/parents/ranking")}
            >
              Rankings
            </Button>

            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/parents/liveclass")}
            >
              Live Class
              <Badge
                color="secondary"
                sx={{ px: 0.8 }}
                badgeContent={5}
                max={10}
              ></Badge>
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={handleOpenExamsMenu}
            >
              EXAMS
              <ExpandMoreIcon />
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/parents/reports")}
            >
              Reports
            </Button>
          </Box>

          <Menu
  id="menu-exams"
  anchorEl={anchorElExams}
  anchorOrigin={{
    vertical: "bottom",
    horizontal: "left",
  }}
  keepMounted
  transformOrigin={{
    vertical: "top",
    horizontal: "left",
  }}
  open={Boolean(anchorElExams)}
  onClose={handleCloseExamsMenu}
  sx={{
    "& .MuiPaper-root": {
      backgroundColor: "#333",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
      transition: "transform 0.3s ease, opacity 0.3s ease",
      transformOrigin: "top left",
    },
    "& .MuiMenuItem-root": {
      color: "#fff",
      padding: "12px 20px",
      transition: "background-color 0.2s ease, color 0.2s ease, transform 0.2s ease",
      "&:hover": {
        backgroundColor: "#444",
        color: "#edb038", // Change text color on hover
        transform: "scale(1.05)",
      },
    },
  }}
>
  <MenuItem onClick={() => handleNavigate("/parents/mcq")}>
    MCQ Exam
  </MenuItem>
  <MenuItem onClick={() => handleNavigate("/parents/subjective")}>
    Subjective Exam
  </MenuItem>
  <MenuItem onClick={() => handleNavigate("/parents/mega")}>
    Mega Exam
  </MenuItem>
</Menu>


          
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", lg: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
{/* {
  isAuthenticated&&

   <Box sx={{ flexGrow: 0 }}>{
    parentsData?students?.map((item,index)=>(
      <IconButton  sx={{ p: 0 }} key={index}>
      <Avatar>{item?.firstname}</Avatar>
    </IconButton>
    ))
   }
   
   </Box>
} */}
<Box>


<Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{parentsData?.firstname}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
  anchorEl={anchorEl}
  id="account-menu"
  open={open}
  onClose={handleClose}
  onClick={handleClose}
  PaperProps={{
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      backgroundColor: '#333',
      borderRadius: '8px',
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: '#333',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }}
  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
>
  {parentsData?.students?.map((item, index) => (
    <MenuItem
      key={index}
      onClick={() => handleStudentSelect(item)}
      sx={{
        color: '#fff',
        padding: '12px 20px',
        transition: 'background-color 0.2s ease, color 0.2s ease, transform 0.2s ease',
        '&:hover': {
          backgroundColor: '#444',
          color: '#edb038',
          transform: 'scale(1.05)',
        },
      }}
    >
      <PermIdentityIcon />
      {item.firstname}
    </MenuItem>
  ))}
  <Divider sx={{ backgroundColor: '#444' }} />
  <MenuItem
    onClick={handleClose}
    sx={{
      color: '#fff',
      padding: '12px 20px',
      transition: 'background-color 0.2s ease, color 0.2s ease, transform 0.2s ease',
      '&:hover': {
        backgroundColor: '#444',
        color: '#edb038',
        // transform: 'scale(1.05)',
      },
    }}
  >
    <Avatar /> My account
  </MenuItem>
  <MenuItem
    onClick={handleClose}
    sx={{
      color: '#fff',
      padding: '12px 20px',
      transition: 'background-color 0.2s ease, color 0.2s ease, transform 0.2s ease',
      '&:hover': {
        backgroundColor: '#444',
        color: '#edb038',
        // transform: 'scale(1.05)',
      },
    }}
  >
    <ListItemIcon>
      <PersonAdd fontSize="small" sx={{ color: '#fff' }} />
    </ListItemIcon>
    Add another account
  </MenuItem>
  <MenuItem
  onClick={handleLogout}
  sx={{
    color: '#fff',
    padding: '12px 20px',
    transition: 'background-color 0.2s ease, color 0.2s ease, transform 0.2s ease',
    '&:hover': {
      backgroundColor: '#444',
      color: '#edb038',
    },
  }}
>
  <ListItemIcon>
    <Logout fontSize="small" sx={{ color: '#fff' }} />
  </ListItemIcon>
  Logout
</MenuItem>

</Menu>











          
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;