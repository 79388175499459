import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { getMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../../../../components/Navbar";
import DashboardScreen from "./DashboardScreen";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserExams } from "../../../../redux/actions/examActions";

const ExamDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.userReducer);

  const handleNavigateMCQ = () => {
    navigate("/student-test/student_mcq-test");
  };
  const handleNavigateSub = () => {
    navigate("/student-test/student_subjective-test");
  };

  const handleNavigateMega = () => {
    navigate("/student-test/student_mega-test");
  };

  // Use React Query to fetch mcq exam data
  const {
    data: mcqExamData,
    error: mcqExamError,
    isLoading: mcqExamIsLoading,
    isError: mcqExamIsError,
  } = useQuery({
    queryKey: QueryKeys.mcq_exams,
    queryFn: getMCQExams,
  });

  if (mcqExamIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (mcqExamIsError) {
    return <p>Error: {mcqExamError.message}</p>;
  }

  // Fetch today's date and time
  const now = new Date();
  const currentDate = now.toISOString().split("T")[0];
  const currentTime = now.toTimeString().split(" ")[0];

  // Filter exams for today and based on student class
  const todayTest = mcqExamData?.filter((item) => {
    return item.select_date >= currentDate;
  });

  const classTest = todayTest?.filter(
    (item) => item?.select_class == userData?.standard && item?.isApproved===true //use false in dev 
  );
  // console.log('mcqExamData', mcqExamData)
  // console.log("todayTest", todayTest);
  // console.log("classTest", classTest);

  if (classTest?.length !== 0) {
    dispatch(getUserExams());
  }

  return (
    <>
      <Navbar />
      <Box sx={{ mt: "70px", px: 2 }}>
        <BreadCrumbs
          pageName="Student"
          subPageName="Live Test"
          title="MCQ Live Test"
        />
        <Box sx={{ margin: "20px 0 30px 0" }} data-aos="zoom-in">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "center" },
            }}
          >
            <Typography variant="h5" gutterBottom>
              Upcoming Live Test
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                mt: { xs: 2, md: 0 },
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateMCQ}
                className="common-color"
              >
                MCQ Exam
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateSub}
                className="common-color"
              >
                Subjective Exam
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateMega}
                className="common-color"
              >
                Mega Exam
              </Button>
            </Box>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            
            <DashboardScreen classTest={classTest} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExamDashboard;
