import React, { useState, useEffect } from "react";
import Navbar from './Navbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Grid, Typography } from '@mui/material';
import { LiveClassesDropdown } from './ParentDropdown';
import maleTeacher from "../../images/front-view-man-classroom.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../../redux/actions/meetingActions";
import { formatDate } from "../../functions/date";

export const LiveClassParents = () => {
  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const {selectedStudent}=useSelector((store)=>store.studentReducer)

  useEffect(() => {
    if (!meetings) {
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]);

  // const meet = meetings?.data?.meeting
  //   ? [...meetings.data.meeting].reverse()
  //   : [];
  // const meeetingData = meet;
  // console.log('meet', meet)
  // const date = new Date();
  // const formattedDate = formatDate(date);
  // console.log('selectedStudent', selectedStudent)







  const meet = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];

  const meeetingData = meet.filter(
    (meeting) => meeting?.class == selectedStudent?.class
  );

  console.log('first', meeetingData)
 const date = new Date();
  const formattedDate = formatDate(date);

  

  

  return (
    <div>
      <Navbar />
      <Container maxWidth="lg" sx={{ mt: 5, overflow: 'auto' }}>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          my={4}
        >
          <Typography
            variant="h4"
            component="h2"
            color="black"
            mb={{ xs: 2, sm: 0 }}
          >
            Live class
          </Typography>
          <LiveClassesDropdown
            sx={{
              width: { xs: "100%", sm: "auto" },
              fontSize: { xs: "16px", sm: "inherit" },
            }}
          />
        </Box> */}

<Grid container spacing={4} alignItems="center" justifyContent={{ xs: 'center', md: 'space-between' }} my={4}>
  <Grid item xs={12} md="auto">
    <Typography variant="h4" component="h2" color="textPrimary" textAlign="center">
    Live class
    </Typography>
  </Grid>
  <Grid item xs={12} md="auto">
    <Box display="flex" justifyContent="center" width="100%">
      <LiveClassesDropdown fullWidth />
    </Box>
  </Grid>
</Grid>



        <div className="row">
          {meeetingData?.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={item?.id}>
              <div
                className="custom-card"
                style={{ maxHeight: "10rem", backgroundColor: 'white' }}
              >
                <div className="custom-flex">
                  <div className="custom-image">
                    <img src={maleTeacher} className="custom-img" alt="Card" />
                  </div>
                  <div className="custom-body">
                    <div className="custom-date">
                      {formattedDate} | 59mins
                    </div>
                    <h5 className="custom-title">
                      {item?.chapter?.length >= 15
                        ? item.chapter.slice(0, 18) + ".."
                        : item.chapter}{" "}
                      | {item?.subject}
                    </h5>
                    <p className="custom-text" style={{ color: 'black' }}>
                      {item?.createdBy}
                    </p>
                    <p className="custom-class" style={{ color: 'black' }}>
                      {item?.class}th
                    </p>

                  
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default LiveClassParents;
