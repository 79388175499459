//========================>persist<==============
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import userReducer from './userReducer';
import courseReducer from './courseReducer';
import boardReducer from './boardReducer';
import standardReducer from './standardReducer';
import testimonialReducer from './testimonialReducer';
import eventReducer from './eventReducer';
import workshopReducer from './workshopReducer';
import blogReducer from './blogReducer';
import meetingReducer from './meetingReducer';
import teacherReducer from './teacherReducer';
import requestReducer from './requestReducer';
import dropdownReducer from './dropdownReducer';
import parentReducer from './parentReducer';
import studentReducer from './studentReducer';
import watchVideoReducer from './watchVideoReducer';
import projectReducer from './projectReducer';
import bulkUserReducer from './bulkUserReducer';
import lectureReducer from './lectureReducer';
import examReducer from './examReducer';

// Root reducer combining all your individual reducers
const rootReducer = combineReducers({
  userReducer,
  courseReducer,
  boardReducer,
  standardReducer,
  testimonialReducer,
  eventReducer,
  workshopReducer,
  blogReducer,
  meetingReducer,
  teacherReducer,
  requestReducer,
  dropdownReducer,
  parentReducer,
  studentReducer,
  watchVideoReducer,
  projectReducer,
  bulkUserReducer,
  lectureReducer,
  examReducer
});

// Create the persist configuration
const persistConfig = {
  key: 'root', // key in storage
  storage, // defines the storage engine
  whitelist: ['studentReducer'], // Only persist selected reducers
};

// Create a persisted reducer
export default persistReducer(persistConfig, rootReducer);
