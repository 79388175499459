import React, { useState, useEffect } from "react";
import "../../styles/Dropdown.css";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";

import { BsClock } from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux'
import { getMeetings } from '../../redux/actions/meetingActions';
import {getAllCourses} from '../../redux/actions/courseActions';
import {toast } from 'react-toastify'
import Select from 'react-select'
import { getAllStandards } from '../../redux/actions/standardActions';
import config from '../../config';


const Dropdown = () => {
  const zegoMeetingLink = roomId(8);
  const [customChapter, setCustomChapter] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState({
    courseId: "",
    subjectName: "",
  });
  const [selectedUnit, setSelectedUnit] = useState("");
  //const [selectedChapter, setSelectedChapter] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { allStandards } = useSelector((store) => store.standardReducer);
  const { allCourses } = useSelector((store) => store.courseReducer);
  const { teacherData } = useSelector((store) => store.teacherReducer);
  console.log("teacherData from dropdown", teacherData);

  const teacherName = teacherData?.firstname + " " + teacherData?.lastname;
  const teacherId = teacherData?.id;

  useEffect(() => {
    if (allCourses?.length === 0 || allStandards?.length === 0) {
      dispatch(getAllCourses());
      dispatch(getAllStandards());
    }
  }, [dispatch, allCourses.length, allStandards.length]);

  function roomId(len) {
    let result = "";
    if (result) return result;
    var chars =
        "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }

  const units = ["Unit 1", "Unit 2", "Unit 3"];
  const chapters = ["Chapter 1", "Chapter 2", "Chapter 3"];

  console.log("allcourse", allCourses);
  console.log(selectedSubject);

  // const weekend = (date) => {
  //   const currentDate = new Date();
  //   return currentDate < date || currentDate.toDateString() === date.toDateString();
  // };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      fontSize: "14px",
      height: "2.3rem",
      display: "flex",
      justifyContent: "center", // Center content horizontally
      alignItems: "center",
      border: "4px solid  rgb(118, 119, 117)", // example border style
      borderRadius: "28px",
      boxShadow: "none",
      marginBottom: "0.5rem",

      "&:hover": {
        border: "4px solid  rgb(118, 119, 117)", // Disable hover effect by setting border color to transparent
      }, // Remove
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
    }),
    // Define other styles as needed
  };
  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === "custom") {
      // Handle the case when "Enter Manually" is selected

      setSelectedChapter(selectedOption); // Clear the selected chapter
      // You may want to show/hide the input field for entering a custom chapter here
    }
  };

  const handleInputChange = (newValue) => {
    setCustomChapter(newValue);
  };

  const handleCreateChapter = () => {
    if (customChapter.trim() !== "") {
      setSelectedChapter({ value: customChapter, label: customChapter });
      setCustomChapter("");
    }
  };
  const handleStartTimeChange = (time) => {
    setStartTime(time);
    // Reset end time if it's before the new start time or if the difference is more than 4 hours
    if (
      endTime &&
      time &&
      (endTime < time ||
        endTime.getTime() - time.getTime() > 4 * 60 * 60 * 1000)
    ) {
      setEndTime(null);
    }
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
    // Reset start time if it's after the new end time or if the difference is more than 4 hours
    if (
      startTime &&
      time &&
      (startTime > time ||
        time.getTime() - startTime.getTime() > 4 * 60 * 60 * 1000)
    ) {
      setStartTime(null);
    }
  };

  const futureDates = (date) => {
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 20); // Adjusted to 20 to start counting from today

    return (
      (date >= currentDate && date <= futureDate) ||
      currentDate.toDateString() === date.toDateString()
    );
  };

  const handleCreateClass = async () => {
    if (
      !selectedClass ||
      !selectedSubject ||
      !selectedUnit ||
      !selectedChapter ||
      !date ||
      !startTime ||
      !endTime
    ) {
      setErrorMessage("Please fill out all fields");
      return;
    }
    setLoading(true);
    try {
      const classData = {
        teacherId: teacherId,
        class: selectedClass,
        topic: selectedSubject?.subjectName,
        unit: selectedUnit,
        agenda: selectedChapter ? selectedChapter.value : selectedChapter,
        start_time: startTime,
        endTime: endTime,
        date: date,
        createdBy: teacherName,
        password: zegoMeetingLink,
        type: 2,
        timezone: "UTC",
        duration: 120,
        subject: selectedSubject?.subjectName,
        courseId: selectedSubject?.courseId,
        chapter: selectedChapter,
      };

      console.log("classData", classData);

      // Replace 'YOUR_BACKEND_API_URL' with your actual backend API endpoint
      const response = await axios.post(
        `${config.BASE_URL}/api/meeting/createMeeting`,
        classData
      );

      if (response.status === 200) {
        // Optionally reset the form or clear the selected values after successful creation
        setSelectedClass("");
        setSelectedSubject({ courseId: "", subjectName: "" })
        setSelectedUnit("");
        setSelectedChapter("");
        setDate("");
        setStartTime("");
        setEndTime("");
        setErrorMessage("");
        dispatch(getMeetings());
        toast.success("Class created successfully");
      } else {
        setErrorMessage("Error creating class");
      }
    } catch (error) {
      console.error("Error creating class:", error);
      setErrorMessage(error.message || "Something went wrong");
    } finally {
      setLoading(false); // Set loading state to false after API call completes
    }
  };

  return (
    <div className="container mt-4">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="row">
        {/* First Row */}
        <div className="col-md-6">
          <div className="dropdown-item">
            <select
              className="form-select"
              aria-label="Select Class"
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option selected>Select Class</option>
              {allStandards?.map((item, index) => (
                <option key={index} value={item.class}>
                  {item.class}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="col-md-6 ml-0">
          <div className="dropdown-item">
            <select
              className="form-select"
              aria-label="Select Subject"
              onChange={(e) => setSelectedSubject({courseId:e.target.value
                
              })}
            >
              <option selected>Select Subject</option>
              {allCourses.map((item, index) => (
                <option key={index} value={item.subject}>
                  {item.subject}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        <div className="col-md-6 ml-0">
          <div className="dropdown-item">
            <select
              className="form-select"
              aria-label="Select Subject"
              onChange={(e) => {
                const selectedItem = allCourses.find(
                  (item) => item.id == e.target.value
                ); // Find selected item
                console.log("selectedItem", selectedItem);
                setSelectedSubject({
                  courseId: selectedItem?.id, // Use the selected item's id for courseId
                  subjectName: selectedItem?.subject, // Use the selected item's subject for subjectName
                });
              }}
            >
              <option selected>Select Subject</option>
              {allCourses.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.subject}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className="row">
        <div className="col-md-6 ml-3">
          <div className="dropdown-item">
            <select
              className="form-select"
              aria-label="Select Unit"
              onChange={(e) => setSelectedUnit(e.target.value)}
            >
              <option selected>Select Unit</option>
              {units.map((unit, index) => (
                <option key={index} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="col-md-6 ml-3">
      <div className="dropdown-item">
        <select className="form-select" aria-label="Select Chapter" onChange={(e) => setSelectedChapter(e.target.value)}>
          <option selected>Select Chapter</option>
          {chapters.map((chapter, index) => (
            <option key={index} value={chapter}>
              {chapter}
            </option>
          ))}
        </select>
      </div>
    </div> */}

        <div className="col-md-6 ml-3">
          <div className="dropdown-item">
            <Select
              value={selectedChapter}
              onChange={handleChange}
              options={[
                { value: "custom", label: "Enter Manually" },
                ...chapters.map((chapter) => ({
                  value: chapter,
                  label: chapter,
                })),
              ]}
              placeholder="Select Chapter"
              isSearchable
              styles={customStyles}
              onInputChange={handleInputChange}
            />
            {selectedChapter && selectedChapter.value === "custom" && (
              <div>
                <input
                  type="text"
                  value={customChapter}
                  onChange={(e) => setCustomChapter(e.target.value)}
                  className="form-control mt-2"
                  placeholder="Enter Chapter Name"
                />
                <button
                  onClick={handleCreateChapter}
                  className="btn btn-primary mt-2"
                >
                  Add Chapter
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Fourth Row */}
      <div className="row ">
        {" "}
        {/* Added gx-3 for gutter space between columns */}
        <div className="col-lg-4 col-md-2">
          {" "}
          {/* Adjusted column width to fit on all screen sizes */}
          <div className="dropdown-item-date">
            <DatePicker
              className="form-select-three"
              placeholderText="Select Date"
              dateFormat="dd-MM-yyyy"
              filterDate={futureDates}
              selected={date}
              onChange={(date) => setDate(date)}
              showIcon={true}
              calendarClassName="custom-calendar"
              icon={
                <SlCalender
                  className="custom-icon"
                  onClick={() =>
                    document.querySelector(".form-select-three").click()
                  }
                />
              }
              required
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-2">
          {" "}
          {/* Adjusted column width to fit on all screen sizes */}
          <div className="dropdown-item-date">
            <DatePicker
              className="form-select-three"
              placeholderText="Start time"
              showTimeSelectOnly
              showTimeInput
              dateFormat="h:mm aa"
              selected={startTime}
              onChange={handleStartTimeChange}
              showIcon
              icon={<BsClock className="custom-icon" />}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-2">
          {" "}
          {/* Adjusted column width to fit on all screen sizes */}
          <DatePicker
            className="form-select-three"
            timeFormat="hh:mm aa"
            placeholderText="End time"
            showTimeSelectOnly
            showTimeInput
            dateFormat="h:mm aa"
            selected={endTime}
            onChange={handleEndTimeChange}
            minTime={startTime}
            showIcon
            icon={<BsClock className="custom-icon" />}
          />
        </div>
      </div>

      {/* <button className="schedule-btn" onClick={handleCreateClass}>Create Class</button> */}

      {loading ? (
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      ) : (
        <button className="schedule-btn" onClick={handleCreateClass}>
          Create Class
        </button>
      )}
    </div>
  );
};
export default Dropdown;
