import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import closeIcon from '../images/ic_close.svg';
import prevLightIcon from '../images/ic_arrow_left_white.svg';
import prevDarkIcon from '../images/ic_arrow_left_gray.svg';
import nextLightIcon from '../images/ic_arrow_right_white.svg';
import nextDarkIcon from '../images/ic_arrow_right_gray.svg';
import escLightIcon from '../images/ic_esc_white.svg';
import escDarkIcon from '../images/ic_esc_gray.svg';
import errorImage from '../images/error-img2.jpg'; 
import '../styles/ReadWatchModal.css';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';
import { getWatchVideo } from '../redux/actions/watchVideoActions';

const ReadWatchModal = ({ type, handleClose, chapter }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [watchedPercentage, setWatchedPercentage] = useState(0);
  const [videoError, setVideoError] = useState(false); 
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const { isAuthenticated, userData } = useSelector((store) => store.userReducer);
  const { videoData } = useSelector((store) => store.watchVideoReducer);

  useEffect(() => {
    const noContext = document.getElementById('noContextMenu');
    if (noContext) {
      const preventContextMenu = (e) => e.preventDefault();
      noContext.addEventListener('contextmenu', preventContextMenu);
      return () => {
        noContext.removeEventListener('contextmenu', preventContextMenu);
      };
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (isAuthenticated) {
      intervalId = setInterval(() => {
        dispatch(getWatchVideo(userData.id, chapter.courseId, chapter.id));
        const watchedPercentageData = parseFloat(videoData?.data[0]?.watchedPercent || 0);
        setWatchedPercentage(watchedPercentageData);
        if (watchedPercentage > 0 && watchedPercentage < 100) {
          if (!watchedPercentageData || watchedPercentage > watchedPercentageData) {
            axios.post(`${config.BASE_URL}/api/user/watch-video`, {
              userId: userData.id.toString(),
              subjects: [
                {
                  subjectId: chapter.courseId.toString(),
                  chapters: [
                    {
                      chapterId: chapter.id.toString(),
                      percentage: watchedPercentage.toFixed(2),
                    },
                  ],
                },
              ],
            })
            .catch((error) => console.error('Error updating progress:', error));
          }
        }
      }, 10000); 
    }
    return () => clearInterval(intervalId);
  }, [watchedPercentage, videoData, userData]);

  const handleProgressUpdate = (percentage) => {
    if (!isAuthenticated || watchedPercentage >= 100) return;
    setWatchedPercentage(parseFloat(percentage));
  };

  return (
    <div className="ReadWatchModal">
      <div className={`ReadWatchModal-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <span onClick={handleClose} role="button" tabIndex={0} aria-label="Close">
          <img src={closeIcon} alt="Close icon" className="ReadWatchModal-close-icon" />
        </span>
        <div className="ReadWatchModal-title-container">
          <h1>{chapter.name}</h1>
        </div>
        <p className="ReadWatchModal-notes-text">
          {type === 'notes' ? 'Notes' : 'Video lecture'}
        </p>
        {type === 'notes' ? (
          <div className={`ReadWatchModal-notes-container ${isDarkMode ? 'dark-mode' : ''}`}>
            <iframe
              id="noContextMenu"
              className="notes-frame"
              src={`${chapter?.notes}#toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              scrolling="auto"
              frameBorder="0"
              height="100%"
              width="100%"
              title="Notes"
              aria-label="Notes content"
            ></iframe>
          </div>
        ) : (
          <div className={`ReadWatchModal-lecture-container ${isDarkMode ? 'dark-mode' : ''}`}>
            {videoError ? (
              <div className="error-image-container" style={{ marginTop: '10px' }}>
                <img src={errorImage} alt="Error loading video" className="error-image" />
              </div>
            ) : (
              <video
                id="noContextMenu"
                controls
                controlsList="nodownload"
                className="video-frame"
                ref={videoRef}
                onLoadedMetadata={() => {
                  if (videoRef.current && watchedPercentage > 0) {
                    videoRef.current.currentTime =
                      (watchedPercentage / 100) * videoRef.current.duration;
                  }
                }}
                onTimeUpdate={(e) => {
                  const { currentTime, duration } = e.target;
                  if (duration > 0) {
                    const percentage = (currentTime / duration) * 100;
                    handleProgressUpdate(percentage.toFixed(2));
                  }
                }}
                onError={() => setVideoError(true)} // Set error state if video fails to load
              >
                <source src={chapter.url} type="video/mp4" />
              </video>
            )}
          </div>
        )}
        <div className="ReadWatchModal-options-container">
          <div className="ReadWatchModal-toggle-div">
            <p className={`toggle-switch-label ${isDarkMode ? 'dark-mode' : ''}`}>Dark mode</p>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isDarkMode}
                onChange={() => setIsDarkMode(!isDarkMode)}
              />
              <span className="switch" />
            </label>
          </div>
          <div className={`ReadWatchModal-close-div ${isDarkMode ? 'dark-mode' : ''}`}>
            <img
              src={isDarkMode ? escLightIcon : escDarkIcon}
              alt="Esc icon"
              className="esc-icon"
            />
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadWatchModal;
