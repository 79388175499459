import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { QueryKeys } from "../../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import TeacherNavbar from "../../../../../components/Teacher/TeacherNavbar";
import AddExamModal from "../../../../../components/addExamModal/AddExamModal";
import SubTableMarks from "./SubTableMarks";
import { useLocation } from "react-router-dom";

const SubExamAttendByStu = () => {
  const location = useLocation();
  const examData = location?.state?.data;
  const studentData = location?.state?.studentDetails;
  console.log("eee", examData);
  console.log("sss", studentData);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="Subjective"
          title="Subjective Exam Attend By Students"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <SubTableMarks
              isEditOpen={isEditOpen}
              setIsEditOpen={setIsEditOpen}
              studentData={studentData}
              examData={examData}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SubExamAttendByStu;
