import axios from "axios";
import Cookies from "js-cookie";
import config from "../../../config";

// for live server
// const API = axios.create({
//   baseURL: 'https://api.enroutingcareers.com/',
// });

//for dev server


// const API = axios.create({
//     baseURL: 'http://localhost:8080',
//   });


const API = axios.create({
   baseURL: config.BASE_URL,
 
});



API.interceptors.request.use((req) => {
  const userJWT = Cookies.get('userJWT');
  const parentJWT = Cookies.get('parentJWT');
  const teacherJWT = Cookies.get('teacherJWT');

  // Prioritize tokens based on your requirements
  if (userJWT) {
    req.headers['x-access-token'] = userJWT;
  } else if (parentJWT) {
    req.headers['x-access-token'] = parentJWT;
  } else if (teacherJWT) {
    req.headers['x-access-token'] = teacherJWT;
  }

  return req;
}, (error) => {
  return Promise.reject(error);
});



API.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Network or server-side error handling
    if (!error.response) {
      // If there's no response from the server (likely a network issue)
      alert('Network error. Please check your internet connection.');
    } else if (error.response.status === 500) {
      // Handle server errors (like 500 status code)
      alert('Server error. Please try again later.');
    }

    // Return the error for further handling in the component if needed
    return Promise.reject(error);
  }
);



const configMultipart = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// User Routes
export const getUser = () => API.get("/api/user/profile/get");
export const login = (loginData) => API.post("/api/auth/signin", loginData);
export const signup = (signupData) => API.post("/api/auth/signup", signupData);
export const updateUserDetails = (updates) =>
  API.put("/api/user/update", updates);



// Forgot password
export const forgotPasswordEmail = (formData) =>
  API.post("/api/auth/forgotpassword", formData);

// Reset password
export const resetPassword = (formData) =>
  API.post("/api/auth/newpassword", formData);

// Courses Routes
export const getAllCourses = () => API.get("/admin/courses/all");
export const getUserCourses = () => API.get("/api/courses/self");
export const getUserChapters = () => API.get("/api/chapters/self");


// Board Routes
export const getAllBoards = () => API.get("/admin/boards/all");

// Standard Routes
export const getAllStandards = () => API.get("/admin/standards/all");

// Testimonial Routes
export const getAllTestimonials = () => API.get("/api/testimonials");

// Event Routes
export const getAllEvents = () => API.get("/api/events/all");
export const getUserEvents = () => API.get("/api/events/self");
export const makeSubmission = (id, formData) =>
  API.post(`/api/submit/${id}`, formData);
export const editSubmission = (id, formData) =>
  API.post(`/api/submit/edit/${id}`, formData);

export const checkSubmission = (eventId) =>
  API.get(`/api/user/submission/${eventId}`);

// Workshop Routes
export const getAllWorkshops = () => API.get("/api/workshops/all");
export const getUserWorkshops = () => API.get("/api/workshops/self");

// Blog Routes
export const getAllBlogs = () => API.get("/api/blogs/all");

// Help email Routes
export const helpMail = (formData) => API.post("/api/connect", formData);

// Payment Routes
export const buyCourse = (id, formData) =>
  API.post(`/api/course/purchase/${id}`, formData);
export const buyEvent = (id, formData) =>
  API.post(`/api/event/purchase/${id}`, formData);
export const buyWorkshop = (id) => API.post(`/api/workshop/purchase/${id}`);
export const verifyCoursePayment = (paymentData) =>
  API.post("/course/verify-payment", paymentData);
export const verifyEventPayment = (paymentData) =>
  API.post("/event/verify-payment", paymentData);
export const verifyWorkshopPayment = (paymentData) =>
  API.post("/workshop/verify-payment", paymentData);

export const buyProject = (id) => API.post(`/users/project/purchase/${id}`);
export const verifyProjectPayment = (paymentData) =>
  API.post("/users/project/enroll", paymentData);

export const verifyRegistrationPayment=(paymentData) =>API.post("/registration-fees/verify-payment", paymentData);

export const purchaseRegistration=(id, formData) => API.post(`/api/registration-fees/purchase/${id}`, formData);




export const buyChapter = (id, formData) =>
  API.post(`/api/chapter/purchase/${id}`, formData);

export const verifyChapterPayment = (paymentData) =>
  API.post("/chapter/verify-payment", paymentData);
















export const teacher_login = (teacherloginData) =>
  API.post("/api/teacher/signin", teacherloginData);
export const getTeacher = () => API.get("/api/teacher/profile/get");

export const updateTeacherDetails = (updates) =>API.put("/api/user/update", updates);

//meeting

export const getAllMeetings = () => API.get("/api/meeting/getMeeting");
export const studentRequests = (formData) =>
  API.post("api/user/createStudentQuery", formData);

export const getLectureReq = (teacherId) =>
  API.put(`/api/user/getStudentQueryData?teacherId=${teacherId}`);

export const getAllSubjects = () => API.get("/admin/courses/all");




export const parents_login = (parentLoginData) => API.post("/api/auth/parentSignin", parentLoginData);
export const parent_signup=(signupData)=>API.post("/api/auth/parentSignup",signupData);
export const getParent = () => API.get("/api/parent/profile/get");


export const fetchAllExams=()=>API.get("/api/exam/findAll")



  export const getAttendance = (userId, examId) => API.get("/api/userAttendExam/filter", {params: {
        userId,
        examId
      }
    });


  export const getRankings=(examId)=>API.get(`/api/userAttendExam/attended-users/${examId}`)

  console.log("login ========================>        ", login);
  export const getAllWatchVideo=(userId,subjectId,chapterId)=>API.get(`/api/video/progress`,{
    params:{
      userId,
        subjectId,
        chapterId
    }
  })

  export const getAllProjects=()=>API.get('/admin/project/all');
  export const updateApproval=(id,approval)=>API.patch(`/teacher/project/${id}`,approval)


  export const userProjects=()=>API.get('/users/project/userproject');
  export const makeProjectSubmission=(id, formData)=>API.post(`/api/project/submit/${id}`,formData)
  
  export const checkProjectSubmission=(projectId)=>API.get(`/api/user/project/submission/${projectId}`);
  export const editProjectSubmission=(projectId,formData)=>API.post(`/users/project/userproject/submission/edit/${projectId}`,formData)



  export const getAllPendingUsers=()=>API.get('/api/teacher/users/pending');
  export const getAllUsersByTeacher=()=>API.get('/api/teacher/users/created');
  export const createSingleUserByTeacher=(formData)=>API.post("/app/teacher/user/create",formData)
  export const createBulkUser=(formData)=>API.post("/api/teacher/bulk_users",formData)

  


  export const getLecturePrice=(id)=>API.get(`/user/lectures/${id}`);
  export const purchaseLive=(formData)=>API.post(`/api/live-classes/purchase`,formData);
  export const verifyLivePayment=(paymentData)=>API.post('/api/live-classes/verify-payment',paymentData)


  export const getUserLecturesbyYear=()=>API.get('/api/liveLectures/selfClasses')

  
  export const getUserAllLectures=()=>API.get('/api/live-class/selfLiveClasses')


  export const purchaseExam=(formData)=>API.post('/user/exam/purchase',formData);
  export const verifyExam=(paymentData)=>API.post('/user/exam/enroll',paymentData);
  export const getUserExams=()=>API.get('/user/exam/all');



  export const userPoints=()=>API.get('/api/users/points');


  export const updateUserStatus=(id, status)=>API.patch(`/app/teacher/status/${id}`, status)
  export const userRegisterFees=()=>API.get('/user/register/fees')




