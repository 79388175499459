import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { userSubjectiveCol } from "../../../../data/mockData";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";

const UserMegaTestTable = ({ data }) => {
  const tokenString = Cookies.get("userJWT");
  let token;
  if (tokenString) {
    try {
      token = jwtDecode(tokenString);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [examData, setExamData] = useState([]);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedResultData, setSelectedResultData] = useState(null);

  const isMobile = useMediaQuery("(max-width:900px)"); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = data?.filter((item) =>
    item.select_subject.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  const paginatedData = filteredData?.slice(startIndex, endIndex);
  const totalLength = filteredData?.length;

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const examIds = data.map((item) => item.id);
        const examDataPromises = examIds.map((id) =>
          axios.get(`https://api.enroutingcareers.com/api/userAttendExam/${id}/attended-users`)
        );
        const examDataResponses = await Promise.all(examDataPromises);
        const examsData = examDataResponses.map((response) => response.data.data);
        setExamData(examsData.flat());
      } catch (error) {
        console.error("Failed to fetch exam data:", error);
      }
    };

    fetchExamData();
  }, [data]);

  const handleExamData = (item) => {
    setSelectedFormData(item);
    setIsEditOpen(true);
  };

  const handleCheckResult = (item) => {
    setSelectedResultData(item);
    setIsResultOpen(true);
  };

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      {isMobile ? (
        // Mobile view
        <Box sx={{ p: 2 }}>
          {paginatedData?.map((item, index) => {
            const userExamData = examData?.find((exam) => exam?.userId === token?.id);
            return (
              <Box
                key={item.id}
                sx={{
                  border: '1px solid black',
                  borderRadius: 1,
                  mb: 2,
                  p: 2,
                }}
              >
                <Typography variant="h6">{item.examName}</Typography>
                <Typography>Subject: {item.select_subject}</Typography>
                <Typography>Chapter: {item.select_chapter}</Typography>
                <Typography>Total Marks: {item.total_marks}</Typography>
                <Typography>Date: {item.select_date}</Typography>
                <Typography>Start Time: {item.start_time}</Typography>
                <Typography>End Time: {item.end_time}</Typography>
                <Button
                  size="small"
                  variant="contained"
                  disabled={userExamData && !userExamData?.attendedBy}
                  onClick={() => !userExamData?.attendedBy && handleExamData(item)}
                  sx={{ mt: 1 }}
                >
                  {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  disabled={userExamData && userExamData?.obtainedMarks}
                  onClick={() => userExamData?.obtainedMarks && handleCheckResult(userExamData)}
                  sx={{ mt: 1, ml: 1 }}
                >
                  {userExamData?.attendedBy ? "Check Result" : "Not Check"}
                </Button>
              </Box>
            );
          })}
        </Box>
      ) : (
        // Desktop view
        <Table>
          <TableHead>
            <TableRow>
              {userSubjectiveCol?.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    borderColor: "black",
                    backgroundColor: "black",
                    borderWidth: 1,
                    borderStyle: "solid",
                    color: "white",
                  }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((item) => {
              const userExamData = examData?.find((exam) => exam?.userId === token?.id);
              return (
                <TableRow key={item.id}>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.examName}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.select_subject}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.select_chapter}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.total_marks}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.select_date}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.start_time}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    {item.end_time}
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                    <a href={item?.questionPaperPath} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                      View Questions
                    </a>
                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                  <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && !userExamData?.attendedBy}
                      sx={{
                        opacity: userExamData?.attendedBy ? 0.5 : 1,
                      }}
                      onClick={() => !userExamData?.attendedBy && handleExamData(item)}
                    >
                      {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                    </Button>


                    {/* <Button
                  size="small"
                  variant="contained"
                  disabled={userExamData && !userExamData?.attendedBy}
                  onClick={() => !userExamData?.attendedBy && handleExamData(item)}
                  sx={{ mt: 1 }}
                >
                  {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                </Button> */}


                  </TableCell>
                  <TableCell sx={{ borderColor: "black", borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>
                  <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && userExamData?.obtainedMarks}
                      sx={{
                        opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                      }}
                    >
                      {userExamData?.attendedBy ? "Check Result" : "Not Check"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {/* Pagination and other controls */}
      <Box sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography>Total exams: {totalLength}</Typography>
        {/* Add TablePagination here if needed for desktop view */}
      </Box>
      {isEditOpen && (
        <AddExamModal
          formData={selectedFormData}
          open={isEditOpen}
          formName="Upload Mega Test Answer"
          onClose={() => setIsEditOpen(false)}
        />
      )}
      {isResultOpen && (
        <AddExamModal
          formData={selectedResultData}
          open={isResultOpen}
          formName="Result Check"
          onClose={() => setIsResultOpen(false)}
        />
      )}
    </TableContainer>
  );
};

export default UserMegaTestTable;
