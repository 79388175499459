import { useFormik } from "formik";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { InputForm } from "../../../../components/inputField/InputForm";
import NormalSelector from "../../../../components/dropdownSelector/NormalSelector";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import * as Yup from "yup";
import FormButton from "../../../../components/FormButton/FormButton";
import { Slide, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format, isBefore } from "date-fns";
import { createMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-datepicker";
import { BsClock } from "react-icons/bs";
import { createSubMegaExams } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";

const initialValues = {
  testType: "mega_test",
  select_class: "",
  questionPaper: "",
  ansKey: "",
  examName: "",
  select_subject: "",
  select_chapter: "",
  total_marks: "",
  select_date: "",
  start_time: null,
  end_time: null,
};

const MegaTestForm = () => {
  const queryClient = useQueryClient();

  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [filteredChapters, setFilteredChapters] = useState([]);

  const handleStartTimeChange = (time) => {
    setFieldValue("start_time", time);
  };

  const handleEndTimeChange = (time) => {
    setFieldValue("end_time", time);
  };

  // post the data
  const createPostMutation = useMutation({
    mutationFn: createSubMegaExams,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.subjective_mega_exam,
      });
      toast.success("Exams added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    },
    onError: (error) => {
      toast.error("An error occurred while adding the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const onSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        start_time: format(values.start_time, "HH:mm:ss"),
        end_time: format(values.end_time, "HH:mm:ss"),
      };

      const formData = new FormData();
      formData.append("testType", "mega_test");
      formData.append("select_class", values.select_class);
      formData.append("questionPaper", values.questionPaper);
      formData.append("ansKey", values.ansKey);
      formData.append("select_subject", values.select_subject);
      formData.append("select_chapter", values.select_chapter);
      formData.append("total_marks", values.total_marks);
      formData.append("select_date", values.select_date);
      formData.append("start_time", formattedValues.start_time);
      formData.append("end_time", formattedValues.end_time);
      formData.append("examName", formattedValues.examName);
      formData.append("courseId",values.courseId)

      await createPostMutation.mutate(formData);
    } catch (error) {
      console.error(error.message || "An error occurred");
      toast.error("An error occurred while adding the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    }
  };

  const validationSchema = Yup.object({
    select_class: Yup.string().required("Please select class"),
    questionPaper: Yup.string().required("Please upload question paper pdf"),
    ansKey: Yup.string().required("Please upload answer key pdf"),
    select_subject: Yup.string().required("Please select subject"),
    select_chapter: Yup.string().required("Please select chapter"),
    total_marks: Yup.string().required("Please enter total marks"),
    select_date: Yup.string().required("Please select select date"),
    start_time: Yup.string().required("Please select start time"),
    end_time: Yup.string().required("Please select end time"),
    examName: Yup.string().required("Please enter exam name"),
  });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  });

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
    handleChange,
  } = formik;

  //fetch data from api
  const isDropdownSelected =
    values.select_chapter && filteredChapters.includes(values.select_chapter);

  const { allStandards } = useSelector((store) => store.standardReducer);

  const classData = allStandards?.map((item, i) => ({
    value: item.class,
    name: item.class,
  }));

  // ----------------------------class data end-----------------------------------------------------

  const { allCourses } = useSelector((store) => store.courseReducer);

  // ---------------------------------course data end-----------------------------------------------------

  // Update filtered subject when class change
  useEffect(() => {
    if (values.select_class) {
      const subjectsFilter = allCourses?.filter(
        (subject) => subject.class == values.select_class
      );
      setFilteredSubjects(subjectsFilter);
    }
  }, [values.select_class, allCourses]);

  // Update filtered chapters when subject changes
  useEffect(() => {
    if (values.select_subject) {
      const chaptersForSubject = allCourses?.filter(
        (chapter) => chapter.subject == values.select_subject
      );
      const currentChapters = chaptersForSubject?.flatMap(
        (item) => item?.chapters
      );
      console.log("currentChapters", currentChapters);
      const chapterData = currentChapters?.map((item) => item?.name);
      console.log("chapterData", chapterData);
      setFilteredChapters(chapterData);
    }
  }, [values.select_subject, allCourses]);

  const type = "date";
  const today = new Date().toISOString().split("T")[0]; // Get current date in "YYYY-MM-DD" format

  const handleReset = () => {
    resetForm();
    setSelected("");
    setSelectedAns("");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selected, setSelected] = useState("");
  const [selectedAns, setSelectedAns] = useState("");
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelected(file?.name?.slice(0, 10));
    setFieldValue("questionPaper", file);
  };

  const handleAnsKeyUpload = (e) => {
    const file = e.target.files[0];
    setSelectedAns(file?.name?.slice(0, 10));
    setFieldValue("ansKey", file);
  };

  console.log("valuesSubjective", values);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
          width: { lg: "350px", md: "300px", sm: "300px", xs: "300px" },
          mt: 3,
        }}
      >
        {/* row 1 testType and examName */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          {/* <InputForm
            type="text"
            formik={formik}
            name="testType"
            // placeholder="Enter Exam"
          /> */}
          <InputForm
            type="text"
            formik={formik}
            name="examName"
            placeholder="Enter Exam"
          />
        </Box>

        {/* row 2 class and subject */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          <NormalSelector
            name="select_class"
            id="select_class"
            label="Select Class"
            options={classData}
            value={values.select_class}
            onChange={(e) => {
              handleChange(e);
              setFilteredSubjects([]); // Clear filtered subjects when class changes
            }}
            onBlur={handleBlur}
            MenuProps={MenuProps}
            isDangerousHTML={true}
            error={touched.select_class && Boolean(errors.select_class)}
            helperText={touched.select_class && errors.select_class}
          />
          <NormalSelector
            name="select_subject"
            id="select_subject"
            label="Select Subject"
            options={filteredSubjects?.map((item) => ({
              value: item?.subject,
              name: item?.subject,
            }))}
            value={values.select_subject}
            onChange={(e) => {
              handleChange(e); // Keep formik's handleChange
          
              // Find the selected subject
              const selectedSubject = filteredSubjects?.find(
                (item) => item.subject === e.target.value
              );
          
              // Update both select_subject and courseId in formik's state
              // setFieldValue("select_subject", selectedSubject?.subject || "");
              setFieldValue("courseId", selectedSubject?.id || ""); // Assuming courseId is in the selected subject
            }}

            onBlur={handleBlur}
            MenuProps={MenuProps}
            error={touched.select_subject && Boolean(errors.select_subject)}
            helperText={touched.select_subject && errors.select_subject}
          />
        </Box>

        {/* row 2 chapter and manually chapter */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          <NormalSelector
            name="select_chapter"
            id="select_chapter"
            label="Select Chapter"
            options={filteredChapters?.map((item) => ({
              value: item,
              name: item,
            }))}
            value={values.select_chapter}
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={MenuProps}
            error={touched.select_chapter && Boolean(errors.select_chapter)}
            helperText={touched.select_chapter && errors.select_chapter}
            disabled={Boolean(
              values.select_chapter &&
                !filteredChapters.includes(values.select_chapter)
            )}
          />

          <InputForm
            type="text"
            formik={formik}
            name="select_chapter"
            disabled={isDropdownSelected}
            placeholder="Write chapter manually"
          />
        </Box>

        {/* date  */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            width: "100%",
            gap: "10px",
          }}
        >
          <>
            <FormControl fullWidth>
              <TextField
                size="small"
                type="date"
                id="outlined-basic"
                label="Select Date"
                name="select_date"
                placeholder="Select date"
                value={values.select_date}
                variant="outlined"
                onChange={formik.handleChange}
                InputProps={
                  type === "date"
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <span role="img" aria-label="calendar">
                              📅
                            </span>
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
                inputProps={
                  type === "date"
                    ? {
                        min: today,
                      }
                    : {} // Ensure inputProps is defined even if empty for other input types
                }
              />
              {/* Helper Text */}
              <FormHelperText
                style={{
                  color: "red",
                  margin: "2px 0 0 5px",
                  fontSize: ".7rem",
                }}
              >
                {formik.touched["select_date"] && formik.errors["select_date"]}
              </FormHelperText>
              <FormHelperText
                style={{
                  color: "red",
                  margin: "2px 0 0 5px",
                  fontSize: ".7rem",
                }}
              >
                {formik.touched[values.select_date] &&
                  formik.errors[values.select_date]}
              </FormHelperText>
            </FormControl>
          </>
          <InputForm
            type="text"
            formik={formik}
            name="total_marks"
            placeholder="Enter Total Marks"
          />
        </Box>

        {/* startTime, endTime */}
        <Box
          sx={{
            display: "flex",
            alignItems: {
              lg: "flex-start",
              md: "flex-start",
              sm: "flex-start",
              xs: "flex-start",
            },
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            mb: 1,
            width: "100%",
          }}
        >
          <DatePicker
            className="form-select-three"
            placeholderText="Start Time"
            showTimeSelectOnly
            showTimeInput
            dateFormat="h:mm aa"
            selected={values.start_time}
            onChange={handleStartTimeChange}
            showIcon
            icon={<BsClock className="custom-icon" />}
          />

          <DatePicker
            className="form-select-three"
            timeFormat="hh:mm aa"
            placeholderText="End Time"
            showTimeSelectOnly
            showTimeInput
            dateFormat="h:mm aa"
            selected={values.end_time}
            onChange={handleEndTimeChange}
            minTime={values.start_time}
            showIcon
            icon={<BsClock className="custom-icon" />}
          />
        </Box>

        {/* row 2 ansKey, question paper*/}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          {/* question paper */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              size="small"
              fullWidth
              variant="contained"
              className="pdf-button"
              component="label"
            >
              <CloudUploadIcon /> &nbsp; Question
              <input
                type="file"
                name="questionPaper"
                id="questionPaper"
                accept="application/pdf"
                hidden
                onChange={(e) => handleFileUpload(e)}
              />
            </Button>
            <Typography>
              {selected.length > 0 && `Selected : ${selected}`}
            </Typography>
            {touched.questionPaper && errors.questionPaper && (
              <FormHelperText error>{errors.questionPaper}</FormHelperText>
            )}
          </Box>

          {/* ans key  */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              size="small"
              fullWidth
              variant="contained"
              className="pdf-button"
              component="label"
            >
              <CloudUploadIcon /> &nbsp; Ans key
              <input
                type="file"
                name="ansKey"
                id="ansKey"
                accept="application/pdf"
                hidden
                onChange={(e) => handleAnsKeyUpload(e)}
              />
            </Button>
            {selectedAns.length > 0 && `Selected : ${selectedAns}`}
            {touched.ansKey && errors.ansKey && (
              <FormHelperText error>{errors.ansKey}</FormHelperText>
            )}
          </Box>
        </Box>

        {/* submit button */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            gap: "10px",
          }}
        >
          <FormButton handleSubmit={handleSubmit} handleReset={handleReset} />
        </Box>
      </Box>
    </>
  );
};

export default MegaTestForm;
