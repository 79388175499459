import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/Projectpage.css";
import "../styles/ProjectCards.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects, userProjects } from "../redux/actions/projectActions";
import ProjectCard from "./ProjectCard";


const ProjectPage = () => {
  const dispatch = useDispatch();
  const { projectData,userProject } = useSelector((store) => store.projectReducer);
  const { isAuthenticated, userData } = useSelector((store) => store.userReducer);
  useEffect(() => {
    dispatch(getAllProjects());
    
    dispatch(userProjects());
  }, []);

  // Filter projects by the user's standard
  const projectsbyStandard = projectData?.filter((item) => item.standard == userData?.standard);

  // Further filter projects by approval status
  const Data = projectsbyStandard?.filter((project) => project?.approval === 1);


  // console.log("Data", Data);

   //console.log('userProject', userProject)

  const isProjectPurchased=(item)=>{

    if (!userProject) {
      return false;
    } else {
      return userProject.some((e) => e.projectId === item.id);
    }
        
  } 
  
  return (
    <div className="ProjectPage">
      <Navbar activeMenuItem={"Project"} />
      <div className="ProjectPage-title-container">
        <h1>Projects </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta culpa
          dolor asperiores doloribus temporibus aut ipsam, corrupti adipisci
          neque impedit, laudantium nostrum pariatur non consectetur labore quis
          doloremque sit tempore.
        </p>
      </div>
      <div className="events-card-container">
        {Data?.map((item, index) => (
          <ProjectCard
            item={item}
            key={index}
            locked={!isAuthenticated || !isProjectPurchased(item)}
            isAuthenticated={isAuthenticated}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ProjectPage;
