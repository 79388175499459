import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Container, Box, Typography, useMediaQuery, Grid } from "@mui/material";
import TableRank from "./Table";
import { RankingDropDown } from "./ParentDropdown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { userRankings } from "../../redux/actions/getStudentActions";

export const Ranking = () => {
  // Create a theme instance to access breakpoints
  const theme = createTheme();
  const dispatch = useDispatch();

  // Detect if the current screen width is below the 'sm' breakpoint (mobile devices)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <div className="slate">
        <Navbar />
       
        <Container sx={{ mt: 10 }}>
          {/* Flex container to hold the title and dropdown */}
        

          <Grid container spacing={4} alignItems="center" justifyContent={{ xs: 'center', md: 'space-between' }} my={4}>
<Grid item xs={12} md="auto">
  <Typography variant="h4" component="h2" color="textPrimary" textAlign="center">
    Ranking
  </Typography>
</Grid>
<Grid item xs={12} md="auto">
  <Box display="flex" justifyContent="center" width="100%">
    <RankingDropDown fullWidth />
  </Box>
</Grid>
</Grid>

          {/* TableRank component */}
          <TableRank />
        </Container>
      </div>
    </ThemeProvider>
  );
};
