import React, { useState } from 'react';
import ReadWatchModal from './ReadWatchModal';
import UnlockChapterModal from './UnlockChapterModal';
import LoginModal from './LoginModal';
import ReadNotesModal from './ReadNotesModal'; 
import lockIcon from '../images/ic_lock.svg';
import nextIcon from '../images/ic_arrow_right.svg';
import '../styles/ChapterCard.css';
import RegistrationFeeModal from './RegistrationFeeModal';
import { Lock } from '@mui/icons-material';


const ChapterCard = ({ isLocked, chapter, course, isAuthenticated ,isRegistrationFeesPaid}) => {
  const [notesModal, setNotesModal] = useState(false);
  const [lecturesModal, setLecturesModal] = useState(false);
  const [unlockChapterModal, setUnlockChapterModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [unlockType, setUnlockType] = useState('');
  const [registrationFeeModal, setRegistrationFeeModal] = useState(false);

  const lockIcon = () => (
    <img src={lockIcon}/>
  );
  // Open the appropriate modal based on user action
  const handleOpen = (type) => {
    if (type === 'notes') {
      if (!isAuthenticated) {
        // Show login modal if user is not authenticated
        setLoginModal(true);
      } else {
        setNotesModal(true);
      }
    } else if (type === 'lectures') {
      if (!isAuthenticated) {
        setLoginModal(true);
      } else {
        setLecturesModal(true);
      }
    } else if (type === 'login') {
      setLoginModal(true);
    } else {
      setUnlockChapterModal(true);
    }
  };

  // Handle unlocking logic
  const handleUnlockClick = (unlockOption) => {
    if (!isAuthenticated) {
      setLoginModal(true);
    } else if (!isRegistrationFeesPaid) {
      // Open registration fees modal if user hasn't paid
      setRegistrationFeeModal(true); // This modal should handle the fee payment process
    } else {
      setUnlockType(unlockOption);
      handleOpen('unlockChapter');
    }
  };
  
  const handlePaymentSuccess = () => {
console.log('first') // Dispatch action to update the payment status in Redux
  };

  
  const handleClose = () => {
    setNotesModal(false);
   setLecturesModal(false);
   setUnlockChapterModal(false);
    setLoginModal(false);
    setRegistrationFeeModal(false); 
 };

  return (
    <div className="ChapterCard">
      {notesModal &&
        <ReadNotesModal
          handleClose={handleClose}
          chapter={chapter}
        />
      }
      {lecturesModal && (
        <ReadWatchModal
          type={'lectures'}
          handleClose={handleClose}
          chapter={chapter}
        />
      )}

{registrationFeeModal && (
      <RegistrationFeeModal
        handleClose={() => setRegistrationFeeModal(false)}
        onPaymentSuccess={handlePaymentSuccess}
      />
    )}
      {unlockChapterModal && (
        <UnlockChapterModal
          handleClose={handleClose}
          course={course}
          chapter={chapter}
          unlockType={unlockType}
        />
      )}
    
     
      {loginModal && (
        <LoginModal handleClose={handleClose} setLoginModal={setLoginModal} />
      )}
      
      <div className="ChapterCard-left">
        <span>Chapter {chapter?.number}</span>
        <p>{chapter?.name}</p>
      </div>

      {isLocked ? (
        <div className="ChapterCard-right">
          <button onClick={() => handleUnlockClick('chapter')}> Chapter {<Lock/>}</button>
          
          <button onClick={() => handleUnlockClick('course')}>Course {<Lock/>}</button>
        </div>
      ) : (
        <div className="ChapterCard-right">
          <div onClick={() => handleOpen('notes')} className="read-watch-button">
            <span>Read notes</span>
            <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
          </div>
          <div onClick={() => handleOpen('lectures')} className="read-watch-button">
            <span>Watch lecture</span>
            <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterCard;
