import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { getMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import MCQTable from "./MCQTable";
import TeacherNavbar from "../../../../components/Teacher/TeacherNavbar";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import { getAllUsers } from "../../../../api/AllUsers/Users";

const MCQ = () => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);

  //for edit modal open and closed
  const handleEditClick = (formData) => {
    setSelectedFormData(formData);
    setIsEditOpen(true);
  };

  // Use React Query to fetch topic data
  const {
    data: mcqExamData,
    error: mcqExamError,
    isLoading: mcqExamIsLoading,
    isError: mcqExamIsError,
  } = useQuery({
    queryKey: QueryKeys.mcq_exams,
    queryFn: getMCQExams,
  });

  // Use React Query to fetch users data
  const {
    data: usersData,
    error: usersError,
    isLoading: usersIsLoading,
    isError: usersIsError,
  } = useQuery({
    queryKey: QueryKeys.all_users,
    queryFn: getAllUsers,
  });

  if (mcqExamIsLoading && usersIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (mcqExamIsError && usersIsError) {
    return (
      <>
        <p>Error: {mcqExamError.message}</p>
        <p>Error: {usersError.message}</p>;
      </>
    );
  }

  const displayData = mcqExamData?.filter(
    (item) => item?.testType?.toLowerCase() == "mcq"
  );
  console.log("displayData", displayData);
  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="MCQ"
          title="MCQ Exam Details"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          {/* import form */}
          {/* <MCQForm /> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button
              size="small"
              variant="contained"
              onClick={handleEditClick}
              className="common-color"
              sx={{
                width: "auto",
                height: "36px", // Maintain the height of the button
                padding: "6px 16px", // Ensure padding is applied correctly
                transition: "background 0.1s ease, transform 0.1s ease",
                 marginTop: "-50px",
                "&:hover": {
                  background: "linear-gradient(to right, #edb038, #fb794a)", // Gradient background on hover
                  transform: "scale(1.07)", // Slightly enlarge on hover
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" // Shadow effect for hover
                }
              }}  
            >
              Add Exam
            </Button>
          </Box>

          <Box sx={{ marginTop: "55px" }}>
            {/* import table */}
            <MCQTable data={displayData} usersData={usersData} />
          </Box>
        </Box>
        {isEditOpen && (
          <AddExamModal
            formData={selectedFormData}
            open={isEditOpen}
            formName="Add MCQ Exam"
            onClose={() => setIsEditOpen(false)}
          />
        )}
      </Box>
    </>
  );
};

export default MCQ;
