import React from 'react';
import moment from 'moment';
import '../styles/Receipt.css';
import {toWords} from 'number-to-words'
const Receipt = ({ billData }) => {


  const tdStyle = {
    border: "1px solid black",
  };
  return (
    <>
    <div className="text-center">
      {" "}
      <h6 style={{ marginTop: "2rem", padding: 0 }}>
        <strong>Payment Receipt</strong>
      </h6>
      <div
        className="container"
        style={{ backgroundColor: "white", border: "1px solid", padding: 0,marginTop:0 }}
      >
  
        <div className="row">
          <div className="text-center">
            <h4 style={{ margin: 0 }}>
              <strong>Enrouting Careers Private Limited</strong>
            </h4>
            <p style={{ margin: 0, fontWeight: "initial" }}>
              PIRIYATH HOUSE 14/35 CHEMBRA OTTAPPALAM
            </p>
            <p style={{ margin: 0 }}>
              Phone no.: +9119600722736 Email:enroutingcareers@gmail.com
            </p>
            <p style={{ margin: 0 }}>
              GSTIN:32AAFCE5000L1Z1, State:32-Kerla{" "}
            </p>
          </div>
        </div>

        <br />
        <div>


<table
className="table"
style={{
  border: "1px solid black",
  borderCollapse: "collapse",
  marginBottom: 0,
  width: '100%' // Set table width to 100%
}}
>
<thead className="invoice-table">
  <tr>
    <th className='invoice-table'
      style={{
       
        textAlign: "start",
        fontSize: "14px",
        width: '50%', // Equal width for both columns
      }}
    >
      Bill To
    </th>
    <th className='invoice-table'
      style={{
       
        textAlign: "end",
        fontSize: "14px",
        width: '50%', // Equal width for both columns
      }}
    >
      Receipt Details
    </th>
  </tr>
</thead>

<tbody
  style={{ border: "1px solid black", borderCollapse: "collapse" }}
>
  <tr>
    <td style={{ border: "1px solid black", width: '50%' }}>
      <p style={{ textAlign: "start" }}>
        <strong>{billData?.userName} </strong>
        <br />
        {billData?.address} 
        <br />
        Contact No.: {billData?.phone}
        <br />
        State: {billData?.state}
      </p>
    </td>
    <td style={{ border: "1px solid black", width: '50%' }}>
      <p style={{ textAlign: "end" }}>
        Receipt no.: ECPMT{billData?.invoiceNumber}
        <br />
        Date: {moment(billData?.issueData)?.format('DD/MM/YYYY')}

      </p>
    </td>
  </tr>
</tbody>
</table>


     

          <div style={{display:'flex',flexDirection: "row-reverse" }}>


<table
  className="table"
  style={{ border: '1px solid black',  marginBottom: 0, width: '50%' }}
>
<thead   style={{ borderCollapse: 'collapse', border: "1px solid black"}}>
<tr style={{ borderCollapse: 'collapse', border: "1px solid black", background: 'linear-gradient(to right, #fb794a, #edb038)' }}>
  <th style={{ textAlign: 'start', border: 'none', background: 'transparent',color:'white' ,fontSize:'14px'}}>Amounts</th>
  <th style={{ textAlign: 'start', border: 'none', background: 'transparent' }}></th>
</tr>


      </thead>

   
 
  <tbody>
  
      <tr >
      <td style={{ border: 'none',textAlign:'start' }}>Recieved</td>
<td style={{ border: 'none', padding: '10px 0px 0 0' }}>₹ {billData?.amount} /-</td>

      </tr>
  </tbody>
</table>



</div>






<table
className="table"
style={{ border: "1px solid black", borderCollapse: "collapse", marginBottom: 0, width: '100%', tableLayout: 'fixed' }}
>
<thead className="invoice-table">
  <tr >
    <th style={{ width: '50%' }}>
      <h5>Invoice Amount in Words</h5>
    </th>
    <th style={{ width: '50%' }}>
      <h5>Description</h5>
    </th>
  </tr>
</thead>

<tbody style={{ border: "1px solid black", borderCollapse: "collapse" }}>
  <tr>
    <td style={{ ...tdStyle, textAlign: 'start', padding: '10px' }}>{billData?.amount ? toWords(billData.amount) + ' Rupees Only' : '-'}
    </td>
    <td style={{ ...tdStyle, textAlign: 'start', padding: '10px' }}>
  Billing Period Month of {moment(billData?.issueData).format('MMMM YYYY')}
</td>
 </tr>
</tbody>
</table>





        </div>

      </div>
    </div>

  
  </>
  );
};

export default Receipt;
