import { client } from "../../api_client";
import config from "../../../config";
const URL = config.BASE_URL;

const endPoints = {
  addExam: "create",
  getAllExam: "findAll",
};

const API_URLS = {
  GET_ALL_MCQ_EXAMS: `${URL}/api/exam/${endPoints.getAllExam}`,
  CREATE_MCQ_EXAMS: `${URL}/api/exam/${endPoints.addExam}`,
};

export const getMCQExams = async () => {
  try {
    const url = `${API_URLS.GET_ALL_MCQ_EXAMS}`;
    const response = await client.get(url);

    const data = response?.data;

    return data;
  } catch (error) {
    throw new Error("An error occurred while fetching the data.");
  }
};

export const createMCQExams = async (postData) => {
  try {
    const url = `${API_URLS.CREATE_MCQ_EXAMS}`;

    const response = await client.post(url, postData);

    const data = response?.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("An error occurred while creating the post.");
  }
};
