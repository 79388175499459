import { GET_ALL_COURSES, GET_USER_COURSES,GET_USER_CHAPTERS } from '../actionsType';


const initialState = {
  allCourses: [],
  userCourses: [],
  userChapters:[]
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COURSES:
      return {
        ...state,
        allCourses: action?.payload,
      };
    case GET_USER_COURSES:
      return {
        ...state,
        userCourses: action?.payload,
      };
      case GET_USER_CHAPTERS:
      return {
        ...state,
        userChapters: action?.payload,
      };
    default:
      return state;
  }
};

export default courseReducer;