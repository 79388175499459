import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
import TeacherNavbar from "../../../../components/Teacher/TeacherNavbar";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import { getSubMegaExams } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";
import SubjectiveTable from "./SubjectiveTable";
import { getAllUsers } from "../../../../api/AllUsers/Users";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const Subjective = () => {
  // Fetch user by token
  const tokenString = Cookies.get("userJWT");
  let token;
  if (tokenString) {
    try {
      token = jwtDecode(tokenString);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);

  //for edit modal open and closed
  const handleEditClick = (formData) => {
    setSelectedFormData(formData);
    setIsEditOpen(true);
  };

  // Use React Query to fetch topic data
  const {
    data: subjectiveExamData,
    error: subjectiveExamError,
    isLoading: subjectiveExamIsLoading,
    isError: subjectiveExamIsError,
  } = useQuery({
    queryKey: QueryKeys.subjective_mega_exam,
    queryFn: getSubMegaExams,
  });

  // Use React Query to fetch users data
  const {
    data: usersData,
    error: usersError,
    isLoading: usersIsLoading,
    isError: usersIsError,
  } = useQuery({
    queryKey: QueryKeys.all_users,
    queryFn: getAllUsers,
  });

  if (subjectiveExamIsLoading && usersIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (subjectiveExamIsError && usersIsError) {
    return (
      <>
        <p>Error: {subjectiveExamError.message}</p>;
        <p>Error: {usersError.message}</p>;
      </>
    );
  }

  const displayData = subjectiveExamData?.filter(
    (item) => item.testType.toLowerCase() === "subject_wise"
  );

  console.log("token", token?.id);
  console.log("displayData", displayData);

  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="Subjective"
          title="Subjective Exam Details"
        />
        <Box
          sx={{
            margin: "20px 20px 30px 20px",
          }}
          data-aos="zoom-in"
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                size="small"
                variant="contained"
                onClick={handleEditClick}
                className="common-color"
                sx={{
                  padding: "6px 16px",
                  width: "auto",
                  transition: "background 0.1s ease, transform 0.1s ease",
                  marginTop: "-90px",
                  "&:hover": {
                    background: "linear-gradient(to right, #edb038, #fb794a)", 
                    transform: "scale(1.07)", 
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
                  },
                }}
              >
                Add Exam
              </Button>
            </Box>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <SubjectiveTable data={displayData} usersData={usersData} />
          </Box>
        </Box>
        {isEditOpen && (
          <AddExamModal
            formData={selectedFormData}
            open={isEditOpen}
            formName="Add Subjective Exam"
            onClose={() => setIsEditOpen(false)}
          />
        )}
      </Box>
    </>
  );
};

export default Subjective;
