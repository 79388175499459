import React from 'react';
import '../styles/style.css'; // Assuming you have this file for custom styles


const BestOutOfWaste = () => {
  return (
    <>
      {/* Big Screen Container */}
      <div className="big-screen-container">
        <div className="container-top">
          <h4>Best out of waste</h4>
          <button className="btn btn-primary">Register for Event</button>
        </div>
        <div className="container-down">
          <div className="leftbox1">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjemOknolJOZ6IfkDclJVaX6zO8bODghFkxA&s"
              alt="Event"
            />
          </div>
          <div className="rightbox1">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat
              velit rem iure, <br />
              amet aperiam explicabo. Lorem ipsum dolor sit amet consectetur
              adipisicing elit.
            </p>
            <div className="detail">
              <div className="register">
                <p>
                  <strong>Registration fee: Rs.200</strong>
                </p>
              </div>
              <div className="more">
                <p>
                  <strong>More detail</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Small Screen Container */}
      <div className="small-screen-container">
        <div className="container-top">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjemOknolJOZ6IfkDclJVaX6zO8bODghFkxA&s"
            alt="Event"
          />
        </div>
        <div className="container-down">
          <h4>Best out of waste</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat
            velit rem iure, amet aperiam explicabo. Lorem ipsum dolor sit amet
            consectetur adipisicing elit.
          </p>
          <div className="detail">
            <div className="register">
              <p>
                <strong>Registration fee: Rs.200</strong>
              </p>
            </div>
            <button className="btn btn-primary">Register for Event</button>
            <div className="more">
              <p>
                <strong>More detail</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestOutOfWaste;
