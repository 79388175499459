
import React ,{useEffect}from "react";
import { Button, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserPoints } from "./../redux/actions/userActions";




export default function RewardSelection({ userData, closeModal, setPointsUsed, setSelectedReward, setFinalPrice, finalPrice, setShowRewardSelection,getPackageBySubjects,lecturePrices }) {
   const dispatch=useDispatch()
   const { userPoints } = useSelector((store) => store.userReducer);

   useEffect(()=>{
    dispatch(getUserPoints())
   },[])

  
   console.log('userPoints', userPoints)
  
    // const applyReward = (rewardType) => {
    //   const rewardAmount = rewardType === 'welcomeBonus' 
    //     ? Math.floor(userData?.welcomeBonus) 
    //     : Math.floor(userData?.loyaltyPoints);
  
    //   // Update points used and final prices, ensuring that the price doesn't go below 1
    //   setPointsUsed({
    //     yearly: Math.min(finalPrice.yearly - 1, rewardAmount),
    //     quarterly: Math.min(finalPrice.quarterly - 1, rewardAmount),
    //     monthly: Math.min(finalPrice.monthly - 1, rewardAmount),
    //   });
      
    //   setSelectedReward(rewardType);
  
    //   // Apply reward, ensuring the final price is at least ₹1
    //   setFinalPrice({
    //     yearly: Math.max(finalPrice.yearly - rewardAmount, 1),
    //     quarterly: Math.max(finalPrice.quarterly - rewardAmount, 1),
    //     monthly: Math.max(finalPrice.monthly - rewardAmount, 1),
    //   });
  
    //   // Close the reward selection modal
    //   setShowRewardSelection(false);
    // };
  

    useEffect(() => {
      dispatch(getUserPoints());
    }, []);

    
  
    const applyReward = (rewardType) => {
        const rewardAmount = rewardType === 'welcomeBonus' 
          ? (userPoints?.data?.welcomeBonus) 
          : (userPoints?.data?.loyaltyPoints);
      
        // Get the original prices
        const originalPrices = {
          yearly: (lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.yearly_prices || 0),
          quarterly: (lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.quarterly_prices || 0),
          monthly: (lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.monthly_prices || 0),
        };
        console.log('originalPrices', originalPrices)
      
        // Calculate maximum spendable amounts
        const maxSpendable = {
          yearly: (rewardType === 'welcomeBonus' ? originalPrices.yearly * 0.3 : originalPrices.yearly * 0.2),
          quarterly: (rewardType === 'welcomeBonus' ? originalPrices.quarterly * 0.3 : originalPrices.quarterly * 0.2),
          monthly: (rewardType === 'welcomeBonus' ? originalPrices.monthly * 0.3 : originalPrices.monthly * 0.2),
        };
        console.log('maxSpendable', maxSpendable)
      
        // Update points used and final prices, ensuring that the price doesn't go below 1
        setPointsUsed({
          yearly: Math.min(finalPrice.yearly - 1, Math.min(rewardAmount, maxSpendable.yearly)),
          quarterly: Math.min(finalPrice.quarterly - 1, Math.min(rewardAmount, maxSpendable.quarterly)),
          monthly: Math.min(finalPrice.monthly - 1, Math.min(rewardAmount, maxSpendable.monthly)),
        });
      
        setSelectedReward(rewardType);
      
        // Apply reward, ensuring the final price is at least ₹1
        setFinalPrice({
          yearly: Math.max(finalPrice.yearly - Math.min(rewardAmount, maxSpendable.yearly), 1),
          quarterly: Math.max(finalPrice.quarterly - Math.min(rewardAmount, maxSpendable.quarterly), 1),
          monthly: Math.max(finalPrice.monthly - Math.min(rewardAmount, maxSpendable.monthly), 1),
        });
      
        // Close the reward selection modal
        setShowRewardSelection(false);
      };
      
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>Select a reward to apply</Typography>
        
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={userPoints?.data?.welcomeBonus === 0|| userPoints?.data?.welcomeBonus < 1}
            onClick={() => applyReward("welcomeBonus")}
          >
            Use Welcome Bonus (₹ {userPoints?.data?.welcomeBonus})
          </Button>
        </Box>
  
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={userPoints?.data?.loyaltyPoints === 0 || userPoints?.data?.loyaltyPoints < 1 }
            onClick={() => applyReward("loyaltyPoints")}
          >
            Use Loyalty Points (₹ {userPoints?.data?.loyaltyPoints})
          </Button>
        </Box>
  
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </Box>
    );
  }
  