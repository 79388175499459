
// import {  applyMiddleware, compose } from 'redux';
// import { legacy_createStore as createStore } from 'redux'
// import {thunk} from 'redux-thunk';
// import reducers from './reducers';
// import { configureStore } from '@reduxjs/toolkit';


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(thunk))
// );

// export default store;




//=======================>Persist<==================


import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist'; // Import persistStore
import {thunk} from 'redux-thunk'; // Assuming you're using thunk middleware
import rootReducer from './reducers'; // Import your persisted root reducer

// Create the store with the persisted reducer
const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

// Create a persistor for the store
export const persistor = persistStore(store);

// Export the store as default
export default store;


