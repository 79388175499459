import * as api from './api';
import { GET_LECTURE_PRICE,GET_USER_LECTURES_BY_YEAR,GET_USER_ALL_LECTURES } from '../actionsType';

export const getLecturePrice = (id) => async (dispatch) => {
    try {
      const { data } = await api.getLecturePrice(id);

      dispatch({
        type: GET_LECTURE_PRICE,
        payload: data,
      });
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : "Something went wrong in getting lectures prices";
      console.log(message);
    }
  };



  export const getUserLecturesbyYear = () => async (dispatch) => {
    try {
  
      const { data } = await api.getUserLecturesbyYear();
     console.log("data from lecctures  action",data);
      dispatch({
        type: GET_USER_LECTURES_BY_YEAR,
        payload: data,
      });
      return data
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : 'Something went wrong';
      console.log(message);
    }
  };
  





  
  export const getUserAllLectures = () => async (dispatch) => {
    try {
  
      const { data } = await api.getUserAllLectures();
     console.log("data from all user lectures",data);
      dispatch({
        type: GET_USER_ALL_LECTURES,
        payload: data,
      });
      return data
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : 'Something went wrong';
      console.log(message);
    }
  };
  

