import React, { useState, Suspense } from 'react';
import SubmissionModal from './SubmissionModal';
import ResultsModal from './ResultsModal';
import closeIcon from '../images/ic_close.svg';

import { Lock } from '@mui/icons-material'; // Add a lock icon image to your project
import '../styles/EventWorkshopModal.css';
import ViewSubmissionModal from './ViewSubmissionModal';
import { screenSize } from '../util/screenSize';
import ProjectSubmissionModal from './ProjectSubmissionModal';

const ProjectModal = (props) => {
  const {
    item,
    purchaseDetails,
    setProjectModal,
    locked,
    handleRegisterClick,
    submissionStatus,
    submissionDetails,
    validTill,
  } = props;

  const [submissionModal, setSubmissionModal] = useState(false);
  const [viewSubmissionsModal, setViewSubmissionsModal] = useState(false);
  const [resultsModal, setResultsModal] = useState(false);
  var screen = window.innerWidth;

  const handleClick = () => {
    setProjectModal(false);
  };

  return (
    <div className='EventWorkshopModal'>
      <div className='EventWorkshopModal-content'>
        <span onClick={handleClick}>
          <img
            src={closeIcon}
            alt='close-icon'
            className='EventWorkshopModal-close-icon'
          />
        </span>

        <div className='EventWorkshopModal-container'>
          <div className='EventWorkshopModal-title-row'>
            <h1>{item?.project_name}</h1>
            {screen > screenSize && locked && validTill && (
              <button onClick={handleRegisterClick} className='cc'>
                Register for project
              </button>
            )}
          </div>

          {!locked ? (
            <div className='registration-details-div'>
              <p className='registration-text'>
                Paid amount: ₹{item?.price}
              </p>
              <div>
                <span className='registration-text date-text-margin'>
                  Start date: {item?.startTime?.replace('6:30 PM', '')}
                </span>
                <span className='registration-text'>
                  End date: {item?.endTime?.replace('6:30 PM', '')}
                </span>
              </div>
            </div>
          ) : (
            <div className='registration-details-div'>
              <p className='registration-text'>
                Registration fee: ₹{item?.price}
              </p>
              {screen < screenSize && locked && validTill && (
                <button onClick={handleRegisterClick} className='cc'>
                  Register for project
                </button>
              )}
            </div>
          )}

          <div className='EventWorkshopModal-img-desc-div'>
            <div className='EventWorkshopModal-imgDiv'>
              {!locked ? (
                item?.isVideo === 0 ? (
                  <img
                    src={item?.img_url}
                    alt='event-img'
                    className='EventWorkshopModal-img'
                  />
                ) : (
                  <video
                    src={item?.video_url}
                    controls
                    className='EventWorkshopModal-img'
                  />
                )
              ) : (
                <div className='locked-image-container'>
                  {item?.isVideo === 0 ? (
                    <>
                     
                      <img
                        src={Lock} // Lock icon overlay
                        alt='lock icon'
                        className='lock-icon'
                      />
                    </>
                  ) : (
                    <>
                    
                      <img
                        src={Lock} // Lock icon overlay for video
                        alt='lock icon'
                        className='lock-icon'
                      />
                    </>
                  )}
                </div>
              )}
            </div>

            <div
              dangerouslySetInnerHTML={{ __html: item?.project_desc }}
              className='EventWorkshopModal-desc'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
