import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamPaymentModal from "./ExamPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserExams } from "../../../../redux/actions/examActions";
import moment from "moment";

const DashboardScreen = ({ classTest }) => {
  
  const dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [examData, setExamData] = useState(null);
  const { userData } = useSelector((store) => store.userReducer);
  const { userExams } = useSelector((store) => store.examReducer);
  const { userLecturesByYEAR } = useSelector((store) => store.lectureReducer);

  // const isButtonEnabled = (selectDate, startTime, endTime) => {
  //   const now = new Date();

  //   // Combine selectDate and startTime/endTime into proper Date objects
  //   const startDateTime = new Date(`${selectDate}T${startTime}`);
  //   const endDateTime = new Date(`${selectDate}T${endTime}`);
  //   if (startDateTime < endDateTime) {
  //     return "comingSoon";
  //   }


  //   if (now >= startDateTime && now <= endDateTime) {
  //     return "enabled";
  //   }

  //   // if (
  //   //   currentDate === selectDate &&
  //   //   currentTime >= startTime &&
  //   //   currentTime <= endTime
  //   // ) {
  //   //   return "enabled";
  //   // }
  //   // const hasSubmitted = userExam?.some(item => item?.attendedBy === true);
  //   // if (hasSubmitted) {
  //   //   return "submitted";
  //   // }
    
  //   return "notAvailable";
  // };



  const isButtonEnabled = (selectDate, startTime, endTime) => {
    const now = moment();
    
    // Combine selectDate and startTime/endTime into proper moment objects
    const startDateTime = moment(`${selectDate} ${startTime}`, "YYYY-MM-DD HH:mm");
    const endDateTime = moment(`${selectDate} ${endTime}`, "YYYY-MM-DD HH:mm");
  

    // Check if the current time is within the start and end times
    if (now.isBetween(startDateTime, endDateTime)) {
      return "enabled";
    }

    if (now.isBefore(startDateTime)) {
      return "comingSoon";
    }

    return "notAvailable";
};


  const navigate = useNavigate();
  const handleNavigate = (item) => {
    console.log('item', item)
    // Check if the user has access to the exam via `userExams`
    const hasExamAccess = Array.isArray(userExams) && userExams.some((exam) => exam?.examId == item?.id);

    console.log('hasExamAccess', hasExamAccess)
  
    // Check if the user has purchased lectures that are still valid
    const hasValidLectureAccess = Array.isArray(userLecturesByYEAR) && userLecturesByYEAR.some((lectures) => {
      const lectureInstances = lectures?.userPurchasedLectures?.length;
      const validTill = lectures?.userPurchasedLectures[lectureInstances - 1]?.validTill;
      const isValid = new Date(validTill) >= new Date();
      return lectures?.id === item?.courseId && isValid;
    });
    

    console.log('hasValidLectureAccess', hasValidLectureAccess)
  
    // If the user has not purchased the exam and there is no valid lecture access, show the payment modal
    if (!hasExamAccess && !hasValidLectureAccess) {//use !

      setPaymentModal(true);
      setExamData(item);
      return;
    }

    if (item?.testType === "mcq") {
      navigate("/student-test/student_mcq-test-screen", {
        state: { item: item },
      });
    } else if (item?.testType === "subject_wise") {
      navigate("/student-test/student_subjective-test");
    } else if (item?.testType === "mega_test") {
      navigate("/student-test/student_mega-test");
    }
  };

  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Test Cards */}
      <Box
        sx={{
          width: "100%",
          padding: { xs: "10px", sm: "20px" }, // Adjusted padding for mobile
          display: "flex",
          flexWrap: "wrap", // To wrap cards
          justifyContent: { xs: "center", sm: "flex-start" }, // Center items on small screens
          alignItems: "center",
          margin: "auto",
        }}
      >
        {classTest &&
          classTest?.length > 0 &&
          classTest?.map((item, index) => {
            const buttonStatus = isButtonEnabled(
              item.select_date,
              item.start_time,
              item.end_time,
              
             
            );
            return (
              <Card
                sx={{
                  width: { xs: "100%", sm: "400px" }, // Full width for mobile, fixed width for larger screens
                  height: "auto",
                  background: "whiteSmoke",
                  margin: "10px",
                }}
                variant="outlined"
                key={index}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom textAlign="center">
                    {item?.examName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on mobile
                      justifyContent: "space-between",
                      gap: { xs: "10px", sm: "0" }, // Add space between items on mobile
                    }}
                  >
                    <Box>
                      <Typography>
                        Exam Type: {item?.testType?.toUpperCase()}
                      </Typography>
                      <Typography>Exam Date: {item?.select_date}</Typography>
                      <Typography>Start Time: {item?.start_time ? moment(item?.start_time, "HH.mm.ss").format("hh:mm A") : "Invalid date"}</Typography>
                      <Typography>End Time: {item?.end_time ? moment(item?.end_time, "HH:mm:ss").format("hh:mm A") : "Invalid date"}</Typography>
                    </Box>

                    <Box>
                      <Typography>
                        Subject: {item?.select_subject?.toUpperCase()}
                      </Typography>
                      <Typography>Chapter: {item?.select_chapter}</Typography>
                      <Typography>Class: {item?.select_class}</Typography>
                      <Typography>Marks: {item?.total_marks}</Typography>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    margin: "auto auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {buttonStatus === "enabled" ? (
                      <Button
                        size="small"
                        variant="contained"
                        className="common-color"
                        onClick={() => handleNavigate(item)}
                      >
                        Start Test
                      </Button>
                    ) : buttonStatus === "comingSoon" ? (
                      <Typography className="badgeColor">Coming Soon</Typography>
                    ) : buttonStatus==="submitted"?(
                      <Typography className="badgeColor">Submitted</Typography>
                    ): (
                      <Typography className="badgeColor">Not Available</Typography>
                    )}
                  </Box>
                </CardActions>
              </Card>
            );
          })}
      </Box>

      {classTest && classTest?.length === 0 && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="grey" variant="h5">
            There is no live test
          </Typography>
        </Box>
      )}
      {paymentModal && (
        <ExamPaymentModal
          examData={examData}
          userData={userData}
          closeModal={() => setPaymentModal(false)}
        />
      )}
    </Box>
  );
};

export default DashboardScreen;
