
// import React from "react";
// import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
// import { jwtDecode } from "jwt-decode"; // Corrected import
// import Cookies from "js-cookie";
// import App from "./App";
// import store from "./redux/store";
// import "./index.css";
// import { setUserDetails, logout } from "./redux/actions/userActions";
// import {
//   setTeacherDetails,
//   teacher_logout,
// } from "./redux/actions/teacherActions";
// import { BrowserRouter as Router } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { parent_logout, setParentsDetails } from "./redux/actions/parentActions";

// // Create a QueryClient instance
// const queryClient = new QueryClient();

// const jsx = (
//   <React.StrictMode>
//     <Router>
//       <QueryClientProvider client={queryClient}>
//         <Provider store={store}>
//           <App />
//           <ToastContainer
//             position="top-right"
//             autoClose={3000}
//             hideProgressBar={true}
//             pauseOnHover={false}
//             rtl={false}
//           />
//         </Provider>
//       </QueryClientProvider>
//     </Router>
//   </React.StrictMode>
// );

// const rootElement = document.getElementById("root");

// if (Cookies.get("userJWT")) {
//   const decoded = jwtDecode(Cookies.get("userJWT"));
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(logout()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   } else {
//     store.dispatch(setUserDetails()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   }
// } else if (Cookies.get("teacherJWT")) {
//   const decoded = jwtDecode(Cookies.get("teacherJWT"));
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(teacher_logout()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   } else {
//     store.dispatch(setTeacherDetails()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   }
// }
// else if (Cookies.get("parentJWT")) {
//   const decoded = jwtDecode(Cookies.get("parentJWT"));
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(parent_logout()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   } else {
//     store.dispatch(setParentsDetails()).then(() => {
//       ReactDOM.createRoot(rootElement).render(jsx);
//     });
//   }
// }
// else {
//   ReactDOM.createRoot(rootElement).render(jsx);
// }




//===========================>persist<=============================





import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import App from "./App";
import store, { persistor } from "./redux/store"; // Import persistor
import "./index.css";
import { setUserDetails, logout } from "./redux/actions/userActions";
import { setTeacherDetails, teacher_logout } from "./redux/actions/teacherActions";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { parent_logout, setParentsDetails } from "./redux/actions/parentActions";
import ErrorBoundary from "./functions/ErrorBoundary";

// Create a QueryClient instance
const queryClient = new QueryClient();

const jsx = (
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}> {/* Wrap in PersistGate */}
            <ErrorBoundary>
            <App />
            </ErrorBoundary>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              pauseOnHover={false}
              rtl={false}
            />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

// Your existing logic to decode JWT and dispatch user, teacher, or parent details remains the same.
if (Cookies.get("userJWT")) {
  const decoded = jwtDecode(Cookies.get("userJWT"));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  } else {
    store.dispatch(setUserDetails()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  }
} else if (Cookies.get("teacherJWT")) {
  const decoded = jwtDecode(Cookies.get("teacherJWT"));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(teacher_logout()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  } else {
    store.dispatch(setTeacherDetails()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  }
} else if (Cookies.get("parentJWT")) {
  const decoded = jwtDecode(Cookies.get("parentJWT"));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(parent_logout()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  } else {
    store.dispatch(setParentsDetails()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  }
} else {
  ReactDOM.createRoot(rootElement).render(jsx);
}
