import React from "react";
import { Box } from "@mui/material";
import BreadCrumbs from "../../../../../components/BreadCrumbs";
import TeacherNavbar from "../../../../../components/Teacher/TeacherNavbar";
import { useLocation } from "react-router-dom";
import MCQStudAttemptedTable from "./MCQStudAttemptedTable";

const MCQStudAttempted = () => {
  const location = useLocation();
  const examData = location?.state?.examData;
  const studentDetails = location?.state?.studentDetails;

  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="MCQ Exam"
          title="MCQ Exam Attend By Students"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <MCQStudAttemptedTable
              studentDetails={studentDetails}
              examData={examData}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MCQStudAttempted;
