import React, { useState } from "react";
import { Box } from "@mui/material";
import BreadCrumbs from "../../../../../components/BreadCrumbs";
import TeacherNavbar from "../../../../../components/Teacher/TeacherNavbar";
import { useLocation } from "react-router-dom";
import MegaTableMarks from "./MegaTableMarks";

const MegaExamAttendByStu = () => {
  const location = useLocation();
  const examData = location?.state?.data;
  const studentData = location?.state?.studentDetails;
  console.log("me", examData);
  console.log("ms", studentData);

  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <TeacherNavbar />
      <Box sx={{ mt: "100px" }}>
        <BreadCrumbs
          pageName="Exams"
          subPageName="Mega Exam"
          title="Mega Exam Attend By Students"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ marginTop: "10px" }}>
            {/* import table */}
            <MegaTableMarks
              isEditOpen={isEditOpen}
              setIsEditOpen={setIsEditOpen}
              studentData={studentData}
              examData={examData}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MegaExamAttendByStu;
