import * as api from './api';
import {GET_USER_EXAMS } from '../actionsType'

export const getUserExams=()=>async(dispatch)=>{

    try{
        const {data}=await api.getUserExams();
        console.log('data', data)
        dispatch({
            type:GET_USER_EXAMS,
            payload:data
        });
    }catch(err){
throw err
    }
}