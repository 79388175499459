import { TEACHER_LOGIN,SET_TEACHER_DETAILS,TEACHER_LOGOUT } from '../actionsType';
import Cookies from 'js-cookie';

const initialState = {
  teacherData: null,
  isAuthenticated: null,  // Check if token exists in cookies
};

const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHER_LOGIN:
      Cookies.set('teacherJWT', action?.payload?.token, {
        expires: 7, path: '/'
      });
      return {
        ...state,
        teacherData: action?.payload?.teacherDetails,
        isAuthenticated: true,
      };

    // case SIGNUP:
    //   Cookies.set('userJWT', action?.payload?.token, {
    //     expires: 7,
    //   });
    //   return {
    //     ...state,
    //     userData: action?.payload?.userDetails,
    //     isAuthenticated: true,
    //   };

    case SET_TEACHER_DETAILS:
      return {
        ...state,
        teacherData: action?.payload,
        isAuthenticated: true,
      };

    case TEACHER_LOGOUT:

      Cookies.remove('teacherJWT',{path: '/'});
      return initialState;
     


    default:
      return state;
  }
};

export default teacherReducer;