import { LECTURE_REQUESTS, STUDENT_REQUESTS } from "../actionsType";

const InitialState={
   requests:null
}



const requestReducer=(state=InitialState,action)=>{

    switch(action.type)
    {
        case STUDENT_REQUESTS:
            return{
                ...state,
                requests:action?.payload
            }


        case LECTURE_REQUESTS:
            return{
                ...state,
                requests:action?.payload
            }    
        default:
                return state;
    }
}

export default requestReducer;