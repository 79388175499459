
import { GET_ALL_VIDEOS } from '../actionsType';

const initialState={
    videoData:null
}
const watchVideoReducer=(state=initialState,action)=>{

    switch(action.type){
        case GET_ALL_VIDEOS:
            return{
                ...state,
                videoData:action?.payload
            }

         default:
            return state

    
    }


}


export default watchVideoReducer;