import React, { useReducer } from 'react';
import '../../styles/Banner.css';
import anime from '../../images/anime.png'
import { useSelector } from 'react-redux';


const Banner = () => {
   

    const { selectedStudent } = useSelector((store) => store.studentReducer);
  return (
    <div className="dashboard ">
      <div className="banner">
        <div className="persondetail">
          {/* <img className="anime" src={anime} alt="anime" /> */}
          <h3>Welcome to Dashboard  ID- AA000{selectedStudent?.id}</h3>
        </div>
      </div>
    </div>
  );
};

export default Banner;
