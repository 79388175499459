import { useFormik } from "formik";
import React, { useState } from "react";
import { Box, Button, FormHelperText } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import FormButton from "../../../../components/FormButton/FormButton";
import { Slide, toast } from "react-toastify";
import { QueryKeys } from "../../../../utils/QueryKeys";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { uploadUserSubMegaAns } from "../../../../api/viewExamStudent/subjectiveAndMega/userSub_Mega_api";

const initialValues = {
  testType: "subject_wise",
  userId: "",
  examId: "",
  uploadPaper: "",
};

const UserSubjectiveForm = ({ rowData, setIsEditOpen, onMarksUpdate }) => {
  const queryClient = useQueryClient();

  // post the data
  const createPostMutation = useMutation({
    mutationFn: uploadUserSubMegaAns,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.subjective_mega_exam,
      });
      toast.success("Data submitted successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
      setIsEditOpen(false);
      if (onMarksUpdate) {
        onMarksUpdate(rowData?.id, true); // Pass rowData.id and true to indicate the exam is attended
        console.log("rowData?.id", rowData);
        console.log("dataSuc", data);
        console.log("data?.uploadPaper?.examId", data?.uploadPaper?.examId);
      }
    },
    onError: (error) => {
      toast.error("An error occurred while adding the data.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const onSubmit = async (values) => {
    try {
      // Fetch user by token
      const tokenString = Cookies.get("userJWT");
      let token;
      if (tokenString) {
        try {
          token = jwtDecode(tokenString);
        } catch (error) {
          console.error("Invalid token", error);
        }
      }

      const formData = new FormData();
      formData.append("testType", "subject_wise");
      formData.append("userId", token?.id);
      formData.append("examId", rowData?.id);
      formData.append("uploadPaper", values?.uploadPaper);

      await createPostMutation.mutate(formData);
      console.log("formData", formData);
    } catch (error) {
      console.error(error.message || "An error occurred");
      toast.error("An error occurred while adding the data.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    }
  };

  const validationSchema = Yup.object({
    uploadPaper: Yup.string().required("Please upload answer key pdf"),
  });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  });

  const { values, errors, setFieldValue, touched, handleSubmit, resetForm } =
    formik;

  const handleReset = () => {
    resetForm();
    setSelectedAns("");
  };

  const [selectedAns, setSelectedAns] = useState("");

  const handleAnsKeyUpload = (e) => {
    const file = e.target.files[0];
    setSelectedAns(file?.name?.slice(0, 15));
    setFieldValue("uploadPaper", file);
  };

  console.log("valuesSubjective", values);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
          width: { lg: "350px", md: "300px", sm: "300px", xs: "300px" },
          mt: 3,
        }}
      >
        {/* row 2 uploadPaper, question paper*/}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          {/* ans key  */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              size="small"
              fullWidth
              variant="contained"
              className="pdf-button"
              component="label"
            >
              <CloudUploadIcon /> &nbsp; Ans key
              <input
                type="file"
                name="uploadPaper"
                id="uploadPaper"
                accept="application/pdf"
                hidden
                onChange={(e) => handleAnsKeyUpload(e)}
              />
            </Button>
            {selectedAns.length > 0 && `Selected : ${selectedAns}`}
            {touched.uploadPaper && errors.uploadPaper && (
              <FormHelperText error>{errors.uploadPaper}</FormHelperText>
            )}
          </Box>
        </Box>

        {/* submit button */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            gap: "10px",
          }}
        >
          <FormButton handleSubmit={handleSubmit} handleReset={handleReset} />
        </Box>
      </Box>
    </>
  );
};

export default UserSubjectiveForm;
