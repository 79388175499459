import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { addMegaTestExamsCol } from "../../../../data/mockData";
import FlakyIcon from "@mui/icons-material/Flaky";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteExam } from "../../../../api/addExam/SubjectiveAndMegaTest/subjective_megaTest_Exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { Slide, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModalExams from "../../../../components/deleteModal/deleteModalExams";
import config from "../../../../config";

const MegaTestTable = ({ data, usersData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data?.filter((item) =>
    item.select_subject.toLowerCase().startsWith(searchQuery.toLowerCase())
  );
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const totalLength = filteredData && filteredData?.length;

  const navigate = useNavigate();
  const handleExamIdClick = async (itemId) => {
    try {
      console.log("itemId", itemId);
      const response = await axios.get(
        `${config.BASE_URL}/api/userAttendExam/${itemId}/attended-users`
      );
      const examData = response.data.data; // Access the data array directly

      console.log("examDataHai", examData); // Log the response data to inspect its structure
      console.log("usersData", usersData); // Log the users data

      // Find the corresponding user from usersData for each item in examData
      const studentDetails = examData.map((exam) => {
        return usersData.find((user) => user.id === exam.userId);
      });

      console.log("studentDetails", studentDetails);

      navigate("/test/mega-test-attempt-by-students", {
        state: { data: examData, studentDetails: studentDetails },
      });
    } catch (error) {
      console.error("Failed to fetch exam data:", error);
    }
  };

  // for delete modal
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const queryClient = useQueryClient();

  // delete data mutation
  const mutationDelete = useMutation({
    mutationFn: deleteExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.subjective_mega_exam,
      });
      toast.success("Exams deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
    onError: (error) => {
      toast.error("An error occurred while deleting the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const handleDeleteClick = (userId) => {
    // Show the delete confirmation dialog
    setShowDeleteConfirmation(true);
    setUserIdToDelete(userId);
  };

  const handleConfirmDelete = () => {
    // Trigger the delete mutation here
    if (userIdToDelete) {
      mutationDelete?.mutate(userIdToDelete);
    }

    // Close the confirmation dialog
    setShowDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Close the confirmation dialog without deleting
    setShowDeleteConfirmation(false);
  };

  return (
    <>
    <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {addMegaTestExamsCol?.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  textAlign: "center",
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  fontWeight: 'bold',
                  backgroundColor: "#121212",
                  color: "white",
                  '&:hover': {
                    backgroundColor: "#303f9f",
                  },
                }}
              >
                {column.name}
              </TableCell>
            ))}
            <TableCell
              sx={{
                textAlign: "center",
                borderColor: "black",
                borderWidth: 1,
                borderStyle: "solid",
                borderLeft: "none",
                borderRight: "none",
                fontWeight: 'bold',
                backgroundColor: "#121212",
                color: "white",
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData?.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                '&:nth-of-type(even)': { backgroundColor: '#ffffff' },
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
              }}
            >
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.examName}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.select_class}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.select_subject}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.select_chapter}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.total_marks}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.select_date}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.start_time}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {item.end_time}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                <a
                  href={item?.questionPaperPath}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1976d2",
                    '&:hover': { textDecoration: "underline" }
                  }}
                >
                  View Questions
                </a>
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                <a
                  href={item?.ansKeyPath}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1976d2",
                    '&:hover': { textDecoration: "underline" }
                  }}
                >
                  View Ans Key
                </a>
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "black",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                <Box display="flex" justifyContent="center">
                  <Tooltip title="Check Paper">
                    <FlakyIcon
                      sx={{ cursor: "pointer", color: "#3f51b5", '&:hover': { color: "#303f9f" } }}
                      onClick={() => handleExamIdClick(item.id)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      sx={{ cursor: "pointer", color: "#f44336", '&:hover': { color: "#d32f2f" } }}
                      onClick={() => handleDeleteClick(item.id)}
                    />
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "baseline",
        backgroundColor: "#f1f1f1",
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Typography sx={{ pl: 1, fontWeight: "bold" }}>
          Total exams: {totalLength}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            alignItems: "baseline",
          }}
        />
      </Box>
    </Box>

    {/* Delete Confirmation Dialog */}
    <DeleteModalExams
      showDeleteConfirmation={showDeleteConfirmation}
      handleCancelDelete={handleCancelDelete}
      handleConfirmDelete={handleConfirmDelete}
    />
  </>
  );
};

export default MegaTestTable;
