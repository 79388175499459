import {GET_USER_EXAMS} from '../actionsType';

const initalState={

    userExams:[]
};


const examReducer=(state=initalState,action)=>{

    switch(action.type){
        case GET_USER_EXAMS:
            return{
                ...state,
                userExams:action?.payload
            }


            default:
                return state;
    }
    

}



export default examReducer;