import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { buyEvent, verifyEventPayment } from '../redux/actions/api/index';
import { checkSubmission } from '../redux/actions/eventActions';
import LoginModal from './LoginModal';
import EventWorkshopModal from './EventWorkshopModal';
import RegisterModal from './RegisterModal';
import ecLogo from '../images/ec_logo_square.jpg';
import nextIcon from '../images/ic_arrow_right.svg';
import { eventSize } from '../util/screenSize';
import '../styles/EventCard.css';
import '../styles/EventCard.style.css'; // Assuming you have this file for custom styles
import moment from 'moment/moment';
const EventCard = (props) => {
  const { event, locked, isAuthenticated } = props;
  const validTill = new Date(event.end_time) >= new Date() ? true : false;
  const history = useNavigate();
  const dispatch = useDispatch();
  const [eventModal, setEventModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState({});
  const [boolVal, setBoolVal] = useState(false);
  const { userData } = useSelector((store) => store.userReducer);
  const { userEvents } = useSelector((store) => store.eventReducer);
  var screen = window.innerWidth;
  useEffect(() => {
    if (userEvents && !boolVal) {
      const Event =
        userEvents.length !== 0
          ? userEvents.filter((detail) => detail.id === event.id)
          : [];
      const Details = Event.length !== 0 ? Event[0].UserEvents[0] : null;
     // console.log('Details', Details)
      setPurchaseDetails(Details);
      setBoolVal(true);
    }
  }, []);

  console.log('purchaseDetails', purchaseDetails)

  eventModal
    ? (document.querySelector('body').style.overflow = 'hidden')
    : (document.querySelector('body').style.overflow = 'auto');

  // const buyNow = async (regType, memberList) => {
  //   const formData = {
  //     isSolo: regType === 'solo',
  //     isGroup: regType === 'group',
  //     groupMembers: memberList,
  //   };

  //   if (!formData.isSolo && !formData.isGroup) {
  //     window.alert('Please select either Solo or Group registration');
  //     return;
  //   }
  //   const res = await buyEvent(event.id, formData);
  //   if (res.status !== 201) {
  //     return;
  //   }

  //   const options = {
  //     key: process.env.REACT_APP_RAZORPAY_KEY,
  //     amount: res.data.response.amount,
  //     currency: res.data.response.currency,
  //     name: 'Enrouting Careers',
  //     description: event.topic,
  //     image: ecLogo,
  //     order_id: res.data.response.id,
  //     handler: async function (response) {
  //       const data = {
  //         orderCreationId: res.data.response.id,
  //         razorpayPaymentId: response.razorpay_payment_id,
  //         razorpaySignature: response.razorpay_signature,
  //         eventId: event?.id,
  //         userId: userData?.id,
  //         groupMembers: memberList,
  //         isSolo: regType === 'solo',
  //         isGroup: regType === 'group',
  //         price: res.data.price,
  //       };

  //       await verifyEventPayment(data);
  //       history(
  //        '/subscription',{
  //         state: {
  //           type: 'event',
  //           event,
  //           paymentDetails: data,
  //           regType: regType,
  //         },
  //       });
  //     },
  //     prefill: {
  //       name: userData.firstname + ' ' + userData.lastname,
  //       email: userData.email,
  //       contact: userData.phone,
  //     },
  //     theme: {
  //       color: '#3399cc',
  //     },
  //   };
  //   var rzp1 = new window.Razorpay(options);

  //   rzp1.open();

  //   rzp1.on('payment.failed', function (response) {
  //     // payment failed
  //   });
  // };
  const buyNow = async (regType, memberList) => {
    try {
      const formData = {
        isSolo: regType === 'solo',
        isGroup: regType === 'group',
        groupMembers: memberList,
      };
  
      if (!formData.isSolo && !formData.isGroup) {
        window.alert('Please select either Solo or Group registration');
        return;
      }
  
      // Attempt to buy event
      const res = await buyEvent(event.id, formData);
  
      if (res.status !== 201) {
        window.alert('Failed to initiate payment. Please try again.');
        return;
      }
  
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: res.data.response.amount,
        currency: res.data.response.currency,
        name: 'Enrouting Careers',
        description: event.topic,
        image: ecLogo,
        order_id: res.data.response.id,
        handler: async function (response) {
          try {
            const data = {
              orderCreationId: res.data.response.id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpaySignature: response.razorpay_signature,
              eventId: event?.id,
              userId: userData?.id,
              groupMembers: memberList,
              isSolo: regType === 'solo',
              isGroup: regType === 'group',
              price: res.data.price,
            };
  
            // Verify payment
            const paymentVerificationRes = await verifyEventPayment(data);
  
            if (paymentVerificationRes.status !== 200) {
              window.alert('Payment verification failed. Please contact support.');
              return;
            }
 
            // On successful verification, redirect
            history('/subscription', {
              state: {
                type: 'event',
                event,
                paymentDetails: data,
                regType: regType,
              },
            });
          } catch (verificationError) {
            console.error('Payment verification failed:', verificationError);
            window.alert('Payment verification failed. Please try again.');
          }
        },
        prefill: {
          name: `${userData.firstname} ${userData.lastname}`,
          email: userData.email,
          contact: userData.phone,
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      var rzp1 = new window.Razorpay(options);
  
      rzp1.open();
  
      // Handle payment failure
      rzp1.on('payment.failed', function (response) {
        console.error('Payment failed:', response.error);
        window.alert('Payment failed. Please try again.');
      });
  
    } catch (error) {
      console.error('Error in buyNow function:', error);
      window.alert('An error occurred while processing your request. Please try again.');
    }
  };
  
  const handleMoreDetail = () => {
    dispatch(checkSubmission(event.id)).then((res) => {
      if (res && Object.keys(res).length !== 0) {
        setSubmissionDetails(res);
        setSubmissionStatus(true);
      }

      setEventModal(true);
    });
  };

  const handleRegisterClick = () => {
    if (!isAuthenticated) {
      setLoginModal(true);
    } else {
      setRegisterModal(true);
    }
  };


  const blog=null;
  const description=null;
  const setBlogModal=1;

const desc='dkbvjebjbvje nvjefvefjkjf df  nvkjvbjfvbkjfvbjdfjbvf ndjk dfbdfkbkdf dfbkhdf  vjkdbvkjfbj'
return(
<>

{eventModal && (
          <EventWorkshopModal
            type={'event'}
            event={event}
            validTill={validTill}
            purchaseDetails={purchaseDetails}
            setEventModal={setEventModal}
            submissionDetails={submissionDetails}
            locked={locked}
            submissionStatus={submissionStatus}
            handleRegisterClick={handleRegisterClick}
          />
        )}
        {loginModal && <LoginModal setLoginModal={setLoginModal} />}
        {registerModal && (
          <RegisterModal
            setRegisterModal={setRegisterModal}
            buyNow={buyNow}
            event={event}
          />
        )}
  
  <div className="big-screen-container">

  <div className="container-top">
    <h4>{event?.topic}</h4>
    {!locked ? (
            <span >
              Paid amount: ₹
              {purchaseDetails.isSolo ? event.soloPrice : event.groupPrice}
            </span>
          ) : validTill ? (
            <button
              
              onClick={handleRegisterClick}
            >
              Register for event
            </button>
          ) : (
            <span >Event ends</span>
          )}

  </div>
  <div className="container-down">
    <div className="leftbox1">
      <img
        src={event?.img}
        alt="Event"
      />
    </div>
    <div className="rightbox1">
      <p>
       {desc?.replace(/(<([^>]+)>)/gi, '')
           ?.replace('&nbsp;', ' ')
         ?.substring(0, 120)}
      ...
      
      </p>
      <div className='container-rightbox-bottom'>
        <div>
        {!locked ? (
                <div>
                 <span>
  Start date: {event?.start_time ? moment(event?.start_time).format("DD MMM, YYYY") : "No start date available" }
  &nbsp;&nbsp;
  End date: {event?.end_time ? moment(event?.end_time).format("DD MMM, YYYY") : "No end date available"}
</span>

                </div>
              ) : (
                <div>
                  <span >
                    Registration fee: ₹{event.soloPrice} (for single){' '}
                    {event.groupAllowed
                      ? `and ₹
                      ${event.groupPrice} (for group)`
                      : ''}
                      </span>
                      </div>
             )}

  </div>

  <div  className='more' onClick={handleMoreDetail} >
      <strong>More detail  <img
       src={nextIcon}
       alt='forward-icon'
       className='forward-icon'
     /></strong>
     
        </div>
</div>
        
    
     
     
    </div>
    
  


    
  </div>

</div>

{/* Small Screen Container */}
<div className="small-screen-container">
  <div className="container-top">
    <img
      src={event?.img}
      alt="Event"
    />
  </div>
  <div className="container-down">
    <h4>{event?.topic}</h4>
    <p>
    {event?.desc?.replace(/(<([^>]+)>)/gi, '')
           ?.replace('&nbsp;', ' ')
         ?.substring(0, 120)}
      ...
      
    </p>
    <div className="detail">
      <div className="register">

      {!locked ? (
                <div>
                 <span>
  Start date: {event?.start_time ? moment(event.start_time).format("DD MMM, YYYY") : "No start date available" }
<br/>
  End date: {event?.end_time ? moment(event.end_time).format("DD MMM, YYYY") : "No end date available"}
</span>

                </div>
              ) : (
                <div>
                  <span >
                    Registration fee: ₹{event.soloPrice} (for single){' '}
                    {event.groupAllowed
                      ? `and ₹
                      ${event.groupPrice} (for group)`
                      : ''}
                      </span>
                      </div>
             )}
      {!locked ? (
            <span >
              Paid amount: ₹
              {purchaseDetails.isSolo ? event.soloPrice : event.groupPrice}
            </span>
          ) : validTill ? (
            <button
              className='mt-3'
              onClick={handleRegisterClick}
            >
              Register for event
            </button>
          ) : (
            <span>Event ends</span>
          )}
      </div>

      <div className="more"  onClick={handleMoreDetail}>
            <strong>More detail <img
             src={nextIcon}
             alt='forward-icon'
             className='forward-icon'
           />
          </strong>
           
      </div>
    </div>
  </div>
</div>

</>
)


};

export default EventCard;



// {!locked ? (
//   <span className='EventCard-amount-text'>
//     Paid amount: ₹
//     {purchaseDetails.isSolo ? event.soloPrice : event.groupPrice}
//   </span>
// ) : validTill ? (
//   <button
//     className='register-event-button'
//     onClick={handleRegisterClick}
//   >
//     Register for event
//   </button>
// ) : (
//   <span className='EventCard-amount-text'>Event ends</span>
// )}