import { useFormik } from "formik";
import React from "react";
import { Box } from "@mui/material";
import { InputForm } from "../../../../../components/inputField/InputForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import FormButton from "../../../../../components/FormButton/FormButton";
import { Slide, toast } from "react-toastify";
import { addMarks } from "../../../../../api/addExam/addSubMarks/AddSubMarks";
import { QueryKeys } from "../../../../../utils/QueryKeys";

const initialValues = {
  userId: "",
  examId: "",
  testType: "mega_test",
  obtainedMarks: "",
};

const MegaExamAttendByUserForm = ({
  exam_Id,
  rowData,
  setIsEditOpen,
  onMarksUpdate,
}) => {
  console.log("exam_Id", exam_Id);
  console.log("rowData", rowData);

  const queryClient = useQueryClient();

  // post the data
  const createPostMutation = useMutation({
    mutationFn: addMarks,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.add_sub_marks });
      toast.success("Marks added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
      setIsEditOpen(false);
      if (onMarksUpdate) {
        onMarksUpdate(rowData, data.obtainedMarks);
      }
    },
    onError: (error) => {
      toast.error("An error occurred while adding the marks.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    },
  });

  const onSubmit = async (values) => {
    try {
      // Format start_time and end_time to "HH:mm:ss" before sending
      const formattedValues = {
        ...values,
        testType: "mega_test",
        userId: rowData,
        examId: exam_Id,
      };

      await createPostMutation.mutate(formattedValues);
      setIsEditOpen(false);
    } catch (error) {
      console.error(error.message || "An error occurred");
      toast.error("An error occurred while adding the exams.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
      handleReset();
    }
  };

  const validationSchema = Yup.object({
    obtainedMarks: Yup.string().required("Please Marks"),
  });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  });

  const { handleSubmit, resetForm } = formik;

  const handleReset = () => {
    resetForm();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
          width: "100%",
          mt: 3,
        }}
      >
        {/* row 1 marks */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            gap: "10px",
            width: "100%",
          }}
        >
          <InputForm
            type="text"
            formik={formik}
            name="obtainedMarks"
            placeholder="Enter Marks"
          />
        </Box>
      </Box>

      {/* submit button */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          gap: "10px",
        }}
      >
        <FormButton handleSubmit={handleSubmit} handleReset={handleReset} />
      </Box>
    </>
  );
};

export default MegaExamAttendByUserForm;
