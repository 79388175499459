import { PARENTS_SIGN_IN, PARENTS_SIGN_UP,PARENT_LOGOUT,SET_PARENT_DETAILS} from '../actionsType';
import * as api from './api';



export const parentSignup = (signupData, history) => async (dispatch) => {
  try {
    const { data } = await api.parent_signup(signupData);
    dispatch({
      type: PARENTS_SIGN_UP,
      payload: { parentsDetails: data.result, token: data.accessToken },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const parentsLogin = (parentLoginData) => async (dispatch) => {
  try{
    const data=await api.parents_login(parentLoginData)
    dispatch({
    type:PARENTS_SIGN_IN,
    payload: { parentsDetails: data?.data?.result, token: data?.data?.accessToken },
  });
  return data;

} catch (err) {
  console.error(err);
  throw err;
  }
};



export const setParentsDetails = (history) => async (dispatch) => {
  try {
    const parent = await api.getParent();
    console.log('parent from setParentDetails', parent.data.result)
    dispatch({
      type: SET_PARENT_DETAILS,
      payload: parent?.data?.result,
    });

   // console.log(history)
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const parent_logout = (history) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_LOGOUT,
    });
    history('/');
  } catch (err) {
    console.log('something went wrong');
  }
};