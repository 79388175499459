import { Box, Button } from "@mui/material";
import React from "react";

const FormButton = ({ handleSubmit, handleReset }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: {
            lg: "flex-end",
            md: "flex-end",
            sm: "flex-end",
            xs: "flex-start",
          },
          width: { lg: "100%", md: "100%", sm: "100%", xs: "50px" },
        }}
      >
        <Button
          className="common-color"
          variant="contained"
          onClick={handleSubmit}
          size="small"
        >
          Add
        </Button>

        <Button
          className="common-color"
          variant="contained"
          onClick={handleReset}
          size="small"
        >
          Reset
        </Button>
      </Box>
    </>
  );
};

export default FormButton;
