import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import TryModal from "./TryModal";
import OutsideClickHandler from "react-outside-click-handler";
import brandLogo from "../images/ec_logo_nobg.png";
import downIcon from "../images/ic_arrow_down_green.svg";
import { logout } from "../redux/actions/userActions";
import { getAllBoards } from "../redux/actions/boardActions";
import { getAllStandards } from "../redux/actions/standardActions";
import { getAllCourses, getUserCourses } from "../redux/actions/courseActions";
import { screenSize } from "../util/screenSize";
import "../styles/Navbar.css";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils/QueryKeys";
import { getMCQExams } from "../api/addExam/MCQ/mcq_exam_api";
import { Badge } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";


const Navbar = (props) => {
  const history = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeMenuItem } = props;

  const [loginModal, setLoginModal] = useState(false);
  const [tryModal, setTryModal] = useState(false);
  const [courseMenu, setCourseMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [activeMenu1Item, setActiveMenu1Item] = useState(0);
  const [activeMenu2Item, setActiveMenu2Item] = useState("");
  const [activeMenu3Item, setActiveMenu3Item] = useState("");
  const [activeProfileMenuItem, setActiveProfileMenuItem] = useState(0);

  const { isAuthenticated, userData } = useSelector(
    (store) => store.userReducer
  );
  const { allBoards } = useSelector((store) => store.boardReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  const { allCourses, userCourses } = useSelector(
    (store) => store.courseReducer
  );

  useEffect(() => {
    if (allBoards.length === 0) {
      dispatch(getAllBoards());
    }
    if (allStandards.length === 0) {
      dispatch(getAllStandards());
    }
  }, []);

  const handleMenu1Click = (val) => {
    setActiveMenu1Item(val);
  };

  const handleSubmit = (course) => {
    handleOutsideClick();
    if (!userCourses && isAuthenticated) {
      dispatch(getUserCourses());
    }

    history("/course", { state: course });
  };

  const handleCourseClick = () => {
    setCourseMenu(true);
    if (allCourses.length === 0) {
      dispatch(getAllCourses());
    }
  };

  const handleTryClick = () => {
    setTryModal(true);
    if (allCourses.length === 0) {
      dispatch(getAllCourses());
    }
  };
  const toggleMobileMenu = () => {
    setct(!ct);
    const navLeft = document.querySelector('.nav_left');
    navLeft.classList.toggle('show');
  }
  const handleProfileClick = (option) => {
    setProfileMenu(false);
    if (option === "profile") history("/profile");
    else if (option === "rewards") history("/myRewards");
    else if (option === "purchases")
      history("/purchases", {
        state: "purchases",
      });
    else if (option === "events") history("/myEvents");
    else if (option === "workshop") history("/myWorkshops");
    else if (option === "notes") history("/notes", { state: "notes" });
    else if (option === "progress") history("/progress");
  };

  const handleOutsideClick = () => {
    setProfileMenu(false);
    setCourseMenu(false);
    setActiveMenu1Item(0);
    setActiveMenu2Item("");
    setActiveMenu3Item("");
    setActiveProfileMenuItem(0);
  };

  loginModal || tryModal
    ? (document.querySelector("body").style.overflow = "hidden")
    : (document.querySelector("body").style.overflow = "auto");

  const getFilteredCourses = (standard, board) => {
    return allCourses?.filter(
      (course) =>
        course?.class === standard.toString() && course?.board === board
    );
  };



  const comingSoonMenu = () => (
    <div className="course-menu-2">
      <div className="no-subject-item">
        <span>Available soon</span>
      </div>
    </div>
  );

  const subjectsMenu = (standard, board) => (
    <div className="course-menu-2">
      {getFilteredCourses(standard, board)?.length === 0 ? (
        <div className="no-subject-item">
          <span>No subject available</span>
        </div>
      ) : (

        getFilteredCourses(standard, board)?.map((course) => (
          <div
            onClick={() => handleSubmit(course)}
            className="course-menu-1-item"
          >
            <span>{course?.subject}</span>
          </div>
        ))
      )}
    </div>
  );

  const boardMenu = () => (
    <div className="course-menu-2">

      {allBoards?.map((board) => (
        <div
          onClick={() => setActiveMenu3Item(board?.name)}
          className={`course-menu-1-item  ${activeMenu3Item === board.name && "active-item"
            }`}
        >
          <span className="ll">{board?.name}</span>
          {activeMenu3Item === board?.name &&
            subjectsMenu(activeMenu2Item, activeMenu3Item)}
        </div>
      ))}
    </div>
  );
  const classMenu = () => (
    <div className="course-menu-2">
      {isAuthenticated
        ? allStandards
          ?.filter((standard) => standard?.class === userData.standard)
          .map((standard) => (
            <div
              onClick={() => setActiveMenu2Item(standard?.class)}
              className={`course-menu-1-item  ${activeMenu2Item === standard?.class && "active-item"
                }`}
            >
              <span className="ll">{standard?.class}th Class</span>
              {activeMenu2Item === standard?.class && boardMenu()}
            </div>
          ))
        : allStandards?.map((standard) => (
          <div
            onClick={() => setActiveMenu2Item(standard?.class)}
            className={`course-menu-1-item  ${activeMenu2Item === standard?.class && "active-item"
              }`}
          >
            <span className="ll">{standard?.class}th Class</span>
            {activeMenu2Item === standard?.class && boardMenu()}
          </div>
        ))}
    </div>
  );



  const [ct, setct] = useState(false);
  const [level1, setlevel1] = useState(false);
  const [level2, setlevel2] = useState(false);
  const [level3, setlevel3] = useState(false);
  const [profileTabs, setProfileTabs] = useState(false);
  const [a, seta] = useState(false);
  const [b, setb] = useState(false);
  const [c, setc] = useState(false);
  const [d, setd] = useState(false);
  const [e, sete] = useState(false);
  const [f, setf] = useState(false);
  var screen = window.innerWidth;

  function gg() {
    setct(!ct);
    if (!ct) {
      document.body.classList.add("stop-scroll");
    } else {
      document.body.classList.remove("stop-scroll");
    }
  }

  // Use React Query to fetch topic data
  const { data: mcqExamData } = useQuery({
    queryKey: QueryKeys.mcq_exams,
    queryFn: getMCQExams,
  });

  //   fetch today date
  const now = new Date();
  const currentDate = now.toISOString().split("T")[0];

  // Ensure date strings and times are compared correctly
  const todayTest = mcqExamData?.filter((item) => {
    return item.select_date >= currentDate;
  });

  // Debugging logs
  // console.log("Filtered todayTest:", todayTest);



  const classTest = todayTest?.filter(
    (item) => item?.select_class == userData?.standard
  );


  const handleClick = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setLoginModal(true);
      toast.info("Please login to move further");
      console.log("loginModal", loginModal);
    } else {
      navigate("/exams");
      console.log("working navigate");
    }
  };
  return (
    <div className="Navbar">
      {courseMenu && <div className="dim"></div>}
      {loginModal && <LoginModal setLoginModal={setLoginModal} />}
      {tryModal && <TryModal setTryModal={setTryModal} />}

      <div className="Navbar-container">
        <div className="Navbar-container-left">
          <div
            className="bar"
            onClick={() => {
              gg();
            }}
          >
            {" "}
            <i
              className={ct ? "fas fa-times" : "fas fa-bars"}
              style={{ width: "100%", height: "100%" }}
            ></i>{" "}
          </div>

          <img
            src={brandLogo}
            alt="ec_logo"
            className="ec-logo"
            onClick={() => history("/")}
          />
          {screen >= screenSize ? (
            <div className="new_nav">
              <div className={ct === true ? "nav_left" : "remove"}>
                <NavLink
                  to="/"

                  className={`nav-link ${activeMenuItem === "home" &&
                    !courseMenu &&
                    !profileMenu &&
                    "activ"
                    }`}
                >
                  <p
                    onClick={() => setCourseMenu(false)}
                    className="Navbar-items mm"
                  >
                    Home
                  </p>
                </NavLink>
                <div
                  className={`nav-link course-div ${(courseMenu || activeMenuItem === "course") && "activ"
                    }`}
                >
                  <p className="Navbar-items mm" onClick={handleCourseClick}>
                    Course
                  </p>
                  {courseMenu && (
                    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                      <div className="course-menu-1">
                        <div
                          onClick={() => handleMenu1Click(1)}
                          className={`course-menu-1-item ${activeMenu1Item === 1 && "active-item"
                            }`}
                        >
                          <span className="uu">Notes & lectures</span>
                          {activeMenu1Item === 1 && classMenu()}
                        </div>

                        <div
                          onClick={() => handleMenu1Click(2)}
                          className={`course-menu-1-item ${activeMenu1Item === 2 && "active-item"
                            }`}
                        >
                          <span className="uu">Exam preparation</span>
                          {activeMenu1Item === 2 && comingSoonMenu()}
                        </div>
                        <div
                          onClick={() => handleMenu1Click(3)}
                          className={`course-menu-1-item ${activeMenu1Item === 3 && "active-item"
                            }`}
                        >
                          <span className="uu">Competitive exam</span>
                          {activeMenu1Item === 3 && comingSoonMenu()}
                        </div>
                        <div
                          onClick={() => handleMenu1Click(4)}
                          className={`course-menu-1-item ${activeMenu1Item === 4 && "active-item"
                            }`}
                        >
                          <span className="uu">Articulture</span>
                          {activeMenu1Item === 4 && comingSoonMenu()}
                        </div>
                        <div
                          onClick={() => handleMenu1Click(5)}
                          className={`course-menu-1-item ${activeMenu1Item === 5 && "active-item"
                            }`}
                        >
                          <span className="uu">Scifun</span>
                          {activeMenu1Item === 5 && comingSoonMenu()}
                        </div>
                        <div
                          onClick={() => handleMenu1Click(6)}
                          className={`course-menu-1-item ${activeMenu1Item === 6 && "active-item"
                            }`}
                        >
                          <span className="uu">Practical presentation</span>
                          {activeMenu1Item === 6 && comingSoonMenu()}
                        </div>
                        <div
                          onClick={() => handleMenu1Click(7)}
                          className={`course-menu-1-item ${activeMenu1Item === 7 && "active-item"
                            }`}
                        >
                          <span className="uu">Conceptual videos</span>
                          {activeMenu1Item === 7 && comingSoonMenu()}
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                <NavLink
                  to="/events"

                  className={`nav-link ${activeMenuItem === "events" &&
                    !courseMenu &&
                    !profileMenu &&
                    "activ"
                    }`}
                >
                  <p
                    onClick={() => setCourseMenu(false)}
                    className="Navbar-items mm "
                  >
                    Events
                  </p>
                </NavLink>

                <NavLink
                  to="/blogs"

                  className={`nav-link ${activeMenuItem === "blogs" &&
                    !courseMenu &&
                    !profileMenu &&
                    "activ"
                    }`}
                >
                  <p className="Navbar-items mm ">Blogs</p>
                </NavLink>
                <NavLink
                  to="/about"

                  className={`nav-link ${activeMenuItem === "about" &&
                    !courseMenu &&
                    !profileMenu &&
                    "activ"
                    }`}
                >
                  <p className="Navbar-items mm">About us</p>
                </NavLink>

                {
                  <NavLink
                    to="/live"

                    className={`nav-link ${activeMenuItem === "live" &&
                      !courseMenu &&
                      !profileMenu &&
                      "activ"
                      }`}
                  >
                    <p className="Navbar-items mm">Live Class</p>
                  </NavLink>
                }
                {
                  <NavLink
                    to="/project"

                    className={`nav-link ${activeMenuItem === "project" &&
                      !courseMenu &&
                      !profileMenu &&
                      "activ"
                      }`}
                  >
                    <p className="Navbar-items mm">Project</p>
                  </NavLink>
                }
                {
                  <NavLink
                    to="/exams"
                    className={`nav-link ${activeMenuItem === "exams" &&
                      !courseMenu &&
                      !profileMenu &&
                      "activ"
                      }`}
                    onClick={handleClick}
                  >
                    
                      {" "}
                      
                       
                     
                        <p className="Navbar-items mm">Exams <Badge badgeContent={classTest?.length} sx={{paddingLeft:1.5}} color="warning" /></p>
               
                  </NavLink>
                }



              </div>

              <div className="Navbar-container-right">
                <div className={ct === true ? "sec" : "sec_shrink"}>
                  <NavLink
                    to="/help"

                    className={`nav-link ${activeMenuItem === "help" &&
                      !courseMenu &&
                      !profileMenu &&
                      "activ"
                      }`}
                  >
                    <p className="Navbar-items help mm">Help</p>
                  </NavLink>
                  {isAuthenticated ? (
                    <div
                      className={`Navbar-items profile-div ${(profileMenu || activeMenuItem === "profile") && "activ"
                        }`}
                    >
                      <span
                        className="name-text mm"
                        onClick={() => setProfileMenu(true)}
                      >

                        <Avatar
                          alt={userData?.firstname}
                          src="/static/images/avatar/1.jpg"
                          sx={{ width: 24, height: 24 }}
                        />

                      </span>



                      {profileMenu && (
                        <OutsideClickHandler
                          onOutsideClick={handleOutsideClick}
                        >
                          <div className="profile-menu">
                            <h6 className="text-center p-0 m-0 ">Hi! {!userData?.custom_id?userData?.firstname:userData?.custom_id}</h6>
                            <div
                              onClick={() => setActiveProfileMenuItem(1)}
                              className={`course-menu-1-item ${activeProfileMenuItem === 1 && "active-item"
                                }`}
                            >
                              <p onClick={() => handleProfileClick("profile")}>
                                Profile
                              </p>
                            </div>

                            <div
                              onClick={() => setActiveProfileMenuItem()}
                              className={`course-menu-1-item ${activeProfileMenuItem === 2 && "active-item"
                                }`}
                            >
                              <p onClick={() => handleProfileClick("rewards")}>
                                My Rewards
                              </p>
                            </div>


                            <div
                              onClick={() => setActiveProfileMenuItem()}
                              className={`course-menu-1-item ${activeProfileMenuItem === 3 && "active-item"
                                }`}
                            >
                              <p onClick={() => handleProfileClick("progress")}>
                                My Progress
                              </p>
                            </div>




                            <div
                              onClick={() => setActiveProfileMenuItem(3)}
                              className={`course-menu-1-item ${activeProfileMenuItem === 4 && "active-item"
                                }`}
                            >
                              <p
                                onClick={() => handleProfileClick("purchases")}
                              >
                                My purchases
                              </p>
                            </div>
                            <div
                              onClick={() => setActiveProfileMenuItem(4)}
                              className={`course-menu-1-item ${activeProfileMenuItem === 5 && "active-item"
                                }`}
                            >
                              <p onClick={() => handleProfileClick("events")}>
                                Registered events
                              </p>
                            </div>
                            <div
                              onClick={() => setActiveProfileMenuItem(6)}
                              className={`course-menu-1-item ${activeProfileMenuItem === 7 && "active-item"
                                }`}
                            >
                              <p onClick={() => handleProfileClick("notes")}>
                                Recall & Revise
                              </p>
                            </div>
                            <div
                              onClick={() => setActiveProfileMenuItem(7)}
                              className={`course-menu-1-item ${activeProfileMenuItem === 8 && "active-item"
                                }`}
                            >
                              <p onClick={() => dispatch(logout(history))}>
                                Log Out
                              </p>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                  ) : (
                    <p
                      onClick={() => setLoginModal(true)}
                      className="Navbar-items login"
                    >
                      Login
                    </p>
                  )}
                </div>
                <button
                  onClick={() => handleTryClick()}
                  className={`try-free-button ${ct && "j"}`}
                >
                  Try for free
                </button>
              </div>
            </div>
          ) : (
            <div
              className={
                ct === true ? "show_vertical_nav" : "hide_vertical_nav"
              }
            >
              <ul className="nav_list">
                <li
                  // onClick={() => {
                  //   window.location.href = './';
                  // }}
                  onClick={() => history("/")}
                >
                  <p className="nav_list_item">Home</p>
                </li>
                <li className="course_box">
                  {" "}
                  <p
                    id="course"
                    className={`nav_list_item ${level1 ? "color" : ""}`}
                    onClick={() => {
                      setProfileTabs(false);
                      setlevel1(!level1);
                    }}
                  >
                    Course{" "}
                  </p>
                  {level1 ? (
                    <div className="dropdown">
                      <ul className="box_list">
                        <li>
                          <p
                            id="course1"
                            className={`drop_list_item`}
                            onClick={() => {
                              setlevel2(!level2);
                              if (level2) {
                                document
                                  .getElementById("course1")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("course1")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Notes & lectures
                          </p>
                          {level2 ? (
                            <ul>
                              <li>
                                {" "}
                                <p
                                  id="course12"
                                  onClick={() => {
                                    setlevel3(!level3);
                                    if (level3) {
                                      document
                                        .getElementById("course12")
                                        .classList.remove("color");
                                    } else {
                                      document
                                        .getElementById("course12")
                                        .classList.add("color");
                                    }
                                  }}
                                  className="drop_list_item"
                                >
                                  {" "}
                                  11th Class{" "}
                                </p>
                                {level3 ? (
                                  <ul>
                                    <li className="lev3_item">CBSE</li>
                                    <li className="lev3_item">ICSE</li>
                                  </ul>
                                ) : null}
                              </li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a1"
                            className="drop_list_item vv"
                            onClick={() => {
                              seta(!a);
                              if (a) {
                                document
                                  .getElementById("a1")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a1")
                                  .classList.add("color");
                              }
                            }}
                          >
                            {" "}
                            Exam Preparation{" "}
                          </p>
                          {a ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a2"
                            className="drop_list_item"
                            onClick={() => {
                              setb(!b);
                              if (b) {
                                document
                                  .getElementById("a2")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a2")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Competitive Exam
                          </p>
                          {b ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a3"
                            className="drop_list_item"
                            onClick={() => {
                              setc(!c);
                              if (c) {
                                document
                                  .getElementById("a3")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a3")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Articulture
                          </p>
                          {c ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a4"
                            className="drop_list_item"
                            onClick={() => {
                              setd(!d);
                              if (d) {
                                document
                                  .getElementById("a4")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a4")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Scifun
                          </p>
                          {d ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a5"
                            className="drop_list_item"
                            onClick={() => {
                              sete(!e);
                              if (e) {
                                document
                                  .getElementById("a5")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a5")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Practical Presentation
                          </p>
                          {e ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                        <li>
                          <p
                            id="a6"
                            className="drop_list_item"
                            onClick={() => {
                              setf(!f);
                              if (f) {
                                document
                                  .getElementById("a6")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a6")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Conceptual Videos
                          </p>
                          {f ? (
                            <ul>
                              <li className="lev3_item">Available soon</li>
                            </ul>
                          ) : null}
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </li>

                {/* all student test */}
                <li className="course_box">
                  {" "}
                  <p
                    id="test"
                    className={`nav_list_item ${level1 ? "color" : ""}`}
                    onClick={() => {
                      setProfileTabs(false);
                      setlevel1(!level1);
                    }}
                  >
                    Test{" "}
                  </p>
                  {level1 ? (
                    <div className="dropdown">
                      <ul className="box_list">
                        <li>
                          <p
                            id="course1"
                            className={`drop_list_item`}
                            onClick={() => {
                              setlevel2(!level2);
                              if (level2) {
                                document
                                  .getElementById("course1")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("course1")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Mega Test
                          </p>
                        </li>
                        <li>
                          <p
                            id="a1"
                            className="drop_list_item vv"
                            onClick={() => {
                              seta(!a);
                              if (a) {
                                document
                                  .getElementById("a1")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a1")
                                  .classList.add("color");
                              }
                            }}
                          >
                            {" "}
                            MCQ Test{" "}
                          </p>
                        </li>
                        <li>
                          <p
                            id="a2"
                            className="drop_list_item"
                            onClick={() => {
                              setb(!b);
                              if (b) {
                                document
                                  .getElementById("a2")
                                  .classList.remove("color");
                              } else {
                                document
                                  .getElementById("a2")
                                  .classList.add("color");
                              }
                            }}
                          >
                            Subjective Test
                          </p>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </li>

                <li
                  // onClick={() => {
                  //   window.location.href = './events';
                  // }}

                  onClick={() => history("/events")}
                >
                  <p className="nav_list_item">Events </p>
                </li>
                {/* <li
                  onClick={() => history("/workshop")}
                >
                  <p className="nav_list_item">Workshop</p>
                </li> */}
                <li
                  // onClick={() => {
                  //   window.location.href = './blogs';
                  // }}
                  onClick={() => history("/blogs")}
                >
                  <p className="nav_list_item blogs">Blogs</p>
                </li>

                <li>
                  {" "}
                  <div className="line1"></div>
                </li>
                <li>
                  {" "}
                  {isAuthenticated ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "22px 4px 22px 0px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: "0 10px",
                        }}
                        className={`${profileTabs ? "openProfileTabs" : ""}`}
                        onClick={() => {
                          setlevel1(false);
                          setProfileTabs(!profileTabs);
                        }}
                      >
                        {userData?.firstname}
                      </p>
                      <p
                        style={{
                          margin: "0 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => dispatch(logout(history))}
                      >
                        Logout
                      </p>
                    </div>
                  ) : (
                    <p
                      onClick={() => setLoginModal(true)}
                      className="selectedTab"
                    >
                      Login
                    </p>
                  )}
                </li>

                {!profileTabs && (
                  <li>
                    {" "}
                    <div className="line2"></div>
                  </li>
                )}
                {isAuthenticated && profileTabs && (
                  <div className="dropdown">
                    <ul className="box_list">
                      <li
                        className="profileTabs-list"
                       
                        onClick={() => history("/profile")}
                      >
                        <p className="nav_list_item">Profile</p>
                      </li>
                      <li
                        className="profileTabs-list"
                        // onClick={() => {
                        //   window.location.href = '/purchases';
                        // }}
                        onClick={() => history("/purchases")}
                      >
                        <p className="nav_list_item">My purchases</p>
                      </li>
                      <li
                        className="profileTabs-list"
                        // onClick={() => {
                        //   window.location.href = '/myEvents';
                        // }}
                        onClick={() => history("/myEvents")}
                      >
                        <p className="nav_list_item">Registered events</p>
                      </li>
                      {/* <li
                        className="profileTabs-list"
                        // onClick={() => {
                        //   window.location.href = '/myWorkshops';
                        // }}

                        onClick={() => history("/myWorkshops")}
                      >
                        <p className="nav_list_item">My Workshop</p>
                      </li> */}
                      <li
                        className="profileTabs-list"

                        onClick={() => history("/notes")}
                      >
                        <p className="nav_list_item">My notes</p>
                      </li>
                    </ul>
                  </div>
                )}
                <li

                  onClick={() => history("/help")}
                >
                  <p className="nav_list_item">Help</p>
                </li>
                <li
                  // onClick={() => {
                  //   window.location.href = './about';
                  // }}

                  onClick={() => history("/about")}
                >
                  <p className="nav_list_item">About us</p>
                </li>
                <li onClick={() => history("/story")}>
                  <p className="nav_list_item">Our story</p>
                </li>
                <li
                  // onClick={() => {
                  //   window.location.href = './vision';
                  // }}
                  onClick={() => history("/vision")}
                >
                  <p className="nav_list_item">Vision</p>
                </li>
                <li
                  // onClick={() => {
                  //   window.location.href = './t&c';
                  // }}

                  onClick={() => history("/t&c")}
                >
                  <p className="nav_list_item">Terms and Conditions</p>
                </li>
              </ul>
            </div>
          )}
        </div>


        {/* <button
          onClick={() => handleTryClick()}
          className={`try-free-button ${ct && "j"}`}
        >
          Try for free
        </button> */}
      </div>
    </div>
  );
};

export default Navbar;

















///////////////////////////////////////////////////////////////////////////////////////////////



