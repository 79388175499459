// reducers/meetingReducer.js
import { GET_ALL_MEETINGS } from '../actionsType';

const initialState = {
  meetings: null,
};

const meetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MEETINGS:
      return {
        ...state,
        meetings: action?.payload,
      };
    default:
      return state;
  }
};

export default meetingReducer;
