


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { buyProject, getUserCourses, verifyProjectPayment } from '../redux/actions/api/index'; // Assuming these actions exist like in EventCard
import { checkSubmission } from '../redux/actions/eventActions';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import nextIcon from '../images/ic_arrow_right.svg';
import ecLogo from '../images/ec_logo_square.jpg'; // Assuming the same logo as in EventCard
import ProjectModal from './ProjectModal';
import RegisterProjects from './RegisterProjects';
import { toast } from 'react-toastify';
import { Lock } from '@mui/icons-material';
import { getAllProjects, userProjects } from '../redux/actions/projectActions';
import Padlock from '../images/padlock.jpg'


export default function ProjectCard({ item, locked, isAuthenticated }) {

  console.log('item', item)
  const validTill = new Date(item.endTime) >= new Date() ? true : false;
  //console.log('validTill from card', validTill)
  const history = useNavigate();
  const dispatch = useDispatch();
  
  const [projectModal, setProjectModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState({});
  const [boolVal, setBoolVal] = useState(false);
  
  const { userData } = useSelector((store) => store.userReducer);
  const { userProject } = useSelector((store) => store.projectReducer);
  
  useEffect(() => {
    if (userProject && !boolVal) {
      const project = userProject?.length !== 0
        ? userProject.filter((detail) => detail.projectId === item.id)
        : [];
console.log('item', item)
      console.log('project=========>', project)
      const details = project?.length !== 0 ? project[0] : null;
      console.log('Details from project====>', details)
      setPurchaseDetails(details);
      console.log('purc', purchaseDetails)
      setBoolVal(true);
    }
  }, []);

  console.log('purchase', purchaseDetails)

 

  projectModal
    ? (document.querySelector('body').style.overflow = 'hidden')
    : (document.querySelector('body').style.overflow = 'auto');

    const buyNow = async () => {
      try {
        // Start the project purchase request
        const res = await buyProject(item.id);

        console.log('res', res)
        if (res.status === 200) {
          dispatch(userProjects()).then(() => {
            toast.success('Project registered successfully.');
          });
          return; // No need to proceed further for free projects
        }
        if (res.status !== 201) {
          toast.error('Project purchase failed. Please try again.');
          console.error('Project purchase failed with status:', res.status);
          return;
        }

        console.log('res', res)
    
        // Prepare Razorpay options with the response data
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: res.data.response.amount,
          currency: res.data.response.currency,
          name: 'Enrouting Careers',
          description: item?.project_name,
          image: ecLogo,
          order_id: res.data.response.id,
          handler: async function (response) {
            console.log('response', response)
            try {
              // Handle successful payment response from Razorpay
              const data = {
                orderCreationId: res.data.response.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpaySignature: response.razorpay_signature,
                projectId: item?.id,
                userId: userData?.id,
               
              };
    
              // Verify payment by sending data to the backend
              const verificationRes = await verifyProjectPayment(data);
              if (verificationRes.status !== 200) {
                toast.error('Payment verification failed. Please contact support.');
                console.error('Payment verification failed:', verificationRes);
                return;
              }
             dispatch(getAllProjects())
              // Navigate to the subscription page upon successful payment verification
              history('/subscription', {
                state: {
                  type: 'project',
                  item,
                  paymentDetails: data,
                },
              });
            } catch (error) {
              // Error handling for payment verification
              toast.error('There was an issue verifying the payment. Please contact support.');
              console.error('Error verifying payment:', error);
            }
          },
          prefill: {
            name: userData.firstname + ' ' + userData.lastname,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: '#3399cc',
          },
        };
    
        // Initialize Razorpay
        var rzp1 = new window.Razorpay(options);
    
        // Open Razorpay payment gateway
        rzp1.open();
    
        // Handle payment failure
        rzp1.on('payment.failed', function (response) {
          toast.error('Payment failed. Please try again.');
          console.error('Payment failed:', response.error);
        });
      } catch (error) {
        // General error handling for project purchase and Razorpay initiation
        toast.error('Please try again.');
        console.error('Error during the buyNow process:', error);
      }
    };
    

  const handleMoreDetail = () => {
   
      setProjectModal(true);
    ;
  };

  const handleRegisterClick = () => {
    if (!isAuthenticated) {
      setLoginModal(true);
    } else {
      setRegisterModal(true);
    }
  };

  return (
    <div className="ProjectCard">
      {projectModal && (
        <ProjectModal
          type={'project'}
          item={item}
          validTill={validTill}
          purchaseDetails={purchaseDetails}
          setProjectModal={setProjectModal}
          submissionDetails={submissionDetails}
          locked={locked}
          submissionStatus={submissionStatus}
          handleRegisterClick={handleRegisterClick}
        />
      )}
      {loginModal && <LoginModal setLoginModal={setLoginModal} />}
      {registerModal && (
        <RegisterProjects
          setRegisterModal={setRegisterModal}
           buyNow={buyNow}
          item={item}
        />
      )}
      <div className="ProjectCard-title-row">
        <h3 className="ProjectCard-title-text">{item?.project_name}</h3>
        {!locked ? (
          <span className="ProjectCard-amount-text">
            Paid amount: ₹{item?.price ? ` ${item?.price}` : 'FREE'}
          </span>
        ) : validTill ? (
          <button
            className="register-project-button cc"
            onClick={handleRegisterClick}
          >
            Register for Project
          </button>
        ) : (
          <span className="ProjectCard-amount-text">Project ends</span>
        )}
      </div>
      <div className="ProjectCard-content-row">
      <div className="ProjectCard-content-row">
  {
    !locked ? (
      item?.isVideo === 1 ? (
        <img src={item?.video_url} controls={true}  />
      ) : (
        <img src={item?.img_url} alt="Project Image" />
      )
    ) : (
      item?.isVideo === 1 ? (
         <img src={Padlock} alt="Locked Video Thumbnail" />
       
        // <img src={item?.img_url} alt="Project Image" />
        
      ) : (
        <img src={Padlock} alt="Locked Project Image" />
      )
    )
  }
</div>

         
  
        <div className="ProjectCard-content-div">
          <p>
            {item?.project_desc
              ?.replace(/(<([^>]+)>)/gi, '')
              ?.replace('&nbsp;', ' ')
              ?.substring(0, 185)}
            ...
          </p>
          <div className="ProjectCard-date-row">
            {!locked ? (
              <div>
                <span className="ProjectCard-date-text">
                  Start date: {item?.startTime}
                </span>
                <span className="ProjectCard-date-text">
                  End date: {item?.endTime}
                </span>
              </div>
            ) : (
              <div>
                <span className="ProjectCard-date-text">
                  Registration fee: ₹{item?.price}
                </span>
              </div>
            )}
            <div className="ProjectCard-detail-div" onClick={handleMoreDetail}>
              <span className="ProjectCard-details-text">More Details</span>
              <img src={nextIcon} alt="forward-icon" className="forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
