
import { LECTURE_REQUESTS, STUDENT_REQUESTS } from "../actionsType";
import * as api from './api';

export const studentRequests=(formData)=> async (dispatch)=>{
try {
    
    const {data}=await api.studentRequests(formData)
    dispatch({
        type: STUDENT_REQUESTS,
        payload:{ requests:data}
    })
} catch (err) {
    console.error(err);
    return err; 
}}



export const getLectureReq=(teacherId)=>async(dispatch)=>{
    try{
        const {data}= await api.getLectureReq(teacherId)
        dispatch({
            type:LECTURE_REQUESTS,
            payload:data
        })
    }
    catch(err){
        throw err; // Rethrow the error for further handling
    }

}